import React, { useRef, useEffect } from "react";
import philosophie from "../data/Philosophie.json";
import { useTranslation } from "react-i18next";
import arrow from "../Images/arrow.png";
const PhilosophiePage = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const sectionRef = useRef();
  const scrollToSection = () => {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-[calc(100vh-100px)] h-auto  overflow-x-hidden">
      <div className="relative lg:pt-[110px] sm:pt-0">
        <img
          src="https://media.bbf-bike.de/shop/documents/websites/bbf-bike/images/philosophy/PhilosphieBannerImage.jpg"
          className="h-full xs:object-contain md:object-cover
          w-full"
        />

        <h1 className="absolute inset-0 text-bbf-white  flex justify-start lg:pt-[110px] md:mx-16 xs:mx-5 items-center xs:text-[18px] md:text-[35px] xl:text-[70px] font-roboto-regular">
          <div className="underline xs:underline-offset-[6px] underline-offset-[12px] decoration-bbf-green font-roboto">
            {t("philosophy").toUpperCase()}
          </div>
        </h1>
        <h3 className="absolute inset-0 font-roboto md:mx-16 xs:mx-5 xs:text-bbf-black md:text-bbf-white xs:top-[100px] md:top-[150px] xl:top-[350px] 2xl:top-[430px] xl:text-[30px] md:text-[18px] xs:text-[13px] xs:mt-6 md:my-0 ">
          {t("philosphietagline")}
        </h3>

      </div>
      <div className="md:mt-[35px] xl:mt-[45px]  xs:mt-[66px] xl:mx-0 xs:mx-5">
        {philosophie.philosophie.map((item, index) => (
     <div
  key={item}
  className={`flex flex-wrap xs:flex-col-reverse ${
    index % 2 === 0 ? "lg:flex-row " : "lg:flex-row-reverse  "
  }`}
>
          

            <div className="flex-1 flex flex-col justify-center items-start xs:my-5 lg:my-0">
              <div
                className="xs:mb-4 md:mb-6 xl:mb-10 text-roboto  
            text-[20px]  font-bold underline underline-offset-[12px] decoration-bbf-green xl:mx-10  "
              >
                {currentLanguage === "en"
                  ? item.title.en
                  : currentLanguage === "pl"
                  ? item.title.pl
                  : item.title.de}
              </div>

              <div className="text-justify  xs:text-[13px] md:text-[17px] font-roboto xl:mx-10 ">
                {currentLanguage === "en" ? (
                  item.translations.en.includes("•") ? (
                    item.translations.en
                      .split("•")
                      .filter((item) => item.trim() !== "")
                      .map((item, index) => (
                        <div key={index} className="my-1  flex flex-col mb-4">
                          {item === "We are: " ||
                          item === "We offer: " ||
                          item ===
                            "We are happy to fulfill individual wishes. The bicycle is always the common focus for us!" ? (
                            <ol className="flex justify-start items-start  gap-2 font-bold">
                              <li>{item}</li>
                            </ol>
                          ) : (
                            <ol className="flex  xs:items-start md:justify-start md:items-start  gap-2">
                              <img
                                src={arrow}
                                className="xs:h-4 xs:w-4 xl:h-6 xl:w-6 xs:mt-1 object-contain"
                              />
                              <li>{item}</li>
                            </ol>
                          )}
                        </div>
                      ))
                  ) : (
                    item.translations.en
                  )
                ) : currentLanguage === "pl" ? (
                  item.translations.pl.includes("•") ? (
                    item.translations.pl
                      .split("•")
                      .filter((item) => item.trim() !== "")
                      .map((item, index) => (
                        <div key={index} className="my-1  flex flex-col mb-4">
                          {item === "Jesteśmy: " ||
                          item === "Oferujemy: " ||
                          item ===
                            "chętnie spełniamy indywidualne życzenia. Rower jest zawsze w centrum naszej uwagi!" ? (
                            <ol className="flex justify-start items-start  gap-2 font-bold">
                              <li>{item}</li>
                            </ol>
                          ) : (
                            <ol className="flex  xs:items-center md:justify-start md:items-start  gap-2">
                              <img
                                src={arrow}
                                className="xs:h-4 xs:w-4 xl:h-6 xl:w-6 xs:mt-1 object-contain"
                              />
                              <li>{item}</li>
                            </ol>
                          )}
                        </div>
                      ))
                  ) : (
                    item.translations.pl
                  )
                ) : item.Philosophie_block.includes("•") ? (
                  item.Philosophie_block.split("•")
                    .filter((item) => item.trim() !== "")
                    .map((item, index) => (
                      <div key={index} className="my-1  flex flex-col mb-4">
                        {item === "Wir sind: " ||
                        item === "Wir bieten: " ||
                        item ===
                          "Wir erfüllen gern individuelle Wünsche. Das Fahrrad steht für uns immer im gemeinsamen Mittelpunkt!" ? (
                          <ol className="flex justify-start items-start  gap-2 font-bold">
                            <li>{item}</li>
                          </ol>
                        ) : (
                          <ol className="flex  xs:items-start md:justify-start md:items-start  gap-2">
                            <img
                              src={arrow}
                              className="xs:h-4 xs:w-4 xl:h-6 xl:w-6 xs:mt-1 object-contain"
                            />
                            <li>{item}</li>
                          </ol>
                        )}
                      </div>
                    ))
                ) : (
                  <div className="">{item.Philosophie_block}</div>
                )}
              </div>
            </div>
            <div className="flex-1">
              <img
                src={item.image}
                className="h-full xs:object-contain md:object-cover
                w-full"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PhilosophiePage;
