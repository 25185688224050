import React from 'react'
import SliderSection from '../Components/SliderSection'
import ServicesCards from '../Components/ServicesCards'
import { useEffect } from 'react'
import BrandsSection from '../Components/BrandsSection'
import VideoSection from '../Components/VideoSection'
import Places from '../Components/Places'
const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  return (
    <div className='min-h-[calc(100vh-100px)] h-auto lg:mt-[110px] sm:overflow-y-hidden lg:overflow-y-visible'>
    <SliderSection/>
    <BrandsSection/>
    <ServicesCards/>
    <VideoSection/>
    <Places/>
        </div>
  )
}

export default Home