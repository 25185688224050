import React from 'react'
import Services from '../Components/Services'

const ServicesPage = () => {
  return (
    <div>
        <Services/>
    </div>
  )
}

export default ServicesPage