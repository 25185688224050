import React from "react";
import { useEffect } from "react";
import cataloglist from "../data/catalog.json";
const Catalog = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="min-h-[calc(100vh-100px)] h-auto ">
      <div className="lg:pt-[160px] sm:pt-0  md:pt-10">
        <div className="flex justify-center items-center xs:my-10 md:my-16 xl:my-20">
          <div className="grid lg:grid-cols-4 md:grid-cols-3 md:gap-[89px]  sm:grid-cols-3 xl:gap-24 xs:gap-4 ">
            {cataloglist.map((item, index) => (
              <div key={index}>
                <a href={item.file} target="_blank">
                  <img
                    src={item.logo}
                    className="xl:h-[230px] lg:h-[120px]  xl:w-[230px] 
                  lg:w-[120px] 
                  xs:w-[110px] xs:h-[110px]  md:w-[150px] md:h-[150px] object-contain  bg-bbf-brand transition md:ease-in-out md:delay-150  md:hover:-translate-y-1 md:hover:scale-110  md:duration-500 "
                  />
                </a>
                <div
                  className="font-roboto-light flex justify-start items-start flex-col  font-semibold text-[14px] xl:w-[230px] 
                  lg:w-[120px] 
                  xs:w-[110px] md:w-[150px]  bg-opacity-60 auto-rows-max xs:h-[140px] md:h-[80px] leading-6 mt-4"
                >
                  <p className="xs:h-24 md:h-16">{item.name}</p>
            <p>{item.fileSize}</p>
                  
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Catalog;
