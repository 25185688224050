import React from "react";
import { useTranslation } from "react-i18next";

const Card = ({ name, image, description }) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  return (

    <div className="bg-bbf-white w-[350px]">
      <img src={image} className="h-[350px]  object-cover"/>
      <h3 className=" xl:py-4 xl:px-4 xs:py-3 xs:px-3 md:py-4 md:px-4  xs:text-[18px] md:text-[22px] font-roboto font-bold underline underline-offset-[12px] decoration-bbf-green">{name}</h3>
      <div className=" xl:py-4 xl:px-4 xs:py-3 xs:px-3  md:py-4 md:px-4  text-justify xs:text-[13px] md:text-[17px]  font-roboto ">{description}</div>
      </div>

  );
};

export default Card;
