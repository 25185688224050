import React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
const Help = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const {i18n}= useTranslation()

  const currentLanguage= i18n.language
  return (
    <div className="min-h-[calc(100vh-100px)] h-auto">
      <div className="xl:pt-[190px] xs:pt-12 flex justify-center items-start flex-col md:mx-[70px] xs:mx-5 xs:text-[13px] md:text-[15px] xl:text-[17px] text-justify font-roboto gap-4 md:mb-[30px] xl:mb-[35px]  xs:mb-[15px]">
        <h2 className="xs:text-[15px] md:text-[20px] xl:text-[25px] font-bold">
          {currentLanguage==="de"?"Vorfälle melden und Hinweise ernst nehmen – Schutz für Hinweisgeber":currentLanguage==="pl"?"Zgłaszanie incydentów i poważne traktowanie wskazówek - ochrona osób zgłaszających nieprawidłowości":"Vorfälle melden und Hinweise ernst nehmen – Schutz für Hinweisgeber"}
          
        </h2>
        <p>
          
          {currentLanguage==="de"?"Für die BBF Bike GmbH hat die Einhaltung gesetzlicher Vorschriften und interner Regeln oberste Priorität. Wir treten jeder Form von Korruption, Betrug und sonstigen Gesetzesverstößen entschieden entgegen. Als Bestandteil unseres Compliance Management Systems haben wir eine externe und unabhängige Ombudsperson bestellt, die auf die Entgegennahme und Bearbeitung von Hinweisen spezialisiert und seit vielen Jahren in dieser Funktion auch für weitere Organisationen tätig ist."
          :currentLanguage==="pl"?"Dla BBF Bike GmbH przestrzeganie przepisów prawa i wewnętrznie ustalonych zasad jest najwyższym priorytetem. Zdecydowanie sprzeciwiamy się wszelkim formom korupcji, oszustw i innych naruszeń prawa. W ramach naszego systemu zarządzania zgodnością z przepisami wyznaczyliśmy zewnętrznego i niezależnego rzecznika, który specjalizuje się w przyjmowaniu i przetwarzaniu zgłoszeń, który od wielu lat pełni tę funkcję również w innych organizacjach.":"Dla BBF Bike GmbH przestrzeganie przepisów prawa i wewnętrznie ustalonych zasad jest najwyższym priorytetem. Zdecydowanie sprzeciwiamy się wszelkim formom korupcji, oszustw i innych naruszeń prawa. W ramach naszego systemu zarządzania zgodnością z przepisami wyznaczyliśmy zewnętrznego i niezależnego rzecznika, który specjalizuje się w przyjmowaniu i przetwarzaniu zgłoszeń, który od wielu lat pełni tę funkcję również w innych organizacjach."}
        </p>
        <p>
          {currentLanguage==="de"?"Unsere Ombudsperson Rechtsanwalt Prof. Dr. Cherkeh – im Verhinderungsfall einer seiner anwaltlichen Kolleginnen und Kollegen –steht ab dem 01.10.2023 unseren Mitarbeitenden (auch ehemalige) sowie unseren Geschäftspartnerinnen und Geschäftspartnern als persönliche Ansprechperson zur Verfügung, um von diesen Informationen und Hinweise zu etwaigen Gesetzes- oder Regelverstößen, die im Zusammenhang mit unseren Tätigkeiten und Dienstleistungen stehen, vertraulich entgegenzunehmen. Wenn Sie unsicher sind, wie Beobachtungen oder Sachverhalte zu bewerten sind, dann besprechen Sie dies vertrauensvoll mit der Ombudsperson."
          :currentLanguage==="pl"?"Od 1 października 2023 r. nasz rzecznik, prawnik prof. dr Cherkeh - lub, jeśli nie będzie on mógł być obecny, jeden z jego kolegów lub koleżanek prawników - będzie dostępny dla naszych pracowników (w tym również byłych pracowników) i partnerów biznesowych jako osobista osoba kontaktowa w celu poufnego uzyskania informacji i zgłaszania uwag na temat wszelkich naruszeń prawa lub przepisów w związku z naszą działalnością i usługami. W razie wątpliwości co do oceny spostrzeżeń lub faktów prosimy o omówienie tego w tajemnicy z rzecznikiem.":"Od 1 października 2023 r. nasz rzecznik, prawnik prof. dr Cherkeh - lub, jeśli nie będzie on mógł być obecny, jeden z jego kolegów lub koleżanek prawników - będzie dostępny dla naszych pracowników (w tym również byłych pracowników) i partnerów biznesowych jako osobista osoba kontaktowa w celu poufnego uzyskania informacji i zgłaszania uwag na temat wszelkich naruszeń prawa lub przepisów w związku z naszą działalnością i usługami. W razie wątpliwości co do oceny spostrzeżeń lub faktów prosimy o omówienie tego w tajemnicy z rzecznikiem."}

        </p>
        <p>
          {currentLanguage==="de"?" Um was es nicht geht: Die Ombudsperson ist keine allgemeine Beschwerdestelle.":currentLanguage==="pl"?"O co w działalności rzecznika nie chodzi: nie jest on biurem ds. ogólnych skarg i zażaleń.":"Um was es nicht geht: Die Ombudsperson ist keine allgemeine Beschwerdestelle."}
         
        </p>
        <p>
          {currentLanguage==="de"?"Die Ombudsperson kann auf der Grundlage seiner anwaltlichen Verschwiegenheitspflicht Hinweisgeber*innen vor einer Offenlegung seiner / ihrer Identität schützen. Eine Offenlegung der Identität des Hinweisgebers an uns erfolgt nur dann, wenn der Hinweisgeber dies wünscht, es sei denn, diese ist gesetzlich angeordnet. Die Ombudsperson wird auch mit Ihnen sprechen, wenn Sie Ihren Namen nicht oder zunächst nicht preisgeben möchten. Nur mit der ausdrücklichen Zustimmung des Hinweisgebenden werden dessen Informationen an uns weitergegeben. Dort werden die erforderlichen Schritte, die sich aus dem mitgeteilten Sachverhalt ergeben, bewertet und abgestimmt. Die Ombudsperson ist für uns tätig und ist zugleich „interne Meldestelle“ nach dem Hinweisgeberschutzgesetz. Für die Tätigkeit entstehen Ihnen als Hinweisgeber*in keine Kosten."
          
          :currentLanguage==="pl"?"Rzecznik, na podstawie obowiązku zachowania poufności jako prawnik, może chronić osoby zgłaszające nieprawidłowości przed ujawnieniem ich tożsamości. Tożsamość osoby zgłaszającej zostanie nam ujawniona wyłącznie na jej życzenie, z wyjątkiem sytuacji, gdy jest to wymagane przez prawo. Rzecznik będzie również rozmawiał z osobą, która nie chce ujawnić swojego imienia i nazwiska lub nie życzy sobie tego na początku. Tylko za wyraźną zgodą osoby zgłaszającej nieprawidłowości jej dane zostaną nam przekazane. W biurze rzecznika zostaną ocenione i uzgodnione niezbędne kroki wynikające ze zgłoszonych faktów. Rzecznik pracuje dla nas i jest również wewnętrznym centrum zgłaszania zgodnie z ustawą o ochronie osób zgłaszających nieprawidłowości. Jako osoba zgłaszająca nieprawidłowości nie ponosisz z tego tytułu żadnych kosztów."
          :"Rzecznik, na podstawie obowiązku zachowania poufności jako prawnik, może chronić osoby zgłaszające nieprawidłowości przed ujawnieniem ich tożsamości. Tożsamość osoby zgłaszającej zostanie nam ujawniona wyłącznie na jej życzenie, z wyjątkiem sytuacji, gdy jest to wymagane przez prawo. Rzecznik będzie również rozmawiał z osobą, która nie chce ujawnić swojego imienia i nazwiska lub nie życzy sobie tego na początku. Tylko za wyraźną zgodą osoby zgłaszającej nieprawidłowości jej dane zostaną nam przekazane. W biurze rzecznika zostaną ocenione i uzgodnione niezbędne kroki wynikające ze zgłoszonych faktów. Rzecznik pracuje dla nas i jest również wewnętrznym centrum zgłaszania zgodnie z ustawą o ochronie osób zgłaszających nieprawidłowości. Jako osoba zgłaszająca nieprawidłowości nie ponosisz z tego tytułu żadnych kosztów."}

        </p>
        <p>
          {currentLanguage==="de"?"Sie können unsere Ombudsperson auf jede denkbare Weise (persönliches Gespräch, Videokonferenz, Telefon, Mail, Fax, Post usw.) kontaktieren.Sie erreichen die Ombudsperson, Herrn RA Prof. Dr. Cherkeh, wie folgt:"
          :currentLanguage==="pl"?"Możesz skontaktować się z naszym rzecznikiem w dowolny możliwy sposób (osobista rozmowa, wideokonferencja, telefon, e-mail, faks, poczta itp.) ":"Możesz skontaktować się z naszym rzecznikiem w dowolny możliwy sposób (osobista rozmowa, wideokonferencja, telefon, e-mail, faks, poczta itp.) "}

        </p>
        <p>
          {currentLanguage==="de"?"Sie erreichen die Ombudsperson, Herrn RA Prof. Dr. Cherkeh, wie folgt:":currentLanguage==="pl"?"Z rzecznikiem praw, prof. dr Cherkeh, można skontaktować się w następujący sposób:"
          :"Z rzecznikiem praw, prof. dr Cherkeh, można skontaktować się w następujący sposób:"}
          
        </p>
        <p>
          KERN CHERKEH Rechtsanwälte Partnerschaft mbB
          <br />
          Königstraße 7, D – 30175 Hannover
          <br />
          Tel.: +49 511 – 89 76 57 – 0 <br />
          Fax.: +49 511 – 89 76 57 – 22 <br />
          E-Mail:
          <b className="text-bbf-green">
            {" "}
            ombudsperson-bbf-bike@kern-cherkeh.de{" "}
          </b>
          <br />
          Internet: <b className="text-bbf-green">www.kern-cherkeh.de</b>
        </p>
        <p>{currentLanguage==="de"?"Informationen zum Datenschutz":currentLanguage==="pl"?"Informacje na temat ochrony danych"
        :"Informacje na temat ochrony danych"}
          </p>
        <p>
          {currentLanguage==="de"?"Ihre Hinweise helfen uns, Gesetzes- und Regelverstößen frühzeitig entgegenzuwirken und Schäden von Mitarbeitenden, Kunden*innen,Geschäftspartner*innen und uns abzuwenden. Wir haben fortan eine weitere Möglichkeit, gravierende Verstöße und Fehlerquellen zu erkennen, zu beheben und regelkonformes Verhalten sicherzustellen. Unterstützen Sie uns dabei."
          :currentLanguage==="pl"?"Twoje informacje pomagają nam przeciwdziałać naruszeniom prawa i przepisów na wczesnym ich etapie oraz zapobiegać szkodom dla pracowników, klientów, partnerów biznesowych i nas samych. Od teraz mamy kolejną okazję do zidentyfikowania i naprawienia poważnych naruszeń i źródeł błędów oraz zapewnienia zgodnego z przepisami postępowania. Prosimy o wsparcie w tym przedsięwzięciu"
          :"Twoje informacje pomagają nam przeciwdziałać naruszeniom prawa i przepisów na wczesnym ich etapie oraz zapobiegać szkodom dla pracowników, klientów, partnerów biznesowych i nas samych. Od teraz mamy kolejną okazję do zidentyfikowania i naprawienia poważnych naruszeń i źródeł błędów oraz zapewnienia zgodnego z przepisami postępowania. Prosimy o wsparcie w tym przedsięwzięciu"}

        </p>
        <p>Hagen Stamm</p>
        <p>{currentLanguage==="de"?"Geschäftsführer":currentLanguage==="pl"?"Dyrektor zarządzający":"Geschäftsführer"}</p>
        <a
          href="https://media.bbf-bike.de/shop/documents/bbf-bike/Datenschutzinformation_KERN_CHERKEH_Rechtsanw%C3%A4lte_PartmbB_06072023.pdf"
          target="_blank"
        >
          <h4 className="font-bold text-bbf-black hover:text-bbf-green">
            {currentLanguage==="de"?"Datenschutzinformation KERN CHERKEH Rechtsanwälte PartmbB 06072023":currentLanguage==="pl"?"Informacje dotyczące ochrony danych KERN CHERKEH Rechtsanwälte PartmbB 06072023":"Informacje dotyczące ochrony danych KERN CHERKEH Rechtsanwälte PartmbB 06072023"}
    
          </h4>
        </a>
      </div>
    </div>
  );
};

export default Help;
