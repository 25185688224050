import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SliderSectionData from "../data/slider.json"
import { useTranslation } from "react-i18next";
import { Button } from "./Button";
const SliderSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [pause, setPause] = useState(false);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  
  const prevImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? SliderSectionData.length - 1 : prevIndex - 1
    );
  };
  const handlePause = () => {
    setPause((prev) => !prev);
  };
  const nextImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex ===SliderSectionData.length - 1 ? 0 : prevIndex + 1
    );
  };
  useEffect(() => {
    if (!pause) {
      const timer = setTimeout(() => {
        const nextIndex = (currentIndex + 1) % SliderSectionData.length;
        setCurrentIndex(nextIndex);
      }, 5000);
      return () => clearTimeout(timer);
    }
  });

  const { t } = useTranslation();

  return (
    <div className="relative  bg-bbf-black">
      <div>
        <div>
          {SliderSectionData?.map((item, index) => (
            <div key={index}>
              <div className="lg:block xs:hidden  mx-20">
                <img
                  src={item.image}
                  className={`${
                    index === currentIndex ? "block " : "hidden "
                  } w-full h-full object-cover`}
                />
              </div>
              <div className="md:block lg:hidden">
                <img
                  src={item.ipad}
                  className={`${
                    index === currentIndex ? "block " : "hidden "
                  } w-full h-full object-cover`}
                />
              </div>

              <div className="sm:flex xs:hidden flex-col xs:justify-start md:justify-center absolute inset-0 sm:ml-[70px] lg:ml-[162px]">
                <h2
                  className={`text-bbf-white  xl:text-[28px] md:text-[17px] font-roboto font-semibold xs:mt-[60px] xs:text-[14px]  lg:mt-[50px] 2xl:w-[1100px] xl:w-[900px] md:w-[600px] sm:w-[250px] xs:bg-bbf-black xs:bg-opacity-30 md:bg-opacity-0 xs:p-1 md:p-0 z-30  ${
                    index === currentIndex ? "block" : "hidden"
                  }`}
                >
                  {currentLanguage === "en"
                    ? item.translations.en.description
                    : currentLanguage === "pl"
                    ? item.translations.pl.description
                    : item.description}
                </h2>
                {index === currentIndex && item.thumb ? (
                  <div className="lg:mt-[34px] sm:mt-[15px] z-30 w-[122px]">
                    <Link to="/brands">
                      <Button
                        text={
                          currentLanguage === "en"
                            ? item.translations.en.thumb
                            : currentLanguage === "pl"
                            ? item.translations.pl.thumb
                            : item.thumb
                        } 

                        color="bbf-green"
                      />
                    </Link>
                  </div>
                ) : (
                  // <button className="xl:w-[186px] lg:w-[120px] sm:w-[100px]  xl:h-[50px] lg:h-[35px] font-semibold text-bbf-white xl:text-[14px] sm:text-[10px] lg:text-[10px] bg-bbf-green rounded-[10px] lg:mt-[34px] sm:mt-[15px] hover:bg-opacity-90 z-30 sm:p-2">
                  //   <Link to="/brands">
                  //     {currentLanguage === "en"
                  //       ? item.translations.en.thumb
                  //       : currentLanguage === "pl"
                  //       ? item.translations.pl.thumb
                  //       : item.thumb}
                  //   </Link>
                  // </button>
                  ""
                )}
              </div>
            </div>
          ))}
        </div>
        <div>
          <button
            className="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
            onClick={prevImage}
          >
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsT
        AAALEwEAmpwYAAAFz0lEQVR4nO1bW29VRRSeClq8gC+CvklQiEIk/ggj12JiYlIlVgwSCWAJT4Y3L+EioqitiYlGog8a8RKjj4CJUVTUEnwzQJCLpdaenP19swuIko5ZdU5dne7
        TnrZ777PP0S+Zh57OnjVrzcyadRtjcoJzbiaAO0muIvk4gC0AnvZtC8l1JFdKH+lrGh2lUmkOyTUAXiF5HMAVkq6W5vvKN/tItslYphHgnJsB4D4A75K8WCvDNQjkDwCfA3jIO
        XetKRqcc9f7bXx2AmbOkTwI4G2SXSR3+9YFYD/JQ77PeGOcAbDJOTerCIxfE0XRBgB9VSb7I8ldAO7v7++/qdZxBwYGZgNY5oXTU2VXXLDWrnfOtZh6IIqiewF8mzCx30jutNb
        enRYta+1iEQaA/gR6R8rl8lKTF5xzLSS3JSi1s3IM5DhkRbu3t/cGkp0kzycozc7Md0OpVJoD4NOA+GWSz+Z5JkXIJJ8X5RjM5RM5PpkQjeP4VpLHAoLfkVxo6gSSi0geDXZiTx
        zH81IlFEXRfJInFeNDAPYW4Upyzl0n9oLMSQnhpMw5FQLW2rkAflbMXxXtawoGko8C+FMJ4dTg4OBtaVhzx9SgYtysMgUFydUALulreMo6wTnXIkpFrbxId6UpOGSBAPyl5v3ZlG
        4Hktv0mZctZhoE3tka0QkAnpqKkXNFDbDXNBgAvKp9iTiO76nZvAXwjTpHR0XTmgaD3FCaDwBf13QUoijaEBg5dbvnpwtr7V2Bxbpu3A+cc7PEyVAfPGMaHCR3aAdqXIsVwGZt2
        xfC5UzBfwDwqxLCxsSOzrmZ2p8Xx8Y0CUhuVQv7S2LIjeRq7dJm6dWNY3esBfBw2l6d3wXalR5rz5A8oDrsNDnCu9hvqAV4JG0aAF5Q/L2f5OZernRIM5gxWea9ANrTphPH8RI1
        /qVRJjLJNdp+NnVkXv7OKrAhkWZF51+fxoeuc93+nvnXg5V/UwyxrGgC2KNovZQoGQlgmiZb+QoALFf0jumMzbC1JA7EZKK3jcS80nVDFf/AOTdDVn+hmsi5Ztv2IQD0KtoLhv1
        nNaGDzbjyGiQPK/orjE9KViSyv5mZFwB4R82hw/iUVuWH15qZeQHJ7lHmPoDtWV6BYtUVhXmB8KgEsD0PAawtugC2qMl1/ceOwOb/lSBHX4OHspJ8UXYCyS/Ca3Ch+uF8lsS9EEa
        2oN+Gb+VsCF0YZQi5wBTOLLtagJ1QLpdvHmMKJzhDy0zGqJcQZMuPcYYEvhqr8o9dJgfU4zgAeDEx2UOyLVEyzRcQ+SkxIFIaGxJbbOobEks9JiipMUXj4hi3n+QHqsNuU9+gaH
        uW0SCS743pQGUPSAhZQskm/7B4u7S0j0BfX9+NAH4fdf9XSYycUZ06TZNAp/pJnq5aiwxgkzaK8k6OZAG/+joK9GTVzs65Vt2Z5HOmwSHXerCoreN+YK1dr6QldXiLTINCEjxBer
        yjVmV0RH30fYMWSLT6WuXKYn5Vs3Itl8tLgxKZfabBEPj9UiKzZLIDdKoBhiasrigQrLVPBIbVpikNBOBjNcjVKIoeNAWHmPW6TI7kh9MtlOxRQpAixDZTUFhrHwgKJX+YdqbLWn
        tLWCorhVSmYCD5WLDyp6TAO5XBoyiaD+CE1glSh1eE28Fr+9CtPlEul29PlVAcx/P0tVLZYlKKZup4z1eZ09xMCA4MDMwm+VEgbbkud+TpPIl5698iha9WDmSd3R6G1N4mvNaQUr
        StWQrCM74tMNcrz+qmdtVNJ8Ag1lWwAsOutBQkTdrwmJjWnsClrdD7Mk1aUwlkrNOFiEE77ie+fDKvP/31u8LH8HQYSzd5ONVRzzRb6EVulCLEKpPVx+SwT1F3q4eT3f63w+H2Tmi
        nxaWd0KurB9w/leaVp7ODEzBSc5N4ZaGfziZBtLF/wvKyRJpDpTkBw9K3Ryq5JFSXi2Y3GcM/rL7DJyc6JDOrns9LkXaHP/cLRjI2OeBvNEgdeCDr0lMAAAAASUVORK5CYII="
              className="xl:h-8 xl:w-8  xs:h-6 xs:w-6 ml-2 object-contain inline-flex items-center justify-center"
            />
          </button>
          <button className="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none">
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAFx0lEQVR4nO1bWY9URRQu
        BAUX8EXQNwkKUQjEH2Fkx8TEBCWOGCQSwCE8Gd5cwiKiqGBCopHog0ZcYvQRMDGKijoE3wwQZHEYx+n0/b66A4gSypyxejxTc3u6p+duPfol9dJdt07VqaqzlzE5wTk3CcDdJJeSf
        ALARgDP+LaR5GqSS6SP9DXtjkqlMo3kCgCvkjwO4ApJ10zzfeWb3SSXy1imHeCcmwjgfgDvkrzY7IKbYMgfAD4H8LBz7npTNjjnbvTH+GyDxZwjeRDA2yT3kNzh2x4A+0ke8n1GG
        uMMgPXOuSllWPh1URStBdBTZ7I/ktwO4IHe3t5bmh23r69vKoCFnjlddU7FBWvtGufcBFMEoii6D8C3CRP7jeQ2a+29adGy1s4VZgDoTaB3pFqtLjB5wTk3geTmBKF2Vq6BXIes
        aHd3d99EspPk+QSh2Zn5aahUKtMAfBoQv0zyuTzvpDCZ5AsiHIO5fCLXJxOicRzfTvJYQPA7krNNQSA5h+TR4CR2xXE8I1VCURTNJHlSLfwagF1lUEnOuRvEXpA5KSaclDmnQsBaO
        x3Az2rxV0X6mpKB5GMA/lRMONXf339HGtbcMTWoGDdLTUlBchmAS1oNtywTnHMTRKionRfuLjElh2wQgL/UvD9rSTuQ3KzvvBwx0ybwztagTADwdCtGzhU1wC7TZgDwmvYl4jie37
        R5C+AbdY+OiqQ1bQbRUHodAL5u6ipEUbQ2MHIK0/NjhbX2nsBiXT3iB865KeJkqA+eNW0Oklu1AzWixQpgg7btS+FypuA/APhVMWFdYkfn3CTtz4tjY8YJSG5SG/tLYsiN5DLt0q
        bt1Xm7YhWAR/L24f0p0K70cHuG5AHVYVvakwDwqBp/X95MAPCiov9+kpt7udYhzWCGmsCqwGvLlQlxHM9TJ/zSEBOZ5AptP2cYSNlXJBMk0qxo/+vT+NB1Zsc/YMLeIKbwlhhfJg
        cA2KnovpzIGQlgZjmJIk8CgEWK5jGdsRmwlsSBGE30tt2Y4GXdtZp/4JybKLs/W03inMkJRV0HAN2K3qwB/1lN4qDJEUWcBJKHFa3FxiclaxzZb3JG3kwA8I6i02F8Sqv2w+umAO
        TJBH3tIOY+gC15qMBWmCDWY9p0ZI1q/C1lZ8DKPBiwURHdY/5bV2DD/0KQQ9XgITP+1eAXoRqcrX44b/Jd/BvBnX8zB0PowhBDyAWmcGbZ1RKYwtVq9dZhpnCCM7TQjFNnSI78MG
        dI4Kuxan9sN+Ps2NcA4KXEZA/J5YmcGX8BkZ8SAyKV4SGxuRnHBIsIic1XtC8Oc/tJfqA67Mg4JlhEUHQwGkTyvWEdqOwBCSFLKDmDsPhKaXkvvqen52YAvw/R/3USI2dUp04zTq
        BT/SRP161FBrBeG0VZlrzlvPs6CvRU3c7Oucm6M8nnTZtD1HqwqZNH/MBau0ZxS+rw5pg2hSR4gvR4R7PC6oj66Ps2LZCY7GuVa5v5VdPCt1qtLghKZHabNkPg90uJzLzRDtCpBr
        jWsLqiRLDWPhmY2+tbGgjAx2qQq1EUPWRKDjHrdZkcyQ/HWijZpZggRYjLTUlhrX0wKJT8YcyZLmvtbWGprBRSmZKB5OPBzp+SAu9UBo+iaCaAE1omSB1eGbSDl/Zhiu1EtVq9M1
        VCcRzP0GqldsSkFM0UqOfrzGl6JgT7+vqmkvwo4Laoy61pO0+NzFv/Fil8tXIgj+y2kdrbhNcaUoq2KUtG+IVvDsz12rO61lTdWAIMYl0FOzDgSktB0qgNj8a0dgYubY3el2nSai
        XMtVoXIgbtuJ/4otG8/vTqd7GP4ekwlm7ycKqjsGdzCV7kOilCrDNZfU0O+xT1XvVwcq//7XB4vBPaaXFpG3p1RcD9U2leezrb32AhTTeJV5b66WwSRBr7JyyvSKQ5FJoNFix9u6
        SSS0J1uUh2kzH8w+q7fHKiQzKz6vm8FGl3+Hs/azBjkwP+BrD0HXvcwa/DAAAAAElFTkSuQmCC"
              className="xl:h-8 xl:w-8  xs:h-6 xs:w-6  mr-2 object-contain inline-flex items-center justify-center"
              onClick={nextImage}
            />
          </button>
        </div>
        <div className="absolute inset-0 sm:flex  justify-end xs:items-start md:items-center sm:pt-52 xl:pt-72 xl:mr-60 sm:mr-36 z-20 hover:opacity-90 xs:hidden">
          {pause ? (
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAElElEQV
        R4nO1b3WtcVRA/oYKtX4g+SNvN3pmbaLSPPgta678QFGmT0q53zk0pIQr6ZuKrH2+FtihWfFBZszM3CRS1NKkoPohPPokPoqK20Tc/iqBxZW66243bz
        Wbv93U7MLAsu+ec3+/OOTNnZq4xNyUb8WVs1BP3EAnOWMGXLOMrJHhWVT9vfuf6+hv9rSm7eMtuVQGRYJ0EfraCzUFU/6P/9dixpSFkrl7ZQ+IcJcZVEt
        wYFHRvMnCDBC7q2DqHKZrM1A/cQQyzxPhjUqD7WMbCrDh3543bmKYZIcHjluGXtIHfiAjLeEzXkAt2n52HrOBnWQPvVvjUW3QezBQ8BTBlGX/PH3z7jLh
        qBWupA59fe/QWYng9b8A9lfHMZN3sSgX8yfPjtxLDYu4g+5IAy4l7irnQveGl3MHt3BLWEiNhsm52WYFG7qAGVBJY0S0bmwAq8p7vr6fjgRfnaAFAxLOE
        AKaigQ+qB4rk6iITIPCbDWBiMPRNM1KMICcphU8GihitYC0W6wx/EeNXVuDrJC9FsZRhekfgT54fvytObE8Mb54IRve1xvMXq65leCNvIkhg/fCH993e
        lwBf4IU0Tl2vUX1YTTFnS3huW/Bz9coey3AlGsN49Xi9cs+2EzTNiCfwJDF8nxMBP02fc3an4vZI4PO+5tWyhuW9t1mGecv4R6HcIjGuRh6Y8YOdEtCeb
        6Wynxjftoz/ZEYAw4UeT8WtxjmoohDQnpvdR0jgi0wIENyoNcYr3U9DwmxtMw8C2tmlAKZI8HLaJPgCXtf8luH9XAm4JuqqNOdHDH+maAXvdrMvg6eu0yC
        gJbTo3q93+3QIgPUtkaEvY6OxB02YgJbYhvu4ZfgyaRI6gzXjB/hEUQlQ0Xu97tu4VtqpfuAcTOwATJuAlpzgfffGPataqhUn00HAQhkIaEkiJDDMXx9Q
        4LUyEVBbqmLcyxUxvNoeMKzQloiAa2v+Np4F4JlSE2AZvkuMAFuyLaA5hqS3wEJpCAjTdQmk6TsPQSqRG0yqOrXFDXriHhq2QIjEeaw9gV4Phy0U9hrVvUN
        7GdK0X1eanATrw3IdtgLvdE1s2fWHJSFCDM90ze/L2OgwpMSs4N+ai7zhQkjg4v89KWoFPuq9IBnytPjcZifI5aiFEQ1USl0YUSGB56NbAZ41BS6NeYzPm
        n5yLJi4M05MQIJvdR4yM0tj40Uojqrv31FxVEU7MOOWx7U0bhm/yfaA207hiBmwBfZS/ovOqUFCRdtPw/aS3BcfUxl+rTE+YKKIbThP5Q4gpvqMhyOBb4m
        mjvIGEVVJ4JRJolGSytAi+1/wjEuJNEpe7xzBtRKBX+0b8ERplrYJVWVS1iC112omw75hPF3YJy9wKrV2+U7Rk7VILnJzLe7TJkuxAUzkHduHyvhxZD+fSI
        6eYVobDrIHru18cCS3l6a68ngMs5bxhwzMfV1zhtrRaoom0+ec3WFej+FC0i9OaiZHx07cvaUlWmvQYoZlfC/SFmG4os1MmsDsmcMrk9BKZb8WObQkpekwEn
        y59fJ0+JnhxfA94cA5uKV/56aYVOVflLORHazBVekAAAAASUVORK5CYII="
              className="lg:h-12 lg:w-12 sm:h-8 sm:w-8 object-contain cursor-pointer bg-bbf-black p-[6px] rounded-full border-bbf-white border-2"
              onClick={handlePause}
            />
          ) : (
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABcklEQVR4nL1VXU7CQBDeM6gPimBn0HgWjeglNN2B4mF8MhhP0L
          gzRFFvIuopBHxDo9milZ9ugdI6ySTNNvlmvm/+lEqxuuxWNGOTBB9JsEuM75ELdrXgAxkMzsxeWS1r+q68rQVbmmFIgl9prgU/tWDYuAFvIXBqwwkx9OcB07Qz9H3B41RwX+DcZrQ0uIyxYWwmZ87eIQl+ZAWns
          SBWhRnNM8kijiAMPd/sbI1lD9d5gdMfk1bcii5pTsPy2m8SAZfW571PsRhaZaLCurKYqdOc9wQWDRUNTGEBoKNI4LWwAIzPSgsMCmQwUGntuToD6FkGLwUW+ekfimwwKCqAzx4pu89da9kOUdJAud4TB+0ngyt
          XFpTdL2OKQbtSynPZkcBbI/Q2J3T0jXeQ27pmrKkks8di1YNDBoNE8LgjGGt2QLLIUjfVo1TwWK7b/Q0teLHQ0bfdItiaODCL2ujSVevEcG+n0u6WkUffHfsvbkWHfQNenGYDVNJpJgAAAABJRU5ErkJggg=="
              className="lg:h-12 lg:w-12 sm:h-8 sm:w-8object-contain cursor-pointer bg-bbf-black p-[6px] rounded-full border-bbf-white border-2"
              onClick={handlePause}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SliderSection;
