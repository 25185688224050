import { useEffect } from "react";
import React from "react";
import { useTranslation } from "react-i18next";

const Impressum = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { t } = useTranslation();
  return (
    <div className="min-h-[calc(100vh-100px)] h-auto font-roboto md:mb-[30px] xl:mb-[35px]  xs:mb-[15px]">
      <div className="xs:mt-9 lg:mt-40 flex items-start xs:gap-3 md:gap-4  flex-col md:mx-[70px] xs:mx-5">
        <h2 className="xs:text-[15px] md:text-[20px] xl:text-[25px] font-roboto font-bold  md:my-5 xs:my-4 underline underline-offset-[12px] decoration-bbf-green">
          {t("imprint").toUpperCase()}
        </h2>
        <h3 className="xs:text-[13px] md:text-[15px] xl:text-[17px]  font-semibold mb-2">
          BBF BIKE GmbH <br />
          Carenaallee 8 <br />
          15366 Hoppegarten
        </h3>

        <div className=" flex md:gap-15 xs:gap-10  flex-col xs:text-[13px] md:text-[15px] xl:text-[17px] ">
          <p className="flex items-center gap-4">
            <h3>
              <img
                width="40"
                height="40"
                src="https://img.icons8.com/ios-filled/50/apple-phone.png"
                alt="apple-phone"
              />
            </h3>
            <h3>0 33 42 / 35 43 25</h3>
          </p>
          <p className="flex items-center gap-4">
            <h3>
              <img
                width="40"
                height="40"
                src="https://img.icons8.com/external-tanah-basah-glyph-tanah-basah/48/external-Fax-contact-us-tanah-basah-glyph-tanah-basah-2.png"
                alt="external-Fax-contact-us-tanah-basah-glyph-tanah-basah-2"
              />
            </h3>
            <h3>0 33 42 / 35 43 32</h3>
          </p>
          <p className="flex items-center gap-4">
            <h3>
              <img
                width="40"
                height="40"
                src="https://img.icons8.com/hatch/50/domain.png"
                alt="domain"
              />
            </h3>
            <h3>www.bbf-bike.de</h3>
          </p>
          <p className="flex items-center gap-4">
            <h3>
              <img
                width="40"
                height="40"
                src="https://img.icons8.com/ios-filled/50/new-post.png"
                alt="new-post"
              />
            </h3>
            <h3>info@bbf-bike.de</h3>
          </p>
        </div>
        <p className="md:w-3/6  xs:mt-10 flex  items-start xs:text-[13px] md:text-[15px] xl:text-[17px] ">
          Umsatzsteuer-Identifikationsnummer gemäß § 27a Umsatzsteuergesetz: DE
          811309641 <br />
          Registergericht: Landesgericht Frankfurt/Oder <br />
          Registernummer: HRB 2082 FF <br />
          Geschäftsführender Gesellschafter: Hagen Stamm <br />
          WEEEE-Nr DE95 5804 62
        </p>

        <div className=" flex items-start justify-start flex-col  md:w-3/6">
          <h3 className="font-semibold xs:text-[16px] md:text-[18px] mb-2">
            HAFTUNGSHINWEIS
          </h3>
          <p className="xs:text-[13px] md:text-[15px] xl:text-[17px]  text-justify">
            Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine
            Haftung für die Inhalte externer Links. Für den Inhalt der
            verlinkten Seiten sind ausschließlich deren Betreiber
            verantwortlich.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Impressum;
