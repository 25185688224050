import axios from "axios";
import React, { useEffect, useState } from "react";
import MarkerPic from "../Images/Marker.png";
import headCentre from "../Images/BBF_Icon.png"
import {
  TileLayer,
  MapContainer,
  Marker,
  Popup,
  ZoomControl,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { Link } from "react-router-dom";
import locations from "../data/branches.json";
import { useTranslation } from "react-i18next";
import { Button } from "./Button";
const Places = () => {
  //     const[locations,setLocations]= useState([])
  //     const locationResults= async()=>{
  //         try{
  //   const response = await axios.get("https://api.bbf-bike.de/api/web/data/bbf-bike/branches")
  //     setLocations(response.data)
  //         }
  //         catch(err){
  //   console.log(err)
  //         }
  //     }
  //     useEffect(()=>{
  //  locationResults()
  //     },[])
  //     console.log("loc",locations)
  const customIcon = L.icon({
    iconUrl: MarkerPic,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });
  const customIcon2 = L.icon({
    iconUrl: headCentre,
    iconSize: [40, 55],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  return (
    <div className="flex flex-col justify-center items-center">
      <h1 className="xl:text-[45px] md:text-[35px] sm:text-[20px] xs:p-2 md:p-3 xl:p-4 font-roboto font-bold xl:my-4 md:my-3 xs:my-2 underline underline-offset-[12px] decoration-bbf-green">
        {t("ourlocations")}
      </h1>
      <div className="bg-bbf-green w-full flex justify-center items-center flex-col xs:py-8 md:py-12 xl:py-20">
      <div className="xl:h-[600px] xl:w-[1200px] lg:w-[900px] lg:h-[550px] md:h-[550px] md:w-[700px] sm:w-[370px] sm:h-[300px] xs:w-[250px] xs:px-4 md:px-0 xs:h-[300px]">
        <MapContainer
          center={[51.1657, 10.4515]}
          zoom={6}
          style={{ height: "100%", width: "100%" }}
          zoomControl={true}
          className="z-0 md:block xs:hidden"
        >
         
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">
              OpenStreetMap</a> contributors'
          />
          <ZoomControl position="topright" />
          {locations.map((item, index) => (
            <div> {item.name==="Stammhaus Hoppegarten"?(  <Marker
              key={index}
              icon={customIcon2}
              position={[item.coordinates.lat, item.coordinates.lng]}
            >
              <div>{item.name==="Stammhaus Hoppegarten"}</div>
              <Popup>
                <div className="lg:p-4 md:p-3 xs:p-1 sm:p-0 ">
                  <h3 className="font-roboto font-bold xs:text-[15px] md:text-[18px] xl:text-[22px]">
                    {item.name.toUpperCase()}
                  </h3>
                  <h2 className="flex  items-start justify-start gap-3 mt-3 xs:text-[13px] md:text-[17px] xs:hidden md:block">
                    {" "}
                    <div><img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAs
            TAAALEwEAmpwYAAAFR0lEQVR4nO1aaWgdVRS+KVHcal2r1ZA5ZxoTLSqu+EdIVcQFpShGSyuIMcy9LxKXH1K1yqtVfyjFfyp1Qf1RlGfn3Gmi4lKISMV
            StYoLRQWpiGBVrFat0TSNnHvvvCx2Zt7Lm8kifjAQ3jvv3HPm7OdGiP/xb/S91nak0v4KSfC01LBNavhBafibH/6bP1Man1Kht7w76pgvZhtUBB1Sw7N
            S416lcbSmh/APRfCM3OifMtPyi6B/0WGSYJ0kGHbC7TeWIFxdIuhkBW9844TD+eG/zWcE9ymC95nWKsXWwkdves47ZEaU4DcpNXzKwkiNI5JgQ0Bti2v
            9fe+mxW1S44uxQlLje0HYukhMJ1TknW39nwWAL4Kw9Zyp8goi/zyl4UtnnW9Lm/BMMY2WMEooDW+WXmk9ulGet1RajpEEb8XKFG4ZExPOnViJ8mBnc26
            81597kCLYHLtZoTHDgR27Ux6WSLDMVy6zPSAKS7EEwxzYjcREFkohnm8SAOHvvRXvxNwP4DphrEGwQRQMRfiSs/wT+VdsU8Bwfy0pVoX+QqnxQUn4Eb9
            ZfiThdkm4tq/SdnwtCUVVrbLkiNwU4bbDvaFtmUJEeJ0i2JNU0SXBrwH512by0fAB0wcabshNEe6drBC4OlsJV+AINVfyuLJL7S1VGqNqAY28a9J4KQ3
            3O9r1+Slim71RFizx4NBfWLUEwV1JdCUNq1z6/iXobz8ukS7yLnJtz9Y8dLBCEvzITHsI25NoOCZiS9TArz8rxfYQtjt33tWI7BMP1vAXM01rvZXGj7O
            sNvltcwJIoumOOua7mBpqRPbJQv7JTNOqrdTwG9PUkmViIdkV07oIZWNkr8gLiuB7K2RygYrjoxZF7ABmM1gSjRxoOdlZ7btGZJ8opMYd5g2G/umJB3P
            NMG/QW5rJL/QvdjHyYRJNr/bOcsp+1ojsE4XUMOCYXp9IQ7jWCRfVzE/jmkSaEFe6rEWNyD5JSHjYpcyHkmi4YrOrmIDXsCqZF97jXGb3rXTSsUl0ivC
            RLGXrBlvCBefmNDqu2FzsHG0/ZyeOGX7YncZZgifKZWm8lIYtzgtS6eqC65348CHOJmm0XLG52CW3KLg7S7jbtXeU6bQJhoOKvyA3RYyAhNvt2/SvzKL
            lis3FjoPZpGWCPdw7sZukuVOMEmGXc+UtIm9UK7fG50XBUBrCrFibMoKN3qnV7jXDvRo6p+IvMAXYrJY8KOQQdhWjTIgrC1NEQ5+z/NtFncHu1RsvB4r
            gXx7sbFaEX9v48FeIomDSqMtIPFvnzV+F3vK4LeGtSt78Jx5G8JirEy8X5bpK472iaPDyLA7GQPtn5MVXhnhpPHBxHRHTAUXweN5W4eBWRe6zDoSesK1
            lbKviX9goP9sNmCTyUxGLv/TDNa6Jp7xyWcybKp+uypKD4yW21Ngrpht3VloOlRp3mgwW+TdPlQ8vKly7/nmeu+Qp7bt4guSpr97fc+s/1mDCZWLGMCq
            aJMG7rnVZV+/PFeELblMyUIyAdV762AtP3BdEcEGtvwtC7/L4LrGem65CMW7M3VHLvYbZohB8Y/cAcJuYLejizEPwSdY4HENpfDKeNxrJeIXAbj3MDe1
            I2ibFuBS36ARDJfJOE7MRyk6FXA92HqjNsCMz7LLW8O4QsxXlwc5mSfBOdY0zKpqq35XFPEn4ukvXr47/blaix7Qvduk9/hoiHpW55hRypVYEAo1X21E
            VRxR5VyjCq9yaaF+g/UvEXIIc68V+5hWQtYZ/t5hzGBVNUmNl3D/RTIiZOYVuU/hwK8/4uV5qiv8w/gF0K4RGYBI0eQAAAABJRU5ErkJggg=="
                      className="w-6 h-6 mb-2"
                    /></div>
                    
                    <div>{item.address}</div>
                    
                  </h2>
                  <h2 className="flex flex-col items-center mt-3 text-[16px] xs:hidden md:block">
                    {" "}
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAE
            j0lEQVR4nO1aXWhcVRA+8R/FH/yraZO9MzehghRU+uBPEVHEF1GQEgVriU3TO2eTxj6KT3kofVKpKFUDiqBv696ZTSLRPkiLD/600FoQafWhKiqCNdqqGH8jc/eczdruTXdLs
            jmBfLBPO+funXNmvvlmzhqzghUsHWwFHrIMB4lhhhiOJBzdYZYT+ko3XWQZ9ljB2foPMf5Ek11rzHJAUoqvJIH33Mv/Tozbk4m11xLjeOaM4JQJHUOl6AYr+Inb/W+L0rO+/j
            sSPJE5yDhgQsXIVO8VxHjI7fpnQ2WITrehFDdVTwp+TibiggkNI1O9FxPD+9WTgE+HefU1ebbEKMGGGDHsqoYMfDWY9nY1EX4/Ovt+ExKIcVpfrFiJ7mnG3gpsdnk0naSFThM
            KiGFGX2yHRFc1vUbgbeeMmFBADF9koZLG65pdoyGoSa/rEoFHTQggn8ApbmptHWyrJj4cMyGAGLe7MHmjlXVaKF3h/NvMmo7Fe8MmkXBvj2X81zKcGqjceHmz60iiJxx7HTShg
            JwsSTiyzdj3vx5dQoJfBkfDxPCIj/fRfXdfcHZ7fNqF4+HRUXOeCQV9JXO+ShPnDM1na9P4emI4Wa09eJ8JDVZwo0ve75KJzkvz7EhwzJ3GuAkSs6bDCnzsFO4z+bkBvziHB02
            osJXoVivwJwn+YwXvamiTwpO+0RocL6AJFSS40yd+wxCbNR0kMOlsDswXhkve6hLDEUetexrZqC7z5GAFK0oWJkQMSXSLtrpVZoq35BdS+MH1J2NBVPdGUAd8755U4LZGNjpZ
            8clPjLtNqLCML3hKzpug2DS+15+eFXjOhIhkbP2FlnGfc+aDvMROBB9UtnPt8i4TIkZKvddZxuPOmXeUDBrZFRn75pzB3UHmzCDjWsvwvUvsUh5LVU/GhRnjK0GyGaVwsxZBR8
            uv5u245kxd9a80Ckd1UMlERSdlxRc+V+Fq2oWi9NxpGX89W2LbNLrdU7P2K/VzMhWatTp1xlgWylvHcVV7nGG43wr8UXMm52SK5UJcU9SM09oae0XgQu+49jJJNpKFbV5RVyea
            8WNtcYYq0cPeGRJ4LS8XVAFYhonTdv1kUeApFaD/c1x6upVM6obmonO09pyMDzOGt/LYTE8sERhxefOy9jPzPdcyDtRyMRsGwmaz2KByvMH/KDG+O5+A1NFs08+d7FrjZ2fu1C
            eHK92rzaLrsjlq/nAhp4+Wod9PQnXD8qTSgtaZuWEEfrOQP5ikhc5a28B42LRDAZDgfpfQM3mq+VywtdR1tduk30zbtJnAi3Wx/dJCNF7EsMPrPdNOVKt2dTieVe1yvOFcn2W1
            uDqqL6bxA6bdUBLwFVxlCDE8+/jeVZe18oxiuRB7IrGCz5slbZsFdxLDX7W+RoCaGQKOaM4xHnUbsVfD1iw19GLVMn5UlzvHdDybN2suSk93nbw51MpMuk1zM9zod9kpglMk8G
            Z20ZrG6/R6PFPPDF97um2biGwVGlZ6OZQNzvUWQM5UwS6c9qtjZjkgS+YUhy0jK7tlf1IQPKF51YqkWcEKzMLiPwd3DQyQvIVxAAAAAElFTkSuQmCC"
                      className="w-6 h-6 mb-2"
                    />
                    {item.contact.phone}
                  </h2>
                  <h2 className="flex flex-col items-center gap-1 mt-3 text-[16px] xs:hidden md:block">
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACN0lEQVR4nO2X
            P4gTURDG36kgKJ6IyKEXk5m9FQULwUKwUFFsFCyvEwuVnVkl2GkZSzuxtBPLkMzsGQ2IiFgIFgcKgiAIggiCiHCCqCcaebrJrZzH5Y+5vMT3g1fssm/3+3bmzZtnjMfj8XgGASs2
            RmGYkTNihhT2RhyDRz8iDTNmXKexoHFJI6QwGyVw0DgKCe4jgUfLGmlek0ItruQD4wixTm0nwZss+ONvehdHRPAqK8yn9z+T4JViPRwflIFiPRy3GqyW35pg3mpc1oiFKsEOUiwv
            RAffk8CF6bJZvVIGSiWzihI4RYpvWzoE7p2bmdrdcdWKk8JhEnySMfScNDjebxNcDY6w4tPsd1kKx3oqv+3+mX8BdZEJHe8jJ+9OrCfFy3/kquL1Yjnc0quB+HZ+k10HJPAlffdX
            UrwWlYONfdsQz1bDXLZ6kOCHWOFSsR6u7dRA6cGhNbFCRArvuq2W3OvO3qznGQEvYsHpdufHCR4lhWeZ+bOseGAwLUrDjFnxLPgqI+g+VWHPUlOiSmEXC9xpteCCb2xU7Fo0g+61
            oltb19n0YoGP6SL9btPvzAxONJ85L9s227wngW+pgU92XZxOdm5wrmmkWm6SFG9YI2l1myOFi7+GwFzLpH2mlpt0vvuNqvm9LPhw0UlO8HEkhf1D18ZHiidY4SUJvLZ7UT86a16p
            84gty92U5nb5Dw5WQwZ7I47BPiKOwT4ijsE+Io7BPiKuR4SHfJiRMeLxeDweMwB+An37+6BMF2JeAAAAAElFTkSuQmCC"
                      className="w-6 h-6 mb-2"
                    />
                    {item.contact.email}
                  </h2>
                  <div className="lg:mt-5 md:mt-5 xs:mt-2">
                    {" "}
                    <Link to={`/locations/${item.branchId}`}>
                      <Button text={t("moredetails")}  color="bbf-black"/>
                      {/* <button className="lg:mt-[40px] xs:mt-0 xs:h-[20px] xs:w-[90px] xs:text-[8px] md:text-[14px] lg:h-[43px] lg:w-[135px] text-bbf-white bg-bbf-black rounded-[5px]  hover:bg-opacity-90 text-[12px]">
                        More details
                      </button> */}
                    </Link>{" "}
                  </div>
                </div>
              </Popup>
            </Marker>):(<Marker
              key={index}
              icon={customIcon}
              position={[item.coordinates.lat, item.coordinates.lng]}
            >
              <div>{item.name==="Stammhaus Hoppegarten"}</div>
              <Popup>
                <div className="lg:p-4 md:p-3 xs:p-1 sm:p-0 ">
                  <h3 className="font-roboto font-bold xs:text-[15px] md:text-[18px] xl:text-[22px]">
                    {item.name.toUpperCase()}
                  </h3>
                  <h2 className="flex  items-start justify-start gap-3 mt-3 text-[16px] xs:hidden md:block">
                    {" "}
                    <div><img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAs
            TAAALEwEAmpwYAAAFR0lEQVR4nO1aaWgdVRS+KVHcal2r1ZA5ZxoTLSqu+EdIVcQFpShGSyuIMcy9LxKXH1K1yqtVfyjFfyp1Qf1RlGfn3Gmi4lKISMV
            StYoLRQWpiGBVrFat0TSNnHvvvCx2Zt7Lm8kifjAQ3jvv3HPm7OdGiP/xb/S91nak0v4KSfC01LBNavhBafibH/6bP1Man1Kht7w76pgvZhtUBB1Sw7N
            S416lcbSmh/APRfCM3OifMtPyi6B/0WGSYJ0kGHbC7TeWIFxdIuhkBW9844TD+eG/zWcE9ymC95nWKsXWwkdves47ZEaU4DcpNXzKwkiNI5JgQ0Bti2v
            9fe+mxW1S44uxQlLje0HYukhMJ1TknW39nwWAL4Kw9Zyp8goi/zyl4UtnnW9Lm/BMMY2WMEooDW+WXmk9ulGet1RajpEEb8XKFG4ZExPOnViJ8mBnc26
            81597kCLYHLtZoTHDgR27Ux6WSLDMVy6zPSAKS7EEwxzYjcREFkohnm8SAOHvvRXvxNwP4DphrEGwQRQMRfiSs/wT+VdsU8Bwfy0pVoX+QqnxQUn4Eb9
            ZfiThdkm4tq/SdnwtCUVVrbLkiNwU4bbDvaFtmUJEeJ0i2JNU0SXBrwH512by0fAB0wcabshNEe6drBC4OlsJV+AINVfyuLJL7S1VGqNqAY28a9J4KQ3
            3O9r1+Slim71RFizx4NBfWLUEwV1JdCUNq1z6/iXobz8ukS7yLnJtz9Y8dLBCEvzITHsI25NoOCZiS9TArz8rxfYQtjt33tWI7BMP1vAXM01rvZXGj7O
            sNvltcwJIoumOOua7mBpqRPbJQv7JTNOqrdTwG9PUkmViIdkV07oIZWNkr8gLiuB7K2RygYrjoxZF7ABmM1gSjRxoOdlZ7btGZJ8opMYd5g2G/umJB3P
            NMG/QW5rJL/QvdjHyYRJNr/bOcsp+1ojsE4XUMOCYXp9IQ7jWCRfVzE/jmkSaEFe6rEWNyD5JSHjYpcyHkmi4YrOrmIDXsCqZF97jXGb3rXTSsUl0ivC
            RLGXrBlvCBefmNDqu2FzsHG0/ZyeOGX7YncZZgifKZWm8lIYtzgtS6eqC65348CHOJmm0XLG52CW3KLg7S7jbtXeU6bQJhoOKvyA3RYyAhNvt2/SvzKL
            lis3FjoPZpGWCPdw7sZukuVOMEmGXc+UtIm9UK7fG50XBUBrCrFibMoKN3qnV7jXDvRo6p+IvMAXYrJY8KOQQdhWjTIgrC1NEQ5+z/NtFncHu1RsvB4r
            gXx7sbFaEX9v48FeIomDSqMtIPFvnzV+F3vK4LeGtSt78Jx5G8JirEy8X5bpK472iaPDyLA7GQPtn5MVXhnhpPHBxHRHTAUXweN5W4eBWRe6zDoSesK1
            lbKviX9goP9sNmCTyUxGLv/TDNa6Jp7xyWcybKp+uypKD4yW21Ngrpht3VloOlRp3mgwW+TdPlQ8vKly7/nmeu+Qp7bt4guSpr97fc+s/1mDCZWLGMCq
            aJMG7rnVZV+/PFeELblMyUIyAdV762AtP3BdEcEGtvwtC7/L4LrGem65CMW7M3VHLvYbZohB8Y/cAcJuYLejizEPwSdY4HENpfDKeNxrJeIXAbj3MDe1
            I2ibFuBS36ARDJfJOE7MRyk6FXA92HqjNsCMz7LLW8O4QsxXlwc5mSfBOdY0zKpqq35XFPEn4ukvXr47/blaix7Qvduk9/hoiHpW55hRypVYEAo1X21E
            VRxR5VyjCq9yaaF+g/UvEXIIc68V+5hWQtYZ/t5hzGBVNUmNl3D/RTIiZOYVuU/hwK8/4uV5qiv8w/gF0K4RGYBI0eQAAAABJRU5ErkJggg=="
                      className="w-6 h-6 mb-2"
                    /></div>
                    
                    <div>{item.address}</div>
                    
                  </h2>
                  <h2 className="flex flex-col items-center mt-3 text-[16px] xs:hidden md:block">
                    {" "}
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAE
            j0lEQVR4nO1aXWhcVRA+8R/FH/yraZO9MzehghRU+uBPEVHEF1GQEgVriU3TO2eTxj6KT3kofVKpKFUDiqBv696ZTSLRPkiLD/600FoQafWhKiqCNdqqGH8jc/eczdruTXdLs
            jmBfLBPO+funXNmvvlmzhqzghUsHWwFHrIMB4lhhhiOJBzdYZYT+ko3XWQZ9ljB2foPMf5Ek11rzHJAUoqvJIH33Mv/Tozbk4m11xLjeOaM4JQJHUOl6AYr+Inb/W+L0rO+/j
            sSPJE5yDhgQsXIVO8VxHjI7fpnQ2WITrehFDdVTwp+TibiggkNI1O9FxPD+9WTgE+HefU1ebbEKMGGGDHsqoYMfDWY9nY1EX4/Ovt+ExKIcVpfrFiJ7mnG3gpsdnk0naSFThM
            KiGFGX2yHRFc1vUbgbeeMmFBADF9koZLG65pdoyGoSa/rEoFHTQggn8ApbmptHWyrJj4cMyGAGLe7MHmjlXVaKF3h/NvMmo7Fe8MmkXBvj2X81zKcGqjceHmz60iiJxx7HTShg
            JwsSTiyzdj3vx5dQoJfBkfDxPCIj/fRfXdfcHZ7fNqF4+HRUXOeCQV9JXO+ShPnDM1na9P4emI4Wa09eJ8JDVZwo0ve75KJzkvz7EhwzJ3GuAkSs6bDCnzsFO4z+bkBvziHB02
            osJXoVivwJwn+YwXvamiTwpO+0RocL6AJFSS40yd+wxCbNR0kMOlsDswXhkve6hLDEUetexrZqC7z5GAFK0oWJkQMSXSLtrpVZoq35BdS+MH1J2NBVPdGUAd8755U4LZGNjpZ
            8clPjLtNqLCML3hKzpug2DS+15+eFXjOhIhkbP2FlnGfc+aDvMROBB9UtnPt8i4TIkZKvddZxuPOmXeUDBrZFRn75pzB3UHmzCDjWsvwvUvsUh5LVU/GhRnjK0GyGaVwsxZBR8
            uv5u245kxd9a80Ckd1UMlERSdlxRc+V+Fq2oWi9NxpGX89W2LbNLrdU7P2K/VzMhWatTp1xlgWylvHcVV7nGG43wr8UXMm52SK5UJcU9SM09oae0XgQu+49jJJNpKFbV5RVyea
            8WNtcYYq0cPeGRJ4LS8XVAFYhonTdv1kUeApFaD/c1x6upVM6obmonO09pyMDzOGt/LYTE8sERhxefOy9jPzPdcyDtRyMRsGwmaz2KByvMH/KDG+O5+A1NFs08+d7FrjZ2fu1C
            eHK92rzaLrsjlq/nAhp4+Wod9PQnXD8qTSgtaZuWEEfrOQP5ikhc5a28B42LRDAZDgfpfQM3mq+VywtdR1tduk30zbtJnAi3Wx/dJCNF7EsMPrPdNOVKt2dTieVe1yvOFcn2W1
            uDqqL6bxA6bdUBLwFVxlCDE8+/jeVZe18oxiuRB7IrGCz5slbZsFdxLDX7W+RoCaGQKOaM4xHnUbsVfD1iw19GLVMn5UlzvHdDybN2suSk93nbw51MpMuk1zM9zod9kpglMk8G
            Z20ZrG6/R6PFPPDF97um2biGwVGlZ6OZQNzvUWQM5UwS6c9qtjZjkgS+YUhy0jK7tlf1IQPKF51YqkWcEKzMLiPwd3DQyQvIVxAAAAAElFTkSuQmCC"
                      className="w-6 h-6 mb-2"
                    />
                    {item.contact.phone}
                  </h2>
                  <h2 className="flex flex-col items-center gap-1 mt-3 text-[16px] xs:hidden md:block">
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACN0lEQVR4nO2X
            P4gTURDG36kgKJ6IyKEXk5m9FQULwUKwUFFsFCyvEwuVnVkl2GkZSzuxtBPLkMzsGQ2IiFgIFgcKgiAIggiCiHCCqCcaebrJrZzH5Y+5vMT3g1fssm/3+3bmzZtnjMfj8XgGASs2
            RmGYkTNihhT2RhyDRz8iDTNmXKexoHFJI6QwGyVw0DgKCe4jgUfLGmlek0ItruQD4wixTm0nwZss+ONvehdHRPAqK8yn9z+T4JViPRwflIFiPRy3GqyW35pg3mpc1oiFKsEOUiwv
            RAffk8CF6bJZvVIGSiWzihI4RYpvWzoE7p2bmdrdcdWKk8JhEnySMfScNDjebxNcDY6w4tPsd1kKx3oqv+3+mX8BdZEJHe8jJ+9OrCfFy3/kquL1Yjnc0quB+HZ+k10HJPAlffdX
            UrwWlYONfdsQz1bDXLZ6kOCHWOFSsR6u7dRA6cGhNbFCRArvuq2W3OvO3qznGQEvYsHpdufHCR4lhWeZ+bOseGAwLUrDjFnxLPgqI+g+VWHPUlOiSmEXC9xpteCCb2xU7Fo0g+61
            oltb19n0YoGP6SL9btPvzAxONJ85L9s227wngW+pgU92XZxOdm5wrmmkWm6SFG9YI2l1myOFi7+GwFzLpH2mlpt0vvuNqvm9LPhw0UlO8HEkhf1D18ZHiidY4SUJvLZ7UT86a16p
            84gty92U5nb5Dw5WQwZ7I47BPiKOwT4ijsE+Io7BPiKuR4SHfJiRMeLxeDweMwB+An37+6BMF2JeAAAAAElFTkSuQmCC"
                      className="w-6 h-6 mb-2"
                    />
                    {item.contact.email}
                  </h2>
                  <div className="lg:mt-5 md:mt-5 xs:mt-2">
                    {" "}
                    <Link to={`/locations/${item.branchId}`}>
                      <Button text={t("moredetails")} color="bbf-black"/>
                      {/* <button className="lg:mt-[40px] xs:mt-0 xs:h-[20px] xs:w-[90px] xs:text-[8px] md:text-[14px] lg:h-[43px] lg:w-[135px] text-bbf-white bg-bbf-black rounded-[5px]  hover:bg-opacity-90 text-[12px]">
                        More details
                      </button> */}
                    </Link>{" "}
                  </div>
                </div>
              </Popup>
            </Marker>)}
          
            </div>
          ))}
        </MapContainer>



        <MapContainer
          center={[51.1657, 10.4515]}
          zoom={5}
          style={{ height: "100%", width: "100%" }}
          zoomControl={true}
          className="z-0 md:hidden xs:block"
        >
         
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">
              OpenStreetMap</a> contributors'
          />
          <ZoomControl position="topright" />
          {locations.map((item, index) => (
            <div> {item.name==="Stammhaus Hoppegarten"?(  <Marker
              key={index}
              icon={customIcon2}
              position={[item.coordinates.lat, item.coordinates.lng]}
            >
              <div>{item.name==="Stammhaus Hoppegarten"}</div>
              <Popup>
                <div className="lg:p-4 md:p-3 xs:p-1 sm:p-0 ">
                  <h3 className="font-roboto font-bold xs:text-[15px] md:text-[18px] xl:text-[22px]">
                    {item.name.toUpperCase()}
                  </h3>
                  <h2 className="flex  items-start justify-start gap-3 mt-3 text-[16px] xs:hidden md:block">
                    {" "}
                    <div><img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAs
            TAAALEwEAmpwYAAAFR0lEQVR4nO1aaWgdVRS+KVHcal2r1ZA5ZxoTLSqu+EdIVcQFpShGSyuIMcy9LxKXH1K1yqtVfyjFfyp1Qf1RlGfn3Gmi4lKISMV
            StYoLRQWpiGBVrFat0TSNnHvvvCx2Zt7Lm8kifjAQ3jvv3HPm7OdGiP/xb/S91nak0v4KSfC01LBNavhBafibH/6bP1Man1Kht7w76pgvZhtUBB1Sw7N
            S416lcbSmh/APRfCM3OifMtPyi6B/0WGSYJ0kGHbC7TeWIFxdIuhkBW9844TD+eG/zWcE9ymC95nWKsXWwkdves47ZEaU4DcpNXzKwkiNI5JgQ0Bti2v
            9fe+mxW1S44uxQlLje0HYukhMJ1TknW39nwWAL4Kw9Zyp8goi/zyl4UtnnW9Lm/BMMY2WMEooDW+WXmk9ulGet1RajpEEb8XKFG4ZExPOnViJ8mBnc26
            81597kCLYHLtZoTHDgR27Ux6WSLDMVy6zPSAKS7EEwxzYjcREFkohnm8SAOHvvRXvxNwP4DphrEGwQRQMRfiSs/wT+VdsU8Bwfy0pVoX+QqnxQUn4Eb9
            ZfiThdkm4tq/SdnwtCUVVrbLkiNwU4bbDvaFtmUJEeJ0i2JNU0SXBrwH512by0fAB0wcabshNEe6drBC4OlsJV+AINVfyuLJL7S1VGqNqAY28a9J4KQ3
            3O9r1+Slim71RFizx4NBfWLUEwV1JdCUNq1z6/iXobz8ukS7yLnJtz9Y8dLBCEvzITHsI25NoOCZiS9TArz8rxfYQtjt33tWI7BMP1vAXM01rvZXGj7O
            sNvltcwJIoumOOua7mBpqRPbJQv7JTNOqrdTwG9PUkmViIdkV07oIZWNkr8gLiuB7K2RygYrjoxZF7ABmM1gSjRxoOdlZ7btGZJ8opMYd5g2G/umJB3P
            NMG/QW5rJL/QvdjHyYRJNr/bOcsp+1ojsE4XUMOCYXp9IQ7jWCRfVzE/jmkSaEFe6rEWNyD5JSHjYpcyHkmi4YrOrmIDXsCqZF97jXGb3rXTSsUl0ivC
            RLGXrBlvCBefmNDqu2FzsHG0/ZyeOGX7YncZZgifKZWm8lIYtzgtS6eqC65348CHOJmm0XLG52CW3KLg7S7jbtXeU6bQJhoOKvyA3RYyAhNvt2/SvzKL
            lis3FjoPZpGWCPdw7sZukuVOMEmGXc+UtIm9UK7fG50XBUBrCrFibMoKN3qnV7jXDvRo6p+IvMAXYrJY8KOQQdhWjTIgrC1NEQ5+z/NtFncHu1RsvB4r
            gXx7sbFaEX9v48FeIomDSqMtIPFvnzV+F3vK4LeGtSt78Jx5G8JirEy8X5bpK472iaPDyLA7GQPtn5MVXhnhpPHBxHRHTAUXweN5W4eBWRe6zDoSesK1
            lbKviX9goP9sNmCTyUxGLv/TDNa6Jp7xyWcybKp+uypKD4yW21Ngrpht3VloOlRp3mgwW+TdPlQ8vKly7/nmeu+Qp7bt4guSpr97fc+s/1mDCZWLGMCq
            aJMG7rnVZV+/PFeELblMyUIyAdV762AtP3BdEcEGtvwtC7/L4LrGem65CMW7M3VHLvYbZohB8Y/cAcJuYLejizEPwSdY4HENpfDKeNxrJeIXAbj3MDe1
            I2ibFuBS36ARDJfJOE7MRyk6FXA92HqjNsCMz7LLW8O4QsxXlwc5mSfBOdY0zKpqq35XFPEn4ukvXr47/blaix7Qvduk9/hoiHpW55hRypVYEAo1X21E
            VRxR5VyjCq9yaaF+g/UvEXIIc68V+5hWQtYZ/t5hzGBVNUmNl3D/RTIiZOYVuU/hwK8/4uV5qiv8w/gF0K4RGYBI0eQAAAABJRU5ErkJggg=="
                      className="w-6 h-6 mb-2"
                    /></div>
                    
                    <div>{item.address}</div>
                    
                  </h2>
                  <h2 className="flex flex-col items-center mt-3 text-[16px] xs:hidden md:block">
                    {" "}
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAE
            j0lEQVR4nO1aXWhcVRA+8R/FH/yraZO9MzehghRU+uBPEVHEF1GQEgVriU3TO2eTxj6KT3kofVKpKFUDiqBv696ZTSLRPkiLD/600FoQafWhKiqCNdqqGH8jc/eczdruTXdLs
            jmBfLBPO+funXNmvvlmzhqzghUsHWwFHrIMB4lhhhiOJBzdYZYT+ko3XWQZ9ljB2foPMf5Ek11rzHJAUoqvJIH33Mv/Tozbk4m11xLjeOaM4JQJHUOl6AYr+Inb/W+L0rO+/j
            sSPJE5yDhgQsXIVO8VxHjI7fpnQ2WITrehFDdVTwp+TibiggkNI1O9FxPD+9WTgE+HefU1ebbEKMGGGDHsqoYMfDWY9nY1EX4/Ovt+ExKIcVpfrFiJ7mnG3gpsdnk0naSFThM
            KiGFGX2yHRFc1vUbgbeeMmFBADF9koZLG65pdoyGoSa/rEoFHTQggn8ApbmptHWyrJj4cMyGAGLe7MHmjlXVaKF3h/NvMmo7Fe8MmkXBvj2X81zKcGqjceHmz60iiJxx7HTShg
            JwsSTiyzdj3vx5dQoJfBkfDxPCIj/fRfXdfcHZ7fNqF4+HRUXOeCQV9JXO+ShPnDM1na9P4emI4Wa09eJ8JDVZwo0ve75KJzkvz7EhwzJ3GuAkSs6bDCnzsFO4z+bkBvziHB02
            osJXoVivwJwn+YwXvamiTwpO+0RocL6AJFSS40yd+wxCbNR0kMOlsDswXhkve6hLDEUetexrZqC7z5GAFK0oWJkQMSXSLtrpVZoq35BdS+MH1J2NBVPdGUAd8755U4LZGNjpZ
            8clPjLtNqLCML3hKzpug2DS+15+eFXjOhIhkbP2FlnGfc+aDvMROBB9UtnPt8i4TIkZKvddZxuPOmXeUDBrZFRn75pzB3UHmzCDjWsvwvUvsUh5LVU/GhRnjK0GyGaVwsxZBR8
            uv5u245kxd9a80Ckd1UMlERSdlxRc+V+Fq2oWi9NxpGX89W2LbNLrdU7P2K/VzMhWatTp1xlgWylvHcVV7nGG43wr8UXMm52SK5UJcU9SM09oae0XgQu+49jJJNpKFbV5RVyea
            8WNtcYYq0cPeGRJ4LS8XVAFYhonTdv1kUeApFaD/c1x6upVM6obmonO09pyMDzOGt/LYTE8sERhxefOy9jPzPdcyDtRyMRsGwmaz2KByvMH/KDG+O5+A1NFs08+d7FrjZ2fu1C
            eHK92rzaLrsjlq/nAhp4+Wod9PQnXD8qTSgtaZuWEEfrOQP5ikhc5a28B42LRDAZDgfpfQM3mq+VywtdR1tduk30zbtJnAi3Wx/dJCNF7EsMPrPdNOVKt2dTieVe1yvOFcn2W1
            uDqqL6bxA6bdUBLwFVxlCDE8+/jeVZe18oxiuRB7IrGCz5slbZsFdxLDX7W+RoCaGQKOaM4xHnUbsVfD1iw19GLVMn5UlzvHdDybN2suSk93nbw51MpMuk1zM9zod9kpglMk8G
            Z20ZrG6/R6PFPPDF97um2biGwVGlZ6OZQNzvUWQM5UwS6c9qtjZjkgS+YUhy0jK7tlf1IQPKF51YqkWcEKzMLiPwd3DQyQvIVxAAAAAElFTkSuQmCC"
                      className="w-6 h-6 mb-2"
                    />
                    {item.contact.phone}
                  </h2>
                  <h2 className="flex flex-col items-center gap-1 mt-3 text-[16px] xs:hidden md:block">
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACN0lEQVR4nO2X
            P4gTURDG36kgKJ6IyKEXk5m9FQULwUKwUFFsFCyvEwuVnVkl2GkZSzuxtBPLkMzsGQ2IiFgIFgcKgiAIggiCiHCCqCcaebrJrZzH5Y+5vMT3g1fssm/3+3bmzZtnjMfj8XgGASs2
            RmGYkTNihhT2RhyDRz8iDTNmXKexoHFJI6QwGyVw0DgKCe4jgUfLGmlek0ItruQD4wixTm0nwZss+ONvehdHRPAqK8yn9z+T4JViPRwflIFiPRy3GqyW35pg3mpc1oiFKsEOUiwv
            RAffk8CF6bJZvVIGSiWzihI4RYpvWzoE7p2bmdrdcdWKk8JhEnySMfScNDjebxNcDY6w4tPsd1kKx3oqv+3+mX8BdZEJHe8jJ+9OrCfFy3/kquL1Yjnc0quB+HZ+k10HJPAlffdX
            UrwWlYONfdsQz1bDXLZ6kOCHWOFSsR6u7dRA6cGhNbFCRArvuq2W3OvO3qznGQEvYsHpdufHCR4lhWeZ+bOseGAwLUrDjFnxLPgqI+g+VWHPUlOiSmEXC9xpteCCb2xU7Fo0g+61
            oltb19n0YoGP6SL9btPvzAxONJ85L9s227wngW+pgU92XZxOdm5wrmmkWm6SFG9YI2l1myOFi7+GwFzLpH2mlpt0vvuNqvm9LPhw0UlO8HEkhf1D18ZHiidY4SUJvLZ7UT86a16p
            84gty92U5nb5Dw5WQwZ7I47BPiKOwT4ijsE+Io7BPiKuR4SHfJiRMeLxeDweMwB+An37+6BMF2JeAAAAAElFTkSuQmCC"
                      className="w-6 h-6 mb-2"
                    />
                    {item.contact.email}
                  </h2>
                  <div className="lg:mt-5 md:mt-5 xs:mt-2">
                    {" "}
                    <Link to={`/locations/${item.branchId}`}>
                      <Button text={t("moredetails")} color="bbf-black"/>
                      {/* <button className="lg:mt-[40px] xs:mt-0 xs:h-[20px] xs:w-[90px] xs:text-[8px] md:text-[14px] lg:h-[43px] lg:w-[135px] text-bbf-white bg-bbf-black rounded-[5px]  hover:bg-opacity-90 text-[12px]">
                        More details
                      </button> */}
                    </Link>{" "}
                  </div>
                </div>
              </Popup>
            </Marker>):(<Marker
              key={index}
              icon={customIcon}
              position={[item.coordinates.lat, item.coordinates.lng]}
            >
              <div>{item.name==="Stammhaus Hoppegarten"}</div>
              <Popup>
                <div className="lg:p-4 md:p-3 xs:p-1 sm:p-0 ">
                  <h3 className="font-roboto font-bold xs:text-[15px] md:text-[18px] xl:text-[22px]">
                    {item.name.toUpperCase()}
                  </h3>
                  <h2 className="flex  items-start justify-start gap-3 mt-3 text-[16px] xs:hidden md:block">
                    {" "}
                    <div><img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAs
            TAAALEwEAmpwYAAAFR0lEQVR4nO1aaWgdVRS+KVHcal2r1ZA5ZxoTLSqu+EdIVcQFpShGSyuIMcy9LxKXH1K1yqtVfyjFfyp1Qf1RlGfn3Gmi4lKISMV
            StYoLRQWpiGBVrFat0TSNnHvvvCx2Zt7Lm8kifjAQ3jvv3HPm7OdGiP/xb/S91nak0v4KSfC01LBNavhBafibH/6bP1Man1Kht7w76pgvZhtUBB1Sw7N
            S416lcbSmh/APRfCM3OifMtPyi6B/0WGSYJ0kGHbC7TeWIFxdIuhkBW9844TD+eG/zWcE9ymC95nWKsXWwkdves47ZEaU4DcpNXzKwkiNI5JgQ0Bti2v
            9fe+mxW1S44uxQlLje0HYukhMJ1TknW39nwWAL4Kw9Zyp8goi/zyl4UtnnW9Lm/BMMY2WMEooDW+WXmk9ulGet1RajpEEb8XKFG4ZExPOnViJ8mBnc26
            81597kCLYHLtZoTHDgR27Ux6WSLDMVy6zPSAKS7EEwxzYjcREFkohnm8SAOHvvRXvxNwP4DphrEGwQRQMRfiSs/wT+VdsU8Bwfy0pVoX+QqnxQUn4Eb9
            ZfiThdkm4tq/SdnwtCUVVrbLkiNwU4bbDvaFtmUJEeJ0i2JNU0SXBrwH512by0fAB0wcabshNEe6drBC4OlsJV+AINVfyuLJL7S1VGqNqAY28a9J4KQ3
            3O9r1+Slim71RFizx4NBfWLUEwV1JdCUNq1z6/iXobz8ukS7yLnJtz9Y8dLBCEvzITHsI25NoOCZiS9TArz8rxfYQtjt33tWI7BMP1vAXM01rvZXGj7O
            sNvltcwJIoumOOua7mBpqRPbJQv7JTNOqrdTwG9PUkmViIdkV07oIZWNkr8gLiuB7K2RygYrjoxZF7ABmM1gSjRxoOdlZ7btGZJ8opMYd5g2G/umJB3P
            NMG/QW5rJL/QvdjHyYRJNr/bOcsp+1ojsE4XUMOCYXp9IQ7jWCRfVzE/jmkSaEFe6rEWNyD5JSHjYpcyHkmi4YrOrmIDXsCqZF97jXGb3rXTSsUl0ivC
            RLGXrBlvCBefmNDqu2FzsHG0/ZyeOGX7YncZZgifKZWm8lIYtzgtS6eqC65348CHOJmm0XLG52CW3KLg7S7jbtXeU6bQJhoOKvyA3RYyAhNvt2/SvzKL
            lis3FjoPZpGWCPdw7sZukuVOMEmGXc+UtIm9UK7fG50XBUBrCrFibMoKN3qnV7jXDvRo6p+IvMAXYrJY8KOQQdhWjTIgrC1NEQ5+z/NtFncHu1RsvB4r
            gXx7sbFaEX9v48FeIomDSqMtIPFvnzV+F3vK4LeGtSt78Jx5G8JirEy8X5bpK472iaPDyLA7GQPtn5MVXhnhpPHBxHRHTAUXweN5W4eBWRe6zDoSesK1
            lbKviX9goP9sNmCTyUxGLv/TDNa6Jp7xyWcybKp+uypKD4yW21Ngrpht3VloOlRp3mgwW+TdPlQ8vKly7/nmeu+Qp7bt4guSpr97fc+s/1mDCZWLGMCq
            aJMG7rnVZV+/PFeELblMyUIyAdV762AtP3BdEcEGtvwtC7/L4LrGem65CMW7M3VHLvYbZohB8Y/cAcJuYLejizEPwSdY4HENpfDKeNxrJeIXAbj3MDe1
            I2ibFuBS36ARDJfJOE7MRyk6FXA92HqjNsCMz7LLW8O4QsxXlwc5mSfBOdY0zKpqq35XFPEn4ukvXr47/blaix7Qvduk9/hoiHpW55hRypVYEAo1X21E
            VRxR5VyjCq9yaaF+g/UvEXIIc68V+5hWQtYZ/t5hzGBVNUmNl3D/RTIiZOYVuU/hwK8/4uV5qiv8w/gF0K4RGYBI0eQAAAABJRU5ErkJggg=="
                      className="w-6 h-6 mb-2"
                    /></div>
                    
                    <div>{item.address}</div>
                    
                  </h2>
                  <h2 className="flex flex-col items-center mt-3 text-[16px] xs:hidden md:block">
                    {" "}
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAE
            j0lEQVR4nO1aXWhcVRA+8R/FH/yraZO9MzehghRU+uBPEVHEF1GQEgVriU3TO2eTxj6KT3kofVKpKFUDiqBv696ZTSLRPkiLD/600FoQafWhKiqCNdqqGH8jc/eczdruTXdLs
            jmBfLBPO+funXNmvvlmzhqzghUsHWwFHrIMB4lhhhiOJBzdYZYT+ko3XWQZ9ljB2foPMf5Ek11rzHJAUoqvJIH33Mv/Tozbk4m11xLjeOaM4JQJHUOl6AYr+Inb/W+L0rO+/j
            sSPJE5yDhgQsXIVO8VxHjI7fpnQ2WITrehFDdVTwp+TibiggkNI1O9FxPD+9WTgE+HefU1ebbEKMGGGDHsqoYMfDWY9nY1EX4/Ovt+ExKIcVpfrFiJ7mnG3gpsdnk0naSFThM
            KiGFGX2yHRFc1vUbgbeeMmFBADF9koZLG65pdoyGoSa/rEoFHTQggn8ApbmptHWyrJj4cMyGAGLe7MHmjlXVaKF3h/NvMmo7Fe8MmkXBvj2X81zKcGqjceHmz60iiJxx7HTShg
            JwsSTiyzdj3vx5dQoJfBkfDxPCIj/fRfXdfcHZ7fNqF4+HRUXOeCQV9JXO+ShPnDM1na9P4emI4Wa09eJ8JDVZwo0ve75KJzkvz7EhwzJ3GuAkSs6bDCnzsFO4z+bkBvziHB02
            osJXoVivwJwn+YwXvamiTwpO+0RocL6AJFSS40yd+wxCbNR0kMOlsDswXhkve6hLDEUetexrZqC7z5GAFK0oWJkQMSXSLtrpVZoq35BdS+MH1J2NBVPdGUAd8755U4LZGNjpZ
            8clPjLtNqLCML3hKzpug2DS+15+eFXjOhIhkbP2FlnGfc+aDvMROBB9UtnPt8i4TIkZKvddZxuPOmXeUDBrZFRn75pzB3UHmzCDjWsvwvUvsUh5LVU/GhRnjK0GyGaVwsxZBR8
            uv5u245kxd9a80Ckd1UMlERSdlxRc+V+Fq2oWi9NxpGX89W2LbNLrdU7P2K/VzMhWatTp1xlgWylvHcVV7nGG43wr8UXMm52SK5UJcU9SM09oae0XgQu+49jJJNpKFbV5RVyea
            8WNtcYYq0cPeGRJ4LS8XVAFYhonTdv1kUeApFaD/c1x6upVM6obmonO09pyMDzOGt/LYTE8sERhxefOy9jPzPdcyDtRyMRsGwmaz2KByvMH/KDG+O5+A1NFs08+d7FrjZ2fu1C
            eHK92rzaLrsjlq/nAhp4+Wod9PQnXD8qTSgtaZuWEEfrOQP5ikhc5a28B42LRDAZDgfpfQM3mq+VywtdR1tduk30zbtJnAi3Wx/dJCNF7EsMPrPdNOVKt2dTieVe1yvOFcn2W1
            uDqqL6bxA6bdUBLwFVxlCDE8+/jeVZe18oxiuRB7IrGCz5slbZsFdxLDX7W+RoCaGQKOaM4xHnUbsVfD1iw19GLVMn5UlzvHdDybN2suSk93nbw51MpMuk1zM9zod9kpglMk8G
            Z20ZrG6/R6PFPPDF97um2biGwVGlZ6OZQNzvUWQM5UwS6c9qtjZjkgS+YUhy0jK7tlf1IQPKF51YqkWcEKzMLiPwd3DQyQvIVxAAAAAElFTkSuQmCC"
                      className="w-6 h-6 mb-2"
                    />
                    {item.contact.phone}
                  </h2>
                  <h2 className="flex flex-col items-center gap-1 mt-3 text-[16px] xs:hidden md:block">
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACN0lEQVR4nO2X
            P4gTURDG36kgKJ6IyKEXk5m9FQULwUKwUFFsFCyvEwuVnVkl2GkZSzuxtBPLkMzsGQ2IiFgIFgcKgiAIggiCiHCCqCcaebrJrZzH5Y+5vMT3g1fssm/3+3bmzZtnjMfj8XgGASs2
            RmGYkTNihhT2RhyDRz8iDTNmXKexoHFJI6QwGyVw0DgKCe4jgUfLGmlek0ItruQD4wixTm0nwZss+ONvehdHRPAqK8yn9z+T4JViPRwflIFiPRy3GqyW35pg3mpc1oiFKsEOUiwv
            RAffk8CF6bJZvVIGSiWzihI4RYpvWzoE7p2bmdrdcdWKk8JhEnySMfScNDjebxNcDY6w4tPsd1kKx3oqv+3+mX8BdZEJHe8jJ+9OrCfFy3/kquL1Yjnc0quB+HZ+k10HJPAlffdX
            UrwWlYONfdsQz1bDXLZ6kOCHWOFSsR6u7dRA6cGhNbFCRArvuq2W3OvO3qznGQEvYsHpdufHCR4lhWeZ+bOseGAwLUrDjFnxLPgqI+g+VWHPUlOiSmEXC9xpteCCb2xU7Fo0g+61
            oltb19n0YoGP6SL9btPvzAxONJ85L9s227wngW+pgU92XZxOdm5wrmmkWm6SFG9YI2l1myOFi7+GwFzLpH2mlpt0vvuNqvm9LPhw0UlO8HEkhf1D18ZHiidY4SUJvLZ7UT86a16p
            84gty92U5nb5Dw5WQwZ7I47BPiKOwT4ijsE+Io7BPiKuR4SHfJiRMeLxeDweMwB+An37+6BMF2JeAAAAAElFTkSuQmCC"
                      className="w-6 h-6 mb-2"
                    />
                    {item.contact.email}
                  </h2>
                  <div className="lg:mt-5 md:mt-5 xs:mt-2">
                    {" "}
                    <Link to={`/locations/${item.branchId}`}>
                      <Button text={t("moredetails")} color="bbf-black"/>
                      {/* <button className="lg:mt-[40px] xs:mt-0 xs:h-[20px] xs:w-[90px] xs:text-[8px] md:text-[14px] lg:h-[43px] lg:w-[135px] text-bbf-white bg-bbf-black rounded-[5px]  hover:bg-opacity-90 text-[12px]">
                        More details
                      </button> */}
                    </Link>{" "}
                  </div>
                </div>
              </Popup>
            </Marker>)}
          
            </div>
          ))}
        </MapContainer>





       
      </div>
      <Link to="/locations">
        <div className="flex justify-center flex-col items-center font-roboto xs:mt-8 md:mt-12 xl:mt-20 ">
          <Button text={t("alllocations")}   color="bbf-black"/>
          {/* <button className={`text-bbf-black bg-bbf-green xl:w-[170px] xl:h-[50px] lg:w-[120px] xs:h-[35px]  ${currentLanguage==="pl"?"xs:w-[115px]":""} xs:text-[12px]  lg:h-[35px] p-1 lg:text-[15px] rounded-md hover:bg-opacity-90`}>{t("alllocations")}</button> */}
        </div>
      </Link>
      </div>
    </div>
  );
};

export default Places;
