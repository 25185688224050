import React from 'react'
import BrandsPage from './Pages/BrandsPage'
import { Navigate, Route,Routes } from 'react-router-dom'
import Header from './Components/Header'
import Footer from './Components/Footer'
import Home from './Pages/Home'
import HistoryPage from './Pages/HistoryPage'
import LocationPage from './Pages/LocationPage'
import SingleLocation from './Components/SingleLocation'
import ServicesPage from './Pages/ServicesPage'
import ContactPage from './Pages/ContactPage'
import PhilosophiePage from './Pages/PhilosophiePage'
import Impressum from './Pages/Impressum'
import Dataprotection from './Pages/Dataprotection'
import { I18nextProvider } from 'react-i18next'
import i18n from './index'
import DealerSearch from './Pages/DealerSearch'
import Downloads from './Pages/Downloads'
import ExibitionPage from './Pages/ExibitionPage'
import Catalog from './Components/Catalog'
import CeConfirmations from './Components/CeConfirmations'
import Fahrraeder from './Components/Fahrraeder'
import Support from './Components/Support'
import Help from './Components/Help'
import EbikeAntriebe from './Components/EbikeAntriebe'
import Komponenten from './Components/Komponenten'
import RentalBike from './Components/RentalBike'
import Fahrradteile from './Components/Fahrradteile'
import WheelRim from './Components/WheelRim'
import CpcycleParts from './Components/CpcycleParts'
const App = () => {
  return (
    <I18nextProvider i18n={i18n}>
    <div>
     <Header/>
     <Routes>
      <Route path='/brands' element={<BrandsPage/>}/>
      <Route path='/' element={<Home/>}/>
      <Route path='/history' element={<HistoryPage/>}/>
      <Route path='/locations' element={<LocationPage/>}/>
      <Route path='/locations/:location' element={<SingleLocation/>}/>
      <Route path='/services' element={<ServicesPage/>}/>
      <Route path='/contact' element={<ContactPage/>}/>
      <Route path='/philosophie' element={<PhilosophiePage/>}/>
      <Route path='/impressum' element={<Impressum/>}/>
      <Route path='/Datenschutz' element={<Dataprotection/>}/>
      <Route path='/haendlersuche' element={<DealerSearch/>}/>
      <Route path='/downloads' element={<Downloads/>}/>
      <Route path='/messe' element={<ExibitionPage/>}/>
      <Route path='/catalog' element={<Catalog/>}/>
      <Route path='/ceconfirmations' element={<CeConfirmations/>}/>
      <Route path='/anleitungen-fahrraeder' element={<Fahrraeder/>}/>
      <Route path='/support' element={<Support/>}/>
      <Route path='/help' element={<Help/>}/>
      <Route path='/ebikeantriebe' element={<EbikeAntriebe/>}/>
      <Route path='/components' element={<Komponenten/>}/>
      <Route path='/rental' element={<RentalBike/>}/>
      <Route path='/fahrradteile' element={<Fahrradteile/>}/>
      <Route path='/laufrad' element={<WheelRim/>}/>
      <Route path='/cycleparts' element={<CpcycleParts/>}/>
      <Route path='*' element={<Navigate to='/' />}/>
     </Routes>
     <Footer/>
    </div>
    </I18nextProvider>
  )
}

export default App
