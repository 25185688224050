import React from 'react'

const Downloads = () => {
  return (
    <div className='min-h-[calc(100vh-110px)] h-auto'>
    
    </div>
  )
}

export default Downloads