import React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import brands from "../data/brands.json";
const DealerSearch = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="min-h-[calc(100vh-100px)] h-auto">
      <div className="xl:pt-[150px] 2xl:pt-44 xs:pt-9 md:pt-16 flex justify-center items-center flex-col text-justify ">
        <div className="xl:w-[1100px] xs:w-[350px] md:w-[620px] md:mb-10 xs:mb-5 xl:mb-0">
          <h2 className="xs:text-[15px] md:text-[20px] xl:text-[25px] font-roboto font-bold  md:my-5 xs:my-4 underline underline-offset-[12px] decoration-bbf-green">
            {t("dealersearch").toUpperCase()}
          </h2>
          <p className=" md:text-[17px] xs:text-[13px] font-roboto">
            {t("dealerdescription")}
          </p>
        </div>
        <div className="flex justify-center items-center xl:w-[1100px] xs:w-[350px] md:w-[620px]  xl:my-20">
          {brands.brands.map((item, index) => (
            <div
              key={index}
              className="grid lg:grid-cols-4 md:grid-cols-4 md:gap-7 sm:grid-cols-3 lg:gap-24 xs:gap-2 "
            >
              {item.homebrands.brands.map((item, index) => (
                <div key={index}>
                  <a href={item.handlersuche} target="_blank">
                    <img
                      src={item.logo}
                      className="xl:h-[230px] lg:h-[120px]  xl:w-[230px] 
                  lg:w-[120px] 
                  xs:w-[110px] xs:h-[110px]  md:w-[150px] md:h-[150px] object-contain  p-7 bg-bbf-brand md:transition md:ease-in-out md:delay-150 md:hover:-translate-y-1 md:hover:scale-110  md:duration-500 "
                    />
                  </a>
                </div>
              ))}
               {item.brandsindistribution.brands.filter((item)=>item.name==="Dahon").map((item, index) => (
                <div key={index}>
                  <a href={item.handlersuche} target="_blank">
                    <img
                      src={item.logo}
                      className="xl:h-[230px] lg:h-[120px]  xl:w-[230px] 
                  lg:w-[120px] 
                  xs:w-[110px] xs:h-[110px]  md:w-[150px] md:h-[150px] object-contain  p-7 bg-bbf-brand md:transition md:ease-in-out md:delay-150 md:hover:-translate-y-1 md:hover:scale-110  md:duration-500 "
                    />
                  </a>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DealerSearch;
