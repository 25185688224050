import React from "react";
import Card from "./Card";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import services from "../data/services.json";
import { useTranslation } from "react-i18next";
import { Button } from "./Button";
const ServicesCards = () => {
  // const[services,setServices]=useState([])
  // const marken = async()=>{
  //     try{
  //       const response= await axios.get("https://api.bbf-bike.de/api/web/data/bbf-bike/services")
  //       setServices(response.data.slice(0,3))
  //     }
  //   catch(err){
  //     console.log("error generated", err)
  //   }
  //   }

  //   useEffect(()=>{
  //   marken()
  //   },[])
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { t } = useTranslation();

  return (
    <div className="flex justify-center items-center flex-col">
      <h1 className="xl:text-[45px] md:text-[35px] sm:text-[20px] xs:p-2 md:p-3 xl:p-4 font-roboto font-bold xl:my-4 md:my-3 xs:my-2 underline underline-offset-[12px] decoration-bbf-green">
        {t("ourservices")}
      </h1>
      <div className="bg-bbf-green w-full">
        <div className="  justify-center items-center  flex xs:mt-8 md:mt-12 xl:mt-20">
          <div className="grid md:grid-cols-2 2xl:grid-cols-4 xl:grid-cols-2 xs:grid-cols-1 2xl:gap-10 md:gap-8 xs:gap-3 auto-rows-max  ">
            {services.slice(0, 4).map((item, index) => (
              <Card
                image={item["image small"]}
                name={
                  currentLanguage === "en"
                    ? item.translations.en.name
                    : currentLanguage === "pl"
                    ? item.translations.pl.name
                    : item.name
                }
                description={
                  currentLanguage === "en"
                    ? item.translations.en.description
                    : currentLanguage === "pl"
                    ? item.translations.pl.description
                    : item.description
                }
              />
            ))}
          </div>
        </div>
        <Link to="/services">
          <div className="flex justify-center items-center xs:my-8 md:my-12 xl:my-20 ">
            <Button text={t("allservices")}  color="bbf-black"/>
            {/* <button
              className="text-bbf-black bg-bbf-white  xs:h-[35px] xs:w-[100px]
          xl:w-[170px] xl:h-[50px] lg:w-[120px]  lg:h-[35px] p-1 lg:text-[15px] 
         xs:text-[12px] rounded-md hover:bg-opacity-90"
            >
              {t("allservices")}
            </button> */}
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ServicesCards;
