import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import brands from "../data/brands.json"
import { useTranslation } from "react-i18next";
import { Button } from "./Button";
const Brands = () => {
  // const[brands,setBrands]=useState({})
  // const marken = async()=>{
  //     try{
  //       const response= await axios.get("https://media.bbf-bike.de/shop/documents/websites/bbf-bike/data/brands.json")
  //       setBrands(response.data)
  //       console.log(response.data)
  //     }
  //   catch(err){
  //     console.log("error generated", err)
  //   }
  //   }

  //   useEffect(()=>{
  //   marken()
  //   },[])
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { t } = useTranslation();
  return (
    <div className="min-h-[calc(100vh-100px)] h-auto">
      <div className="lg:pt-[110px] sm:pt-0 relative">
        <img
          src="https://media.bbf-bike.de/shop/documents/websites/bbf-bike/images/brands/BrandsBannerImage.jpg"
          className="h-full xs:object-contain md:object-cover
          w-full"
        />

        <h1 className="absolute inset-0 text-bbf-white  flex justify-start md:pt-8 2xl:pt-[110px] lg:pt-56 xl:pt-[40px] md:mx-16 xs:mx-5 xs:items-center md:items-start xl:items-center xs:text-[18px] md:text-[35px] xl:text-[70px] font-roboto-regular">
          <div className="underline xs:underline-offset-[6px] md:underline-offset-[12px] decoration-bbf-green font-roboto">
            {t("thebrands").toUpperCase()}
          </div>
        </h1>
        <h3 className="absolute inset-0 font-roboto md:mx-16 xs:mx-5 xs:text-bbf-black md:text-bbf-white xs:top-[80px] lg:top-[280px] 2xl:top-[410px] pt-[20px] xl:text-[30px] md:text-[18px] xs:text-[13px] xs:my-4 md:my-0 ">
          {currentLanguage === "en"
            ? brands?.translations.en
            : currentLanguage === "pl"
            ? brands?.translations.pl
            : brands?.description}
        </h3>
      </div>

      <div className="md:mt-[35px] xl:mt-[45px]  xs:mt-[105px]  ">
        {brands.brands.map((item, index) => (
          <div>
            <div key={index}>
              {item.homebrands.brands.map((item, index) => (
                <div
                  key={item}
                  className={`flex flex-wrap xs:flex-col-reverse ${
                    index % 2 === 0 ? "lg:flex-row " : "lg:flex-row-reverse  "
                  }`}
                >
                  <div className="flex-1 items-center flex  sm:my-8 xs:my-5 lg:my-0 ">
                    <div className="flex flex-col justify-center xl:mx-10  ">
                      <div className="flex justify-center items-center">
                        <img
                          src={item.logo}
                          className="2xl:w-[300px] xl:w-[200px] lg:w-[100px] 2xl:h-[243px] xl:h-[200px] lg:h-[100px] md:h-[120px] md:w-[120px] sm: h-[80px] w-[80px] object-contain"
                        />
                      </div>
                      <div className=" xl:mt-[25px]  mr-[62px] xs:text-[13px] md:text-[17px] font-roboto text-justify sm:mt-5 xs:mt-5 xl:mx-0 xs:mx-5">
                        {currentLanguage === "en"
                          ? item.translations.en.description
                          : currentLanguage === "pl"
                          ? item.translations.pl.description
                          : item.description}
                      </div>
                      <div className="xl:mt-[30px] xs:mb-[10px] xl:mb-[20px] md:mt-[20px] xl:mx-0 xs:mx-5 xl:ml-0 sm:mt-[35px] xs:mt-[20px]">
                        <a href={item.weblink} target="_blank">
                          <Button text={t("moredetails")}  color="bbf-black"/>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="flex-1 xl:mx-0 xs:mx-5">
                    <div className="relative">
                      <img
                        src={item.image}
                        className="h-full xs:object-contain md:object-cover
                  w-full"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div key={index}>
              {item.brandsindistribution.brands.map((item, index) => (
                <div
                  key={item}
                  className={`flex flex-wrap xs:flex-col ${
                    index % 2 === 0 ? "lg:flex-row " : "lg:flex-row-reverse"
                  }`}
                >
                  <div className="flex-1 xl:mx-0 xs:mx-5">
                    <div className="relative">
                      <img
                        src={item.image}
                        className="h-full xs:object-contain md:object-cover
                  w-full"
                      />
                    </div>
                  </div>
                  <div className="flex-1 items-center flex  sm:my-8 xs:my-5 lg:my-0 ">
                    <div className="flex flex-col justify-center  i xl:mx-10 ">
                      <div className="flex justify-center items-center">
                        <img
                          src={item.logo}
                          className="2xl:w-[300px] xl:w-[200px] lg:w-[100px] 2xl:h-[243px] xl:h-[200px] lg:h-[100px] md:h-[120px] md:w-[120px] xs:h-[80px] xs:w-[80px] object-contain"
                        />
                      </div>
                      <div className="xl:mt-[45px]  xs:text-[13px] md:text-[17px] font-roboto text-justify sm:mt-5 xs:mt-5 xl:mx-0 xs:mx-5">
                        {currentLanguage === "en"
                          ? item.translations.en.description
                          : currentLanguage === "pl"
                          ? item.translations.pl.description
                          : item.description}
                      </div>
                      <div className="xl:mt-[30px] xs:mb-[10px] xl:mb-[20px] md:mt-[20px] xl:mx-0 xs:mx-5 xl:ml-0 sm:mt-[35px] xs:mt-[20px]">
                        <a href={item.weblink} target="_blank">
                          <Button text={t("moredetails")}   color="bbf-black"/>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
        {/* {brands.brands?.map((item, index) => (
          <div
            key={item}
            className={`flex flex-wrap ${
              index % 2 === 0
                ? "lg:flex-row sm:flex-col xs:flex-col"
                : "lg:flex-row-reverse sm:flex-col xs:flex-col"
            }`}
          >
            <div className="flex-1">
              <div className="relative">
                <img
                  src={item.image}
                  className="2xl:w-[960px] xl:w-[800px] lg:w-[700px]  2xl:h-[601px] xl:h-[500px] lg:h-[400px] object-cover"
                />
                <div className="w-full  2xl:h-[601px] xl:h-[500px] bg-bbf-black opacity-[40%] absolute inset-0">
                  {" "}
                </div>
              </div>
            </div>
            <div className="flex-1 items-center flex  sm:my-8 xs:my-5 lg:my-0">
              <div className="flex flex-col justify-center items-center lg:ml-[62px] sm:ml-0 ">
                <img
                  src={item.logo}
                  className="2xl:w-[300px] xl:w-[200px] lg:w-[100px] 2xl:h-[243px] xl:h-[200px] lg:h-[100px] md:h-[120px] md:w-[120px] sm: h-[80px] w-[80px] object-contain"
                />
                <div className="2xl:mt-[50px] xl:mt-[15px] lg:mt-[10px] mr-[62px] xs:text-[16px] xl:text-[18px] font-roboto text-justify sm:mt-5 xs:mt-5 sm:ml-12 xs:ml-10 lg:ml-0">
                  {currentLanguage === "en"
                    ? item.translations.en.description
                    : currentLanguage === "pl"
                    ? item.translations.pl.description
                    : item.description}
                </div>
                <div className="xl:mt-[40px] lg:mt-[20px]  sm:mt-[35px] xs:mt-[30px]">
                  <a href={item.weblink} target="_blank">
                    <Button text={t("moredetails")} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))} */}
      </div>
    </div>
  );
};

export default Brands;
