import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import i18n from "i18next";
import entranslation from "../src/en.json";
import detranslation from "../src/de.json";
import pltranslation from "../src/pl.json";
import { initReactI18next } from "react-i18next";
const selectedLanguage = localStorage.getItem("language");

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: entranslation },
    de: { translation: detranslation },
    pl: { translation: pltranslation },
  },
  lng: selectedLanguage || "de",
  fallbackLng: ["en", "pl"],
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
