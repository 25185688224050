import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import servicesData from "../data/services.json";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "./Button";

const Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const sectionRef = useRef();
  const scrollToSection = () => {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { t } = useTranslation();
  return (
    <div className="min-h-[calc(100vh-100px)] h-auto md:mb-[35px] xl:mb-[45px]  xs:mb-[25px] overflow-x-hidden">
      <div className="lg:pt-[110px] sm:pt-0 relative">
        <img
          src="https://media.bbf-bike.de/shop/documents/websites/bbf-bike/images/services/ServiceBannerImage.jpg"
          className="h-full xs:object-contain md:object-cover
          w-full"
        />
        <h1 className="absolute inset-0 text-bbf-white  flex justify-start md:pt-10 lg:pt-[110px] md:mx-16 xs:mx-5 xs:items-center md:items-start xl:items-center xs:text-[18px] md:text-[35px] xl:text-[70px] font-roboto-regular">
          <div className="underline xs:underline-offset-[6px] md:underline-offset-[12px] decoration-bbf-green font-roboto">
            {t("ourservices").toUpperCase()}
          </div>
        </h1>
        <h3 className="absolute inset-0 font-roboto md:mx-16 xs:mx-5 xs:text-bbf-black md:text-bbf-white xs:top-[100px] md:top-[110px] xl:top-[350px] 2xl:top-[430px] xl:text-[30px] md:text-[18px] xs:text-[13px] xs:my-4 md:my-0 ">
          {t("servicedes")}
        </h3>
      </div>
      <div className="md:mt-[35px] xl:mt-[45px]  xs:mt-[90px] lg:mx-[20px] sm:mx-0">
        {servicesData?.map((item, index) => (
          <div
            className="flex justify-center items-center xs:mb-[15px] md:mb-[30px] xl:mb-[50px] xs:flex-col lg:flex-row"
            key={index}
          >
            <img
              src={item["image big"]}
              className="xl:w-[400px] 2xl:w-[500px] md:h-[350px] xl:px-0 xs:px-5  object-cover h-full xs:object-contain md:object-cover
              w-full "
            />
            <div className="lg:bg-bbf-brand sm:bg-bbf-white lg:w-[700px] sm:w-full xl:h-[350px] md:h-[190px] flex xl:px-8 xs:px-5 xs:justify-start xl:justify-center flex-col">
              <h2 className="xs:text-[15px] md:text-[20px] xl:text-[25px] font-roboto mt-2 underline-offset-[12px] decoration-bbf-green underline xs:mb-3 md:mb-5 xl:mb-8">
                {currentLanguage === "en"
                  ? item.translations.en.name
                  : currentLanguage === "pl"
                  ? item.translations.pl.name
                  : item.name}
              </h2>

              <div className="text-justify xs:text-[13px] md:text-[17px] font-roboto">
                {currentLanguage === "en"
                  ? item.translations.en.description
                  : currentLanguage === "pl"
                  ? item.translations.pl.description
                  : item.description}
              </div>

              <div className="mt-8 flex justify-start items-center">
              <Link to={item.link}>
                    <Button text={item.button.de}  color="bbf-black"/>
                  </Link>

                {/* {item.name === "Fahrräder & E-Bikes" &&
                item.translations.en.name === "Cycles" &&
                item.translations.pl.name === "Rowery" ? (
              
                ) : (
                  ""
                )} */}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
