import React, { useEffect } from 'react'
import antriebe from "../data/antriebe.json"

const EbikeAntriebe = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='min-h-[calc(100vh-100px)] h-auto md:mb-[10px] xl:mb-[15px]  xs:mb-[5px]'>
    <div className='lg:pt-[150px]'>
    <div className="flex justify-center items-center xs:my-10 md:my-16 xl:my-20">
          <div className="grid lg:grid-cols-4 md:grid-cols-3 md:gap-12  sm:grid-cols-3 xl:gap-24 xs:gap-3 ">
            {antriebe.antriebe.map((item, index) => (
              <div key={index}>
                <a href={item.weblink} target="_blank">
                  <img
                    src={item.logo}
                    className="xl:h-[230px] lg:h-[120px]  xl:w-[230px] 
                  lg:w-[120px] 
                  xs:w-[110px] xs:h-[110px] p-5 md:w-[150px] md:h-[150px] object-contain  bg-bbf-brand transition md:ease-in-out md:delay-150  md:hover:-translate-y-1 md:hover:scale-110  md:duration-500 "
                  />
                </a>
                <div
                  className="font-roboto-light flex justify-start items-start  font-semibold text-[14px] xl:w-[230px] 
                  lg:w-[120px] 
                  xs:w-[110px] md:w-[150px]  bg-opacity-60 auto-rows-max xs:h-[90px] md:h-[50px] leading-6 mt-4"
                >
                  {item.name}
                
                </div>
              </div>
            ))}
          </div>
        </div>
    </div>

</div>
  )
}

export default EbikeAntriebe