import React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ce from "../data/Ceconfirmation.json";
const CeConfirmations = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="min-h-[calc(100vh-100px)] h-auto md:mb-[10px] xl:mb-[15px]  xs:mb-[5px]">
      <div className="xl:pt-[110px] ">
        <div className="flex justify-center items-center flex-col xs:gap-4 md:gap-7 xl:gap-10 xs:my-10 md:my-16 xl:my-20">
          <h2 className="xs:text-[15px] md:text-[19px] xl:text-[25px] font-roboto font-bold  md:my-5 xs:my-4 underline underline-offset-[12px] decoration-bbf-green ">
            {t("germanversion").toUpperCase()}
          </h2>
          <div className="grid lg:grid-cols-4 md:grid-cols-4 md:gap-5 sm:grid-cols-3  xl:gap-24 xs:gap-3 ">
            {ce.map((item, index) => (
              <div key={index}>
                {item.fileDe ? (
                  <div>
                    <a href={item.fileDe} target="_blank">
                      <img
                        src={item.logo}
                        className="xl:h-[230px] lg:h-[120px]  xl:w-[230px] 
                  lg:w-[120px] 
                  xs:w-[110px] xs:h-[110px]  md:w-[150px] md:h-[150px] object-contain  p-7 bg-bbf-brand md:transition md:ease-in-out md:delay-150  md:hover:-translate-y-1 md:hover:scale-110  md:duration-500 "
                      />
                    </a>
                    <div
                      className="font-roboto-light flex justify-start items-center  font-semibold text-[14px] xl:w-[230px] 
                  lg:w-[120px] 
                  xs:w-[110px] md:w-[150px]  bg-opacity-60 pt-3"
                    >
                      {item.name}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="flex justify-center items-center flex-col xs:gap-4 md:gap-7 xl:gap-10 xs:my-10 md:my-16 xl:my-20">
          <h2 className="xs:text-[15px] md:text-[19px] xl:text-[25px] font-roboto font-bold  md:my-5 xs:my-4 underline underline-offset-[12px] decoration-bbf-green ">
            {t("englishversion").toUpperCase()}
          </h2>
          <div className="grid lg:grid-cols-4 md:grid-cols-4 md:gap-5 sm:grid-cols-3 xl:gap-24 xs:gap-3 ">
            {ce.map((item, index) => (
              <div key={index}>
                <a href={item.fileEn} target="_blank">
                  <img
                    src={item.logo}
                    className="xl:h-[230px] lg:h-[120px]  xl:w-[230px] 
                  lg:w-[120px] 
                  xs:w-[110px] xs:h-[110px]  md:w-[150px] md:h-[150px] object-contain  p-7 bg-bbf-brand transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110  duration-500 "
                  />
                </a>
                <div
                  className="font-roboto-light flex justify-start items-center  font-semibold text-[14px] xl:w-[230px] 
                  lg:w-[120px] 
                  xs:w-[110px] md:w-[150px] bg-opacity-60 pt-3 leading-6"
                >
                  {item.name}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CeConfirmations;
