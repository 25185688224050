import React from "react";

export const Button = ({ text,color }) => {
  return (
    <div>
      <button className={`bg-${color} p-3 rounded-md font-roboto xs:text-[13px] lg:text-[15px] text-bbf-white hover:bg-opacity-75`}>
        {text}
      </button>
    </div>
  );
};
