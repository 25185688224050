import React from 'react'
import History from '../Components/History'
import { useEffect } from 'react'
const HistoryPage = () => {
 
    useEffect(() => {
      window.scrollTo(0, 0)
    }, []);
  return (
    <div>
        <History/>
    </div>
  )
}

export default HistoryPage