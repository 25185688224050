import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "./Button";
import brands from "../data/brands.json"
const BrandsSection = () => {
  // const [brands, setBrands] = useState({});
  // const marken = async () => {
  //   try {
  //     const response = await axios.get(
  //       "https://api.bbf-bike.de/api/web/data/bbf-bike/brands"
  //     );
  //     setBrands(response.data);
  //   } catch (err) {
  //     console.log("error generated", err);
  //   }
  // };

  // useEffect(() => {
  //   marken();
  // }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  return (
    <div className="flex justify-center items-center flex-col">
      <h2 className="xl:text-[45px] md:text-[35px] sm:text-[20px] xs:p-2 md:p-3 xl:p-4 font-roboto font-bold xl:my-4 md:my-3 xs:my-2 underline underline-offset-[12px] decoration-bbf-green">
        {t("ourbrands")}

      </h2>
      <div className="bg-bbf-brand w-full">
 
          {brands.brands.map((brand,index)=>
          (<div key={index} className="flex justify-center items-center flex-col">
            <div className="xs:my-8 md:my-12 xl:my-20 text-roboto xl:text-[37px] lg:text-[30px] sm:text-[18px]  font-bold underline underline-offset-[12px] decoration-bbf-green">{currentLanguage==="en"? brand.homebrands.name.en: currentLanguage==="pl"? brand.homebrands.name.pl:brand.homebrands.name.de}</div>

            <div className="grid lg:grid-cols-4 md:grid-cols-4 md:gap-7 sm:grid-cols-3 lg:gap-24 xs:gap-2 ">{brand.homebrands.brands.map((item,index)=>(
              <div key={index}>
                <a href={item.weblink} target="_blank">
                  <img src={item.logo} className="xl:h-[230px] lg:h-[120px]  xl:w-[230px] 
            lg:w-[120px] 
            xs:w-[110px] xs:h-[110px]  md:w-[150px] md:h-[150px] object-contain hover:bg-bbf-green hover:bg-opacity-50 p-7 bg-bbf-white transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110  duration-500 " />
                </a>
              </div>
            ))}</div>
            <div className="xs:my-8 md:my-12 xl:my-20 text-roboto xl:text-[37px] lg:text-[30px] sm:text-[18px] font-bold underline underline-offset-[12px] decoration-bbf-green">{currentLanguage==="en"? brand.brandsindistribution.name.en: currentLanguage==="pl"? brand.brandsindistribution.name.pl:brand.brandsindistribution.name.de}</div>

            <div className="grid lg:grid-cols-4 md:grid-cols-4 md:gap-7 sm:grid-cols-3 lg:gap-24 xs:gap-2 ">{brand.brandsindistribution.brands.map((item,index)=>(
              <div key={index}>
                <a href={item.weblink} target="_blank">
                  <img src={item.logo} className="xl:h-[230px] lg:h-[120px]  xl:w-[230px] 
            lg:w-[120px] 
            xs:w-[110px] xs:h-[110px]     md:w-[150px] md:h-[150px]  object-contain hover:bg-bbf-green hover:bg-opacity-50 p-7 bg-bbf-white transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110  duration-500 " />
                </a>
              </div>
            ))}</div>
          </div>)
          )}
          
        <Link to="/brands">
          <div className="flex justify-center flex-col items-center xs:my-8 md:my-12 xl:my-20">
            <Button text={t("showallbrands")}  color="bbf-black"/>
            {/* <button
              className={`text-bbf-black bg-bbf-green xl:w-[170px] xl:h-[50px] lg:w-[120px]
        lg:h-[35px] p-1 xs:text-[12px] xs:p-1 sm:p-2 lg:p-0 lg:text-[15px] xs:h-[35px]  rounded-md hover:bg-opacity-90`}
            >
              {t("showallbrands")}
            </button> */}
          </div>
        </Link>
      </div>
    </div>
  );
};

export default BrandsSection;
