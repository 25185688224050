import React from "react";
import list from "../data/anleitungen.json";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
const Fahrraeder = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="min-h-[calc(100vh-100px)] h-auto md:mb-[10px] xl:mb-[15px]  xs:mb-[5px]">
      <div className="xl:pt-[160px]">
        <div className="md:mx-20 xs:mx-5"></div>
        <div className="flex justify-center items-center xs:my-10 md:my-16 xl:my-20">
          <div className="grid lg:grid-cols-4 md:grid-cols-4 md:gap-3 xs:grid-cols-2 lg:gap-24 xs:gap-10 ">
            {list["anleitungen-fahrraeder"].map((item, index) => (
              <div key={index} className="xs:mb-8 xl:mb-0">
                <a href={item.fileDe} target="_blank">
                  <img
                    src={item.logo}
                    className="xl:h-[230px] lg:h-[120px]  xl:w-[230px] 
                  lg:w-[120px] 
                  xs:w-[150px] xs:h-[150px]  md:w-[150px] md:h-[150px] object-contain  p-7 bg-bbf-brand md:transition md:ease-in-out md:delay-150  md:hover:-translate-y-1 md:hover:scale-110  md:duration-500 "
                  />
                </a>
                <div
                  className="font-roboto-light flex justify-start items-start flex-col font-semibold text-[14px] xl:w-[230px] 
                  lg:w-[120px] 
                  xs:w-[110px] md:w-[150px]  bg-opacity-60 auto-rows-max  leading-6 mt-4"
                >
                  <div className="xl:h-14 md:h-20 xs:h-24 flex justify-start items-start">{item.name}</div>
              <div className=" flex justify-start items-start">{item.fileSize}</div>
                  
                </div>
              </div>
            ))}
          </div>{" "}
        </div>
      </div>
    </div>
  );
};

export default Fahrraeder;
