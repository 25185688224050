import React from 'react'
import Locations from '../Components/Locations'

const LocationPage = () => {
  return (
    <div>
     <Locations/>
        </div>
  )
}

export default LocationPage