import React from 'react'
import { useEffect } from 'react';
import teile from "../data/fahrradteile.json"
import { useTranslation } from 'react-i18next';
const Fahrradteile = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      const {i18n}=useTranslation()
      const currentLanguage= i18n.language
  return (
    <div className='min-h-[calc(100vh-100px)] h-auto md:mb-[30px] xl:mb-[45px]  xs:mb-[10px]'>
        <div className='xl:pt-[160px] xs:pt-12 md:mx-[70px] xs:mx-5 xs:text-[13px] md:text-[15px] xl:text-[17px] font-roboto'>
<div className='flex flex-col xl:pt-10'>
   <h3 className='xs:text-[15px] md:text-[20px] xl:text-[25px] font-bold font-roboto underline underline-offset-[12px] decoration-bbf-green'>{currentLanguage==="de"?teile['unsere-teile-eigenmarken']?.name.de:currentLanguage==="en"?teile['unsere-teile-eigenmarken']?.name.en:teile['unsere-teile-eigenmarken']?.name.pl}</h3> 
   <div className='flex justify-start items-start'>
   <div className='grid xs:grid-cols-1 xl:grid-cols-3 xs:gap-4 md:gap-7 xl:gap-20'>{teile['unsere-teile-eigenmarken'].brands.map((item,index)=>(
    <div key={index} className='flex justify-start items-start flex-col'>
        <img src={item.logo} className='xl:w-[250px] xs:w-[100px] xs:h-[100px] md:w-[150px] md:h-[150px] xl:h-[250px] object-contain'/>
        <p className='flex justify-start items-start text-justify'>{currentLanguage==="de"?item.description.de:currentLanguage==="en"?item.description.en:item.description.pl}</p>
    </div>
   ))}</div>
   </div>
</div>
<div className='flex flex-col xs:pt-12 md:pt-16 xl:pt-28'>
   <h3 className='xs:text-[15px] md:text-[20px] xl:text-[25px] font-bold font-roboto underline underline-offset-[12px] decoration-bbf-green'>{currentLanguage==="de"?teile['starke-teilemarken']?.name.de:currentLanguage==="en"?teile['starke-teilemarken']?.name.en:teile['starke-teilemarken']?.name.pl}</h3> 
   <div className='flex justify-start items-start mt-10'>
   <div className='grid xs:grid-cols-1 xl:grid-cols-3 xs:gap-4 md:gap-7 xl:gap-20'>{teile['starke-teilemarken'].brands.map((item,index)=>(
    <div key={index} className='flex justify-start items-start flex-col'>
        <img src={item.logo} className='xl:w-[250px] xs:w-[100px] xs:h-[100px] md:w-[150px] md:h-[150px] xl:h-[250px] object-contain'/>
        <p className='mt-8 flex justify-start items-start text-justify'>{currentLanguage==="de"?item.description.de:currentLanguage==="en"?item.description.en:item.description.pl}</p>
    </div>
   ))}</div>
   </div>
</div>
<div className='xs:pt-12 md:pt-16 xl:pt-28 flex flex-col'>
<h3 className='xs:text-[15px] md:text-[20px] xl:text-[25px] font-bold font-roboto underline underline-offset-[12px] decoration-bbf-green'>{currentLanguage==="de"?teile['Marken-sortiment']?.name.de:currentLanguage==="en"?teile['Marken-sortiment']?.name.en:teile['Marken-sortiment']?.name.pl}</h3> 
<div className="flex justify-center items-center xs:mt-10 xl:mt-14">
          <div className="grid xl:grid-cols-4 2xl:grid-cols-6 md:grid-cols-3 md:gap-12  sm:grid-cols-3 xl:gap-24 xs:gap-4 ">
            {teile['Marken-sortiment'].brands.map((item, index) => (
              <div key={index}>
                <a href={item.weblink} target="_blank">
                  <img
                    src={item.logo}
                    className="xl:h-[230px] lg:h-[120px]  xl:w-[230px] 
                  lg:w-[120px] 
                  xs:w-[110px] xs:h-[110px] p-5 md:w-[150px] md:h-[150px] object-contain  bg-bbf-brand transition md:ease-in-out md:delay-150  md:hover:-translate-y-1 md:hover:scale-110  md:duration-500 "
                  />
                </a>
                <div
                  className="font-roboto-light flex justify-start items-start  font-semibold text-[14px] xl:w-[230px] 
                  lg:w-[120px] 
                  xs:w-[110px] md:w-[150px]  bg-opacity-60 auto-rows-max xs:h-[40px] md:h-[50px] leading-6 mt-4"
                >
                  {item.name}
                
                </div>
              </div>
            ))}
          </div>
        </div>
        </div>
        </div>
        </div>
  )
}

export default Fahrradteile