import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
const Footer = () => {
  const date = new Date();
  const currentYear = date.getFullYear();
  const { t } = useTranslation();
  return (
    <div className="bg-bbf-black xs:h-[160px] xl:h-[100px] flex xs:flex-col sm:flex-col md:flex-col xl:flex-row justify-between xs:gap-5  items-center text-bbf-white font-roboto xl:px-[70px]  sm:py-3 lg:py-0">
      <div className="flex-1 flex justify-start items-center xs:gap-3 md:gap-5 xs:text-[13px] md:text-[17px]">
        <Link to="/help">{t("help")}</Link>

        <Link to="/Datenschutz">
          <h2 className="cursor-pointer">{t("dataprotection")}</h2>
        </Link>
        <Link to="/impressum">
          <h2 className="cursor-pointer">{t("imprint")}</h2>
        </Link>
      </div>
      <div className="flex-1 flex justify-center items-center xs:text-[13px] md:text-[17px]">
        © {currentYear} {t("footernote")}
      </div>
      <div className="flex-1 flex justify-end items-center xs:gap-3 md:gap-5 text-[17px]">
        <div className="flex gap-2 justify-center items-center">
          <a
            href="https://www.linkedin.com/company/bbf-bike-gmbh"
            target="_blank"
          >
            <img
              src="https://img.icons8.com/color/48/linkedin.png"
              alt="linkedin"
              className="2xl:h-8 2xl:w-8 xl:h-8 xl:w-8 lg:h-7  xs:h-8 xs:w-8 lg:w-7 object-contain cursor-pointer hover:opacity-90"
            />
          </a>
        </div>
      </div>
      {/* <div className="flex font-roboto gap-3">
        <Link to="/Datenschutz">
          <h2 className="font-bbf-white  xl:text-[20px] xs:text-[15px]  cursor-pointer">
            {t("dataprotection")}
          </h2>
        </Link>

        <Link to="/impressum">
          <h2 className="font-bbf-white xl:text-[20px] xs:text-[15px]  cursor-pointer">
            {t("imprint")}
          </h2>
        </Link>
      </div>
      <div>
        <h2 className=" xl:text-[18px] xs:text-[15px] font-roboto-regular font-bbf-white">
          © {currentYear} {t("footernote")}
        </h2>
      </div>
      <div className="flex items-center gap-3">
        <a href="https://bbf.bike/karriere" target="_blank">
        <h2 className="font-bbf-white xl:text-[20px] xs:text-[15px]  cursor-pointer">
          {t("Career")}
        </h2>
        </a>
        <a
          href="https://www.linkedin.com/company/bbf-bike-gmbh"
          target="_blank"
        >
          <img
            src="https://img.icons8.com/color/48/linkedin.png"
            alt="linkedin"
            className="2xl:h-8 2xl:w-8 xl:h-8 xl:w-8 lg:h-5 sm:h-6 sm:w-6 xs:h-5 xs:w-5 lg:w-5 object-contain cursor-pointer hover:opacity-90"
          />
        </a>
      </div> */}
    </div>
  );
};

export default Footer;
