import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import branches from "../data/branches.json";
import { useTranslation } from "react-i18next";
import { Button } from "./Button";

const Locations = () => {
  //     const[branches,setBranches]=useState([])
  //     const locations=async()=>{
  //  try{
  //     const response= await axios.get("https://api.bbf-bike.de/api/web/data/bbf-bike/branches")
  //     setBranches(response.data)
  //  }
  //  catch(err){
  //     console.log(err)
  //  }
  //     }

  //     useEffect(()=>{
  //         locations()
  //     },[])

  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const sectionRef = useRef();
  const scrollToSection = () => {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  return (
    <div className="min-h-[calc(100vh-100px)] h-auto xl:mb-[15px] md:mb-[10px] xs:mb-[5px]">
      <div className="lg:pt-[110px] sm:pt-0 relative">
        <img
          src="https://media.bbf-bike.de/shop/documents/websites/bbf-bike/images/branches/Berlin.jpg"
          className="h-full xs:object-contain md:object-cover
          w-full"
        />
    <div className="absolute inset-0 bg-bbf-black bg-opacity-30"></div>
        <h2 className="absolute inset-0 text-bbf-white  flex justify-start lg:pt-[110px] md:mx-16 xs:mx-5 items-center xs:text-[18px] md:text-[35px] xl:text-[70px] font-roboto-regular">
          <div className="underline xs:underline-offset-[6px] md:underline-offset-[12px] decoration-bbf-green font-roboto"> {t("ourlocations").toUpperCase()}</div>
         
        </h2>
         <h3 className="absolute inset-0 font-roboto md:mx-16 xs:mx-4 xs:text-bbf-black md:text-bbf-white xs:top-[100px] md:top-[150px] xl:top-[350px] 2xl:top-[430px] xl:text-[30px] md:text-[18px] xs:text-[13px] xs:my-2 md:my-0 ">
        {t("ourlocationdes")}
</h3>
      
      </div>
      <div className="flex justify-center items-center md:mb-[10px] xl:mb-[15px]  xs:mb-[5px] ">
        <div
          className="md:mt-[35px] xl:mt-[45px]  xs:mt-[40px] grid 2xl:grid-cols-4 md:grid-cols-2 xs:grid-cols-1 xl:gap-10 xs:gap-6 md:gap-4 "
          ref={sectionRef}
        >
          {branches.map((item, index) => (
            <div key={index} className="flex flex-col xs:w-[350px] md:w-[350px] xl:w-[400px]  ">
              <div className="relative">
                <img
                  src={item.image}
                  className="h-full xs:object-cover xs:h-[220px] md:object-cover xl:h-[240px]
                  w-full"
                />
              
              </div>
              <div className="bg-bbf-green text-bbf-black xs:h-[390px] md:h-[430px] md:w-[350px] xl:w-[400px] xs:w-full flex flex-col  items-start xs:p-5 md:p-12">
              <div class="  grid grid-cols-1 grid-rows-5 gap-6  auto-rows-max">
                <div className="xl:text-[25px] md:text-[20px]  xs:text-[18px] font-roboto font-bold ">
                  {item.name}
                </div>
               
                  <div className="flex  justify-start items-start gap-2">
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsT
        AAALEwEAmpwYAAAC5UlEQVR4nO2ayWsUQRSHv0ZFNBNXBA8elCgm4MGLF0FNQKOJghqXgMtJzR+Qs4IoZOYuKHjxphj0o
        EFwGcHoyYMQFXTGgAdBEKJx3+IyUlANMjgm/bqqq3qmP/hBX6bf+1X3VL2q15CRkZGRkWGbHNANFIAhoASMAxNa6vopc
        BXIA136N6km0EYGgW9AJaK+AheBLfpeqWIP8EhgupZGgB5SwAqgaNB4tW4Cy/GUXcA7i+ZDfQD24REBcDwB49Uq+DA3BM
        AZB+ZDnXY9CHmH5kOddGW+1wPzofa7mO0/emA81HugJckBKHpg+l9LZCLs9sBsLe20bT4wXOGZ1ojtVaHLQJI3gMPASqB
        JqxU4ol/juPffbHMABmMkpnaA66YQYz1QjhHngi3zzcJdndIdYF6EWPOBYWGsL8Bsn17/UkTzfw/CM2HMTgv+KQiTmcpr
        X4t2YcwBLDDkaG2W1BxXsEBZkIia7ePSJ4irjteM81qQiFrq4tIqiDuGBb4LEjFxsJkTxFWrlXEmHA3AHEFc9bCMM+7oL
        9AmiPsGC5RSNAk+wQLXBIncMhD3tiCuWrKNMyBIRKkjRswNwpgnsMBWYTJlXdZGZQEwKoypuknGaQI+CxMajjgIyvxdYa
        xPtjZDikvCpCp6Y6Nq+8noiPHkK3rLbo3OGImFKuqZvU3XCTl93Sec8Kq10eYABMBjA0na0sMkGiXbPTBaS9tIiKIHZp0
        diyuWedYYUR3jpSRMD/DbA/Mqh700cHM0j0MC4JxD8+ddt8cVM4DrDsyrmmEmntAMPEh4vZ+LZyyKcY4fRc+BxXhKC/DK
        ovkxQ6dMVlljqUZQLa+1pIRu4IdB8z+BHaSMA4YKJXWPQ6SUYwYG4Cgp51QM82epA6YBl4Unu9OpE2YB9yKYv6/PIOuKh
        bphMZn5UV1U1SVLgBf/Mf/Sxb4+aVYBb2t87bmaBqG96oMr1XneRIPRC/zShc5BGpR+rYyMDJzwBzJQcuzq9Sl7AAAAAE
        lFTkSuQmCC"
                      className="sm:h-7 sm:w-7 xs:h-5 xs:w-5 "
                    />
                    <h1>{item.address}</h1>
                  </div>
                  <div className="flex  justify-start items-start gap-2 ">
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTA
        AALEwEAmpwYAAACJklEQVR4nO3YTahNURQH8N/zma/kq4SIIhkoMpGSlDKiMDBAmZgayszA4E0NMXsiyQwlBlKKkpSUrz
        ARwivk2/O2jvat43reO/e+c9493fa//vcO9t5r/ddeZ62z7iUhISEhoUMIXUJdE8hAtwSyFf01ENIuB3G0USeLcbsGokK
        L/IgdjSDWx++pOFMDcaEg72F51D4n+/iJQ7kudgA/aiA0DMPswqdFvWvwLN+1+jAlLm7E6xoIDk1svvS9+BzX/tp4B0vi
        pkW4VQPxIfINNkdtk3Gsaf2fA2+bDpysQRA3sCBqWoibQ+wplMKsbr53KIjjmJR75F/9Z9+wRk7HbpZhA16OYQBfsT/67
        sHBEZrQiAbvYmk0OA/XxiCIJ1gdfU7HuQJnChl+hy3R8AT0VhjERcyKvlbgfsFzhR0MxLrJ0pxhD76UPGr0Yly0vw3vWz
        jfssOzTS+j5yUE0R9nvgzjcSQG1oqNthw/wMo/bpmLq6MIIqvBZTlbV9q007aAD9ieu8V26uZUriuuHWV2S32ud+NTgXP
        fYjttYF8J9SaUwAuYGUVlbfPpMHtf5CbubHI4UZIGoSQ+wqoocDYuD7HnOuZXNMsJFf3Q6Ynt+ldu1JgY1zZVMF0LJXMw
        ts9G3ezErlxwhyv6n0CoiJdyb+gMM3C+Qn9ChXyMdZEPK/YldAl1WkAKRMqIjj8+IdWIzt90SBnR+dsNKSO6ICMJCQkJC
        cYavwFpBILbyX7yRgAAAABJRU5ErkJggg=="
                      className="sm:h-7 sm:w-7 xs:h-5 xs:w-5 "
                    />
                    <h1>{item.contact.email}</h1>
                  </div>
                  <div className="flex  justify-start items-start gap-2">
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsT
        AAALEwEAmpwYAAACQ0lEQVR4nO2ZvUscQRiHH6OXpIioTWItISBRvPSxtYmiRsWAkCJBIY3N1YZAPlDJHxFBwVgkRfJ
        vpDCgqPloA2kuXHJ+pLhXBmbhWHZvZu9272Zgf/Brjp133udmZ2bnHciVK1caugo8AnaBb0AVkIxcBU50X4u671Q0D/z
        MMHGTfwAPWwHoBt52EEBC3gSuNAPiEoRobySFmHMgaYnxrC2EmlzfHUhYYqzm6zUbkEUHkhWDF2xAdh1IVAzesQE5tgx
        WAV4Ad4F+YAz42iaQIxuQvxaByhogrKGMN0up+xONsgm01KB9qU2jYpRNkOuGjfSLDyA1ixizPoAI0GeIMegLSNEQ444
        vICuGGM99AXnfoP0IcOYLSBW4EbNi7bcBQtICUX4a0Xa8TRCSJoj6Qu4JtR32EUSAJxHtP/sI8hu4GWo/Clz4BiLAh4g
        Yqz6CSMzEX/cR5D8wETMy5xHPq31mDbgN3AJmmvjQNKqVM8K9mA3yU91z+/q3qD2olOA8kxmI8q8YmGBpvq8TbqQhy5N
        mpiDByES9ZklUdAEkmDPLLYD0uwIS+KOezEk15hpIsGmq5bmQAOSViyD1VXX1uvUa+h8F/qUBUskQRvkU2AOe6RVuQPer
        ChqPgT9plYNsC3Sd9KENyLYDiYrBWzYgCw4kKgaraw+jCvoOTxz1cZIVcFoX4sQx14BJEuqNA4lLyC9pQuri8bUjI1PTE
        F20oKkOL8lHwANSUkEXptWyd6DvR7JKvKz7eKf7DFdqcuXKRXJdAj5dsYRERMaRAAAAAElFTkSuQmCC"
                      className="sm:h-7 sm:w-7 xs:h-5 xs:w-5 "
                    />
                    <h1>{item.contact.phone}</h1>
                  </div>
                
               
                  <Link to={`/locations/${item.branchId}`}>
                    <Button text={t("moredetails")}  color="bbf-black"/>
                  </Link>
               
              </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Locations;
