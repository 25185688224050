import React from 'react'
import laufrad from "../data/Laufradproduktion.json"
import { Button } from './Button'
import { useEffect } from 'react'

import { useTranslation  } from 'react-i18next'
const WheelRim = () => {
  const{i18n}= useTranslation()
  const currentLanguage= i18n.language
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='min-h-[calc(100vh-100px)] h-auto '>
    <div className='xl:pt-[160px] xs:pt-9 xl:mx-0 xs:mx-5 xs:text-[13px] md:text-[15px] xl:text-[17px] font-roboto'>
      <div className='xl:mx-[70px]'>
      
    <p className='xs:text-[13px] md:text-[15px] xl:text-[17px] xs:text-justify xl:text-start xs:mb-10 xl:mb-20'>{currentLanguage==="de"?laufrad.Laufradproduktion.description.de:currentLanguage==="en"?laufrad.Laufradproduktion.description.en:laufrad.Laufradproduktion.description.pl}</p>
      </div>
    
    {laufrad.Laufradproduktion.sections.map((item,index)=>(
      <div key={index} className={`flex flex-wrap xs:flex-col-reverse ${
        index % 2 === 0 ? "lg:flex-row" : "lg:flex-row-reverse"
      }`}>
         <div className="flex-1 items-center flex  sm:my-8 xs:my-5 lg:my-0 ">
                    <div className="flex flex-col justify-center  md:mx-[70px]">
                      <div className=" xl:mt-[25px]   xs:text-[13px] md:text-[15px] xl:text-[17px] font-roboto text-justify sm:mt-5 xs:mt-5 xl:mx-0 ">
                      <h3 className='xs:text-[15px] md:text-[20px] xs:my-5 my-20 xl:text-[25px] font-bold font-roboto underline underline-offset-[12px] decoration-bbf-green'>{currentLanguage==="de"? item.headline?.de:currentLanguage==="en"?item.headline?.en:item.headline?.pl}</h3> 
            <p>{currentLanguage==="de"?item.description.de[0]:currentLanguage==="en"?item.description.en[0]:item.description.pl[0]}<a href='https://b2b.bbf-bike.de/PrivateLogin/index/requireReload/' target='_blank'>

<b className='text-bbf-green ml-2'>{currentLanguage==="de"?item.description.de[1]:currentLanguage==="en"?item.description.en[1]:item.description.pl[1]}</b>
             </a>  {currentLanguage==="de"?item.description.de[2]:currentLanguage==="en"?item.description.en[2]:item.description.pl[2]}
             
             <b className='text-bbf-green font-bold ml-2'><a href={`tel:${item.description.de[3]}`} target='_blank'>{currentLanguage==="de"?item.description.de[3]:currentLanguage==="en"?item.description.en[3]:item.description.pl[3]}</a></b>
             </p>
             
         
             <p></p>
             	
           
      
            
           
           
                      </div>
                      <div className="xl:mt-[30px] xs:mb-[10px] xl:mb-[20px] md:mt-[20px] xl:mx-0  xl:ml-0 xs:mt-[15px]">
                        {item.button?(<a href={item.link} target="_blank">
                          <Button text={currentLanguage==="de"?item.button.de:currentLanguage==="en"?item.button.en:item.button.pl} color="bbf-black"/>
                        </a>):""}
                      </div>
                    </div>
                  </div>
                  <div className="flex-1">
                    <div className="relative">
                      <img
                        src={item.image}
                        className="h-full xs:object-contain md:object-cover
                  w-full"
                      />
                    </div>
                  </div>
      </div>
    ))}
        </div>
       </div>
  )
}

export default WheelRim