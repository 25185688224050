import React, { useEffect } from "react";
import messe from "../data/messe.json"
import { useTranslation } from "react-i18next";
import i18n from "..";
const ExibitionPage = () => {
  const { t } = useTranslation();
  const currentLanguage = i18n.language;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="min-h-[calc(100vh-100px)] h-auto">
      <div className=" xs:pt-12 xl:pt-[200px] flex justify-center items-center flex-col xs:gap-8 md:gap-10 xl:gap-14">
        <h2 className="xs:text-[15px] md:text-[20px] xl:text-[25px] font-bold font-roboto underline underline-offset-[12px] decoration-bbf-green  text-start">
          {t("outhousemesseheadline").toUpperCase()}
        </h2>
        <div className="grid xl:grid-cols-4 md:grid-cols-3 xs:grid-cols-2 xs:gap-5 md:gap-12 xl:gap-24 xs:text-[15px] md:text-[20px]  mb-20">
          {messe.outhouse.map((item, index) => (
            <div
              key={index}
              className="flex justify-start group items-start flex-col    cursor-pointer  xs:w-[150px] md:w-[170px] xl:w-[200px]"
            >
              <a href={item.link} target="_blank">
              <h3 className=" font-roboto xs:text-[13px] md:text-[17px]">{item.branch}</h3>
              <h5>
                {item.dates.de.map((item, index) => (
                  <div
                    key={index}
                    className="mt-3 font-roboto xs:text-[13px] md:text-[17px]  bg-bbf-brand p-2 font-semibold"
                  >
                    {item}
                  </div>
                ))}
              </h5>
              </a>
             
            </div>
          ))}
        </div>

      </div>
    </div>
  );
};

export default ExibitionPage;
