import React from 'react'
import { useTranslation } from 'react-i18next'

const VideoSection = () => {
  const {t}=useTranslation()
  return (
    <div className='flex flex-col justify-center items-center'>
        <h3 className="xl:text-[45px] xs:text-[13px] md:text-[17px] xs:p-2 md:p-3 xl:p-4 font-roboto font-bold xl:my-4 md:my-3 xs:my-2 underline underline-offset-[12px] decoration-bbf-green">
        {t("BBFBIKEinBewegung")}
        </h3>
        <div className='bg-bbf-brand w-full flex justify-center items-center flex-col'>
        <h2 className='md:text-[22px] sm:text-[16px] font-roboto-regular sm:text-center sm:mx-4 xs:mt-10 md:mt-16 lg:mt-20 '>{t("taglinevideo")}</h2>
        <video src="https://media.bbf-bike.de/shop/documents/websites/bbf-bike/videos/zukunftspreis_2013_bbf-bike-gmbh.mp4" controls className='xs:my-10 md:my-16 lg:my-20 border-2 border-bbf-black lg:w-[708px] lg:h-[400px] sm:w-[350px] xs:w-[250px] md:h-[400px] md:w-[700px]' />
        </div>

    </div>
  )
}

export default VideoSection