import React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
const Dataprotection = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { t } = useTranslation();
  return (
    <div className="min-h-[calc(100vh-100px)] h-auto md:mb-[30px] xl:mb-[35px]  xs:mb-[15px]">
      <div className="md:mx-[70px] xs:mx-5 xs:mt-9 lg:mt-40 font-roboto">
        <div className="flex items-start justify-start flex-col xs:gap-3 md:gap-4 xl:gap-6 text-justify">
          <h2 className="xs:text-[15px] md:text-[20px] xl:text-[25px] font-roboto font-bold  md:my-5 xs:my-4 underline underline-offset-[12px] decoration-bbf-green">
            {t("dataprotection").toUpperCase()}
          </h2>
          <p className="xs:text-[13px] md:text-[15px] xl:text-[17px] ">
            Aufgrund einer Forderung aus der am 25. Mai 2018 in Kraft getretenen
            EU Datenschutz-Grundverordnung (DSGVO) sind wir, die BBF BIKE GMBH,
            Carenaallee 8 15366 Hoppegarten, verpflichtet, Sie nach Artikel 13
            der DSGVO ausführlich über die Verarbeitung Ihrer personenbezogenen
            Daten auf unserer Webseite (nachfolgend nur noch Daten genannt)
            aufzuklären.
          </p>
          <p className="xs:text-[13px] md:text-[15px] xl:text-[17px] ">
            Dieser Verpflichtung kommen wir gerne nach!
          </p>
          <p className="xs:text-[13px] md:text-[15px] xl:text-[17px] ">
            Nachfolgend erläutern wir, welche Daten wir von Ihnen zu welchen
            Zwecken verarbeiten und welche Rechte Sie diesbezüglich haben.
          </p>

          <h4 className="font-semibold xs:text-[16px] md:text-[18px]">
            Datenerhebung
          </h4>
          <p className="xs:text-[13px] md:text-[15px] xl:text-[17px] ">
            Wir erheben Daten beim Anbahnen, beim Abschluss, beim Abwickeln und
            beim Rückabwickeln eines Kaufvertrages. Diese Daten werden von uns
            erhoben, gespeichert und verarbeitet.
          </p>
          <p className="xs:text-[13px] md:text-[15px] xl:text-[17px] ">
            Ihr Besuch auf unseren Webseiten wird protokolliert. Erfasst werden
            im Wesentlichen die aktuell von Ihrem PC verwendete IP-Adresse,
            Datum und Uhrzeit, der Browsertyp und das Betriebssystem Ihres PC
            sowie die von Ihnen betrachteten Seiten. Ein Personenbezug ist uns
            im Regelfall nicht möglich und auch nicht beabsichtigt.
          </p>
          <h4 className="font-semibold xs:text-[16px] md:text-[18px]">
            Datenverwendung und -weitergabe
          </h4>
          <p className="xs:text-[13px] md:text-[15px] xl:text-[17px] ">
            Die personenbezogenen Daten, die Sie uns z.B. durch ein
            Kontaktformular, bei einer Bestellung oder per E-Mail mitteilen
            (z.B. Ihr Name und Ihre Adresse oder Ihre E-Mail-Adresse), werden
            nur zur Korrespondenz mit Ihnen und nur für den Zweck verarbeitet,
            zu dem Sie uns die Daten zur Verfügung gestellt haben. Wir geben
            Ihre Daten nur an das mit der Lieferung beauftragte
            Versandunternehmen weiter, soweit dies zur Lieferung der Waren
            notwendig ist. Zur Abwicklung von Zahlungen geben wir Ihre
            Zahlungsdaten nur an das mit der Zahlung beauftragte Kreditinstitut
            weiter.
          </p>
          <p className="xs:text-[13px] md:text-[15px] xl:text-[17px] ">
            Wir versichern, dass wir Ihre personenbezogenen Daten im übrigen
            nicht an Dritte weitergeben, es sei denn, dass wir dazu gesetzlich
            verpflichtet waren oder Sie uns vorher Ihre Zustimmung gegeben
            haben. Soweit wir zur Durchführung und Abwicklung von
            Verarbeitungsprozessen Dienstleister in Anspruch nehmen, werden die
            Vertragsverhältnisse nach den Bestimmungen des
            Bundesdatenschutzgesetzes geregelt.
          </p>
          <h4 className="font-semibold xs:text-[16px] md:text-[18px]">
            Einwilligung und Widerruf
          </h4>
          <p className="xs:text-[13px] md:text-[15px] xl:text-[17px] ">
            Wenn Sie uns personenbezogene Daten überlassen haben, können sie
            diese jederzeit wieder löschen. Daten für Abrechnungs- und
            buchhalterische Zwecke sind von einer Kündigung/einem Widerruf bzw.
            von einer Löschung nicht berührt.
          </p>
          <h4 className="font-semibold xs:text-[16px] md:text-[18px]">
            Speicherdauer
          </h4>
          <p className="xs:text-[13px] md:text-[15px] xl:text-[17px] ">
            Personenbezogene Daten, die uns über unsere Website mitgeteilt
            worden sind, werden nur so lange gespeichert, bis der Zweck erfüllt
            ist, zu dem sie uns anvertraut wurden. Soweit handels- und
            steuerrechtliche Aufbewahrungsfristen zu beachten sind, kann die
            Speicherdauer zu bestimmten Daten bis zu 10 Jahre betragen.
          </p>
          <h4 className="font-semibold xs:text-[16px] md:text-[18px]">
            Verwendung von Cookies
          </h4>
          <p className="xs:text-[13px] md:text-[15px] xl:text-[17px] ">
            Um den Besuch unserer Website attraktiv zu gestalten und die Nutzung
            bestimmter Funktionen zu ermöglichen, verwenden wir auf
            verschiedenen Seiten sogenannte Cookies. Hierbei handelt es sich um
            kleine Textdateien, die auf Ihrem Endgerät abgelegt werden. Einige
            der von uns verwendeten Cookies werden nach dem Ende der
            Browser-Sitzung, also nach Schließen Ihres Browsers, wieder gelöscht
            (sog. Sitzungs-Cookies). Andere Cookies verbleiben auf Ihrem
            Endgerät und ermöglichen uns oder unseren Partnerunternehmen, Ihren
            Browser beim nächsten Besuch wieder zuerkennen (persistente
            Cookies).
          </p>
          <p className="xs:text-[13px] md:text-[15px] xl:text-[17px] ">
            Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
            Cookies informiert werden und einzeln über deren Annahme entscheiden
            oder die Annahme von Cookies für bestimmte Fälle oder generell
            ausschließen. Bei der Nichtannahme von Cookies kann die
            Funktionalität unserer Website eingeschränkt sein.
          </p>
          <h4 className="font-semibold xs:text-[16px] md:text-[18px]">
            Chatbot
          </h4>
          <p className="flex flex-col justify-start items-start xs:text-[13px] md:text-[15px] xl:text-[17px] ">
            Zweck der Daten <br />
            Diese Liste stellt die Zwecke der Datenerhebung und -verarbeitung
            dar.
            <br />
            Chatverlauf beibehalten
            <br />
            Die IP-Adresse des Abrufgerätes
            <br />
            Datum und Uhrzeit des Zugriffs
            <br />
            Website von der aus Zugegriffen wurde
            <br />
            Rechtliche Grundlage
            <br />
            Im Folgenden wird die erforderliche Rechtsgrundlage für die
            Verarbeitung von Daten genannt
            <br />
            Art. 6 Abs. 1 s. 1 lit. c DSGVO
            <br />
          </p>
          <h4 className="font-semibold xs:text-[16px] md:text-[18px]">
            Kundenkonto
          </h4>
          <p className="xs:text-[13px] md:text-[15px] xl:text-[17px] ">
            Wenn Sie ein Händlerkonto eröffnen, willigen Sie damit ein, dass
            Ihre Bestandsdaten wie zum Beispiel Name, Adresse und Ihre
            E-Mailadresse sowie Ihre Nutzungsdaten (Benutzername, Passwort)
            gespeichert werden. Dadurch haben Sie die Möglichkeit, mit Ihrer
            E-Mailadresse oder Ihrer Kundennummer sowie Ihrem persönlichen
            Passwort bei uns zu bestellen.
          </p>
          <h4 className="font-semibold xs:text-[16px] md:text-[18px]">
            Google Analytics
          </h4>
          <p className="xs:text-[13px] md:text-[15px] xl:text-[17px] ">
            Diese Website nutzt zur Webanalyse Google Analytics. Dies ist eine
            Dienstleistung der Google Inc. ("Google") Google Analytics verwendet
            "Cookies". Die durch den Cookie erzeugten Informationen über Ihre
            Benutzung dieser Webseite (einschließlich Ihrer IP-Adresse) werden
            an einen Server von Google in den USA übertragen und dort
            gespeichert. Google beachtet die Datenschutzbestimmungen des
            "US-Safe-Harbor-Abkommens" und ist beim "Safe Harbor-Programm" des
            US-Handelsministeriums registriert. Google wird diese Informationen
            nutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die
            Website-Aktivitäten für uns zusammenzustellen und um weitere mit der
            Website-Nutzung und der Internetnutzung verbundene Dienstleistungen
            an uns zu erbringen. Google wird diese Informationen auch
            gegebenenfalls an Dritte übertragen, sofern dies gesetzlich
            vorgeschrieben oder soweit Dritte diese Daten im Auftrag von Google
            verarbeiten. Google wird Ihre IP-Adresse nicht mit anderen Daten von
            Google in Verbindung bringen. Sie können die Installation der
            Cookies durch eine entsprechende Einstellung Ihrer Browser Software
            verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem
            Fall gegebenenfalls nicht sämtliche Funktionen dieser Website voll
            umfänglich nutzen können.
          </p>
          <p className="xs:text-[13px] md:text-[15px] xl:text-[17px] ">
            Wenn Sie nicht wünschen, dass Informationen über Ihren
            Webseiten-Besuch an Google Analytics übermittelt werden, haben Sie
            die Möglichkeit, für Ihren Browser ein "Deaktivierungs-Add-On"
            (https://tools.google.com/dlpage/gaoptout?hl=de) zu installieren.
            Außerdem haben wir Einstellungen vorgenommen, wonach Google
            Analytics den letzten Teil der IP-Adressen der Besucher unserer
            Website löscht. Damit kommen wir auch hier nicht in den Besitz von
            Daten, die für uns Rückschlüsse auf Ihre Person zulassen.
          </p>
          <h4 className="font-semibold xs:text-[16px] md:text-[18px]">
            Ihre Rechte, Auskunft
          </h4>
          <p className="xs:text-[13px] md:text-[15px] xl:text-[17px] ">
            Sollten Sie mit der Speicherung Ihrer personenbezogenen Daten nicht
            mehr einverstanden oder diese unrichtig geworden sein, werden wir
            auf eine entsprechende Weisung hin die Löschung oder Sperrung Ihrer
            Daten veranlassen oder die notwendigen Korrekturen vornehmen (soweit
            dies nach dem geltendem Recht möglich ist). Auf Wunsch erhalten Sie
            unentgeltlich Auskunft über alle personenbezogenen Daten, die wir
            über Sie gespeichert haben.
          </p>
          <p className="xs:text-[13px] md:text-[15px] xl:text-[17px] ">
            Bei Fragen zur Erhebung, Verarbeitung oder Nutzung Ihrer
            personenbezogenen Daten, bei Auskünften, Berichtigung, Sperrung oder
            Löschung von Daten wenden Sie sich bitte per E-Mail an
            Datenschutz@BBF-Bike.de.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Dataprotection;
