import React, { useEffect, useState } from "react";
import Logo from "../Images/BBF_LOGO.png";
import { Link } from "react-router-dom";
import { Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { Button } from "./Button";
import goBackIcon from "../Images/goback.png";
const Header = () => {
  const [languageSelected, setLanguageSelected] = useState(false);
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const handleSelectedLanguage = (selectedlanguage) => {
    i18n.changeLanguage(selectedlanguage);
    localStorage.setItem("language", selectedlanguage);
  };

  const FadeIn = ({ delay, children }) => (
    <Transition.Child
      enter={`transition-all ease-in-out duration-300 ${delay}`}
      enterFrom="opacity-0 translate-y-6"
      enterTo="opacity-100 translate-y-0"
      leave="transition-all ease-in-out duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      {children}
    </Transition.Child>
  );
  const [show, setShow] = useState(false);
  const [showSub, setShowSub] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [subMenuClicked, setSubMenuClicked] = useState(false);
  const[subMenuInnerClicked,setSubMenuInnerClicked]=useState(false)
  const [getSubMenu, setGetSubMenu] = useState("");
  const [getSubMenuInner,setGetSubMenuInner]=useState("")

  const subMenu = (menuItem) => {
    setGetSubMenu(menuItem);
    setSubMenuClicked(true);
    
  };

  const subMenuinner=(innerItem)=>{
    setGetSubMenuInner(innerItem)
    setSubMenuInnerClicked(true)
setSubMenuClicked(false)
setMobileView(false)

  }
 
  const showSection = () => {
    setShow(true);
  };
  const showSectionfalse = () => {
    setShow(false);
  };
  const showMoreItem = () => {
    setShowSub(true);
  };
  const showMoreItemfalse = () => {
    setShowSub(false);
  };
  const showBurgerMenu = () => {
    setMobileView(true);
    setSubMenuClicked(false);
    setGetSubMenuInner(false)
    if (typeof window != "undefined" && window.document) {
      document.body.style.overflow = "hidden";
    }
   
  };
  const closeMenu = () => {
    setMobileView(false);
    setSubMenuClicked(false);
    setGetSubMenuInner(false)
   
    document.body.style.overflow = "unset";
  };



  const selectedLanguage = localStorage.getItem("language");
 console.log(selectedLanguage)
  return (
    <div className="relative">
      <div className="h-[110px] lg:fixed top-0 left-0 w-full bg-bbf-green z-50">
        <div className="h-[35px] bg-bbf-black flex items-center xs:justify-end xl:justify-between">
          <h3 className="2xl:text-[17px] xl:ml-[70px]  xl:text-[14px]  font-roboto-light  text-bbf-white  xl:block xs:hidden sm:hidden  ">
            {" "}
            {t("tagline")}
          </h3>
          {/* <div className="flex text-bbf-white text-[15px] xl:mx-[70px] md:mx-[70px] sm:mx-5 xs:mx-2 ">
            <div
              className={`flex items-center cursor-pointer mr-1  p-2  rounded-md`}
              style={{
                textDecoration: selectedLanguage === "de" ? "underline" : "",
                fontWeight: selectedLanguage === "de" ? "700" : "",
                textUnderlineOffset: selectedLanguage === "de" ? "5px" : "",
              }}
              onClick={() => handleSelectedLanguage("de")}
            >
              <h3 className="mr-1">DE</h3>
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEFklEQVR
      4nO2ZyWsbVxzHfyRuTj2k7Sk0PXVvkrZuvMRLLC9ZJEuyLGkkWdbMaGYeKOCUJj2FQhr50uDkUru30j8gIdBSQintydCcCgUtljcocWulkSXZlhfsmiTSt8
      yYFCwKkqxtCv7C56aBz/e9p3kz84gOcpCDlJ1gMHiIMdYiy/JVSZLuiaIYFkUxLoritiAI2zzPx30+X9jn893zer1X3G53s3oN1TuSJL2mKEpQkqQ/ZFmG
      JEnw+/0QRVFDEATwPK/h8/kwPDwMr9erMTQ0tODxeG7Y7fbjNRf3+/1HGWNjsizvqOL7kIfH44Hb7YbL5XrCcdyEzWY7WhN5RVGcjLGUoigoV969WwAcx8
      HpdKacTqejauLBYLBBHXXGWK7S8txuATgcDtjt9q8DgcALFZV3uVxHGGM/MMZQZXmoDA4O3q9YCZfLdVhRlLs1lIfNZlP5zmAwNJRdgDH2VR3kMTAwAIvFMl
      6WvCzLphqsefyXvNVqVQvkLBaLdV/ygiC8oihKuo7yUDGbzSmz2fxSyQUYY7d1II/+/n6YTKaxkuQDgcAxWZa3dCIPo9G4bTQai9+x06O3H2xah7Bp8WDTrO
      LGRr8bGyYXNkwcNowc1i86sX7Bsct5O9bODWKtT8WGtd4BZHpUrMh0W5ExWLDaZd7lbD9WO01Y6TBipd2IlbaLWDlzAcut57Hceg7LLX1IN/ch3dSL9OkepD/q
      RqrRgFlh5Jei5DE52ZDhLz3Tk3zqwy7EOy1PJw3BwrfVxPg3st7kUx90Ifn+WYQ+vsYXLJAavfWzHuWTpzoR40d+LFhg5er1R3qUT57swLzVt1iwwKo0sqNH+eS
      Jdjzssv5dsECG8+f0KL90og3x5t5swQLrliFdyi+914bEqQ4UUcCjS/mld8/gr5PthQtkHEJOj/KJd1qx2NhdeAkt85d29CifeLsFv7eZCv+Jk5evxfUon3irGTN
      GrvBt9NFnX/ykR/nEm00IcVLhjezPm+N+Pco/fqMJv0qXh4t6mFu2+5/pTX6hsefpZLHvyPFPbzzQk/zj108jNMAX9zitzcIMHcvO0VZunvAvc3nM7oLnzOQxnUcs
      j6k8onlE9rCNEL1KpSQ7T7d0Ig+E6GZJ8tosxOjl7ByldCCfRJRKf6nXSsyRKTtLuTrK5xCm/X1WeZ7sHE3URT6s8WVZ8tosgA5nZ+lOzeVD9C0mqfxPi1qJGB3BDN
      2v4ch/j9+osl+o1ZnANI1hmnJVXfMhmqi4/J4is+TENKWqMPIphKl6Bxx7SsToRUzTKGK0UwH5J9qoh6g2R0x7ikToOGL0OaZoYR/yDxGh6yXvsFUpAjqEKDVhiq4
      gSncRpRCitIgobWlEaBERCiFCdxCmT7Tfgup/zHqQg9D/P/8A9+JSzY86f54AAAAASUVORK5CYII="
                className="h-5 w-5 object-contain"
              />
            </div>

            <div
              className="flex items-center cursor-pointer mr-1 p-2 rounded-md"
              style={{
                textDecoration: selectedLanguage === "en" ? "underline" : "",
                fontWeight: selectedLanguage === "en" ? "700" : "",
                textUnderlineOffset: selectedLanguage === "en" ? "5px" : "",
              }}
              onClick={() => handleSelectedLanguage("en")}
            >
              <h3 className="mr-1">EN</h3>

              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAA
              FOElEQVR4nO2Z+08cVRTHx9dvVmN/0P4PmqiBaXfAzkDFiNLiDHUbVNTEH7BpGm3RRpO2QLSxVBsTK0l/0DStWkiaasDOAKXALrAib1wo4WF5
              OsOyy8I+2F3Y3Zk95g5LXOgszLLDQg3f5P7Azr3nns/lPs65F8N2tKMdaSKKufUcAJwEgOsCgbfxOnycJ3CvXHT4OPoNfUN1UF1sO+jAocpnSJ
              otJGn2LsVwsCyBwBXLslBdkub6SJo9TenZPQl3PCW7YhfJsJ9RDOtCziyXWAAiipeiuZIM/Z0nE+J8GsNmkQw3s8qJeABALjRrRbY3zfGk/M7HS
              Jr7hmLYkKID8QIwqLAhiua+Q31p6rxef+MRkubKo3esFQC3tD4YrkIzCOQ8RbPX1+tUSwBKhmBvor7jBvD7pW9vG3lDhr7an0iAl49UL9Y0TBbG5
              TwAHFnu1B8QR86UdHYkAuBMSYc5EJBG5cqLi29u1PndAGCFVbLafO1H8uunNgPg8Af11pEJlwkAQiBJIdfVKyYhdd/QSFJS7Fus/aNjRUGLxQIKkiR
              w3vh91JCWUyVqAZDOVEm/3Py7SZLAhb4H7t0bs7z2SvdyO16XfD4m5yd1ut08gXtQGOA494URgsGgEohvQRw4WdjaFw/AibOtg15vYFAeGJ/Pay84YR
              AIPBDZbupgJgpD1J/YPIGfXWHgADng7+rsV4JA/Q4MO+uy8mpnYwHIyrvt7OmzNwKAKA9GfV2rQBKjq9rMuS6XNqHpBADqFjQAPBTk+T+nmTdMkcZ4A
              pes7+e1iQ6HS3laSTYAkOfvOgChH8uGm0VJmkF/iDabZTpX37iqr5A17+0WcdY+F9HFmFqAFwDAg1p4KitahNR9/ArjqXut7mtXWyG6etYA6AGAv+Rao
              ii5Si818ATuXlGPTBn3GRrMUWw/rwbgEwBAi7cLYtcCABjWAAgAQHO4XqxCg3ZMDUBZRKN2AJhU2UE3AIyoWQMAMAEAHSrtTob9QCpbF4DXJXdEc2CrC
              6/D21UAyNnT9gQgktdfyGjv374AuPdBB/CoAEge28ZTaFTNGmjfakeF6KVtXYDw1QeEDzMjACjGQWspmgMbVBAAUMjhVrWNAkBBeN8VNtrjGgDjG7UJS
              4frh2oAXlQeh2DAeeH8HbSQIp2aSieH/N1dgypPYvl7tJPYU1nxh2Lo8tO1dtWhRBhiKRsKa9Hc0yek7+9ftSN4HV+dawZRlKNJ2+xCR/iEXXcK5R03T
              thnFzqVIKT5effM0Xwjr0sWI9tO65kqFGiqBSiWjfl88/aPj9fdZ+xQVkeQ/4dHdURRmrr0Q79xI/lAyffm9mBQVJyq/r7ewamMtLsR7T9X5XwYYI+H
              u9UivKQTVv8758vLWpYnVafZ3pD5Vs18PAnNq7k1XoNJQJuF/z4KFLFeLm3iCdxmIYinsVgkEPjXEdMlNP1Orkmcm5OTFpc70Jv/qWlYy5z46CnTkNsT
              7FWcVk7nRSxWzaSk7BJ0uCDsl+Pz7nAu7PiVG2tEOexmJPVpOWzoSvlQiyhJkYkMSpSewjaiuYsXsiX/4iLKooZGnMbs92rtibhWOfhurSOcbkroage
              LRzb7QklB8X+Je6IutiiGg1NfthVjmtyLMlxZogFIhvsZKyp6GNPsZprhKhIGQHO/UZThUUxLIYMUwxVTNCdt8vV6iWYjryT0CEHRrE1rAJLmLGRO1etY
              IkTpDY9r9cREMqwHjfrezKonsC165DuNHuxiBqBZMxoEZAN70J5Z0w9XP7vV/u5oR/8X/Qsqblqc0YX1FgAAAABJRU5ErkJggg=="
                className="h-5 w-5 object-contain"
              />
            </div>
            <div
              className="flex items-center cursor-pointer  py-2 pl-2 rounded-md"
              style={{
                textDecoration: selectedLanguage === "pl" ? "underline" : "",
                fontWeight: selectedLanguage === "pl" ? "700" : "",
                textUnderlineOffset: selectedLanguage === "pl" ? "5px" : "",
              }}
              onClick={() => handleSelectedLanguage("pl")}
            >
              <h3 className="mr-1">PL</h3>
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYA
      AACoElEQVR4nO2YwW7TMBjHo41x2JHBicJLdDblVMmeOHGtNo0H2dQLt3V3Nm2IR0CMA4wLTwEqDwCsHGbH/hynUsvFyKEU0bU4adI4h/
      yl/ylt8/vbXz5/aRDUqlUrt4wx62GoHnMBXSbgDZPwhQklmICfv60Ek9C31+xnOFctY8xa4FthGD7gQh0zCVdcKpPFTKrvXKoe57xROvi
      PKLrHhXrJBIyzgt8IIpLfOIui6G4p8EzAPhMqzAs+Jwi/DmFvZeDGmA0m4FXR4HzWQp3bexUKPxgMNpmEDyuHl38Ml/aexa18qfBqUlLqo
      zHmdu4ApZSNXOizvPDPPMIb6xBgdyl4pdQWE8B8B2BChUu1WNvnfcPzvz7NBs95o4hDqrhdgLEQ4mGW1T/2Dc1vupcK3g5ZkznFN7D5Zxc
      kXNmh0RkgmSorAMznGQCnKB/oegeVC3ZBwKEzABNwUd0A6rU7gIR+ZQNI+JxiB4oflQssIZamhCrT//msBYycATTB45hiU0Vrgt0BYoJC36D
      xYrtLKKa4XwFQM9cEuR9iTfGFd1C6yMjdRmOCuxXegQNngOEObnkHpfM9pNvIGcA8D9Y0wd98w8Yz1gR/tWzOAMlzQHCvegHQUSr4pIzauFGl
      80ATPBqS1v3UAZJdoPjMN3g8NXoRZBU8ad2xB4d3eIJ51G4u97+pJo/2fAfQBHeWgp+GoOjcY+mcBHllOp11TdHb8lcevTft9q3cAZIQT5ubmq
      LLEsvmnb1nIfDTEM3mRjmdCZ0UtvLzpOn27kq6E8HXuR/YtFIUbcUEn9oDpoByGdk+b9t2ULbs6WjHjmVmJ/sdTdBR5hN2FbJD1pA0cbyDDu3Mb
      l887JudHUeSkSR5y0OfJtcO7FSZejCrVatW8D/9AqaRz6+kJ9FxAAAAAElFTkSuQmCC"
                className="h-5 w-5 object-contain"
              />
            </div>
          </div> */}
        </div>
        <div className="xl:mx-[70px] md:mx-[70px] sm:mx-5 xs:mx-2 flex justify-between items-center pt-4">
          <div className="flex justify-center items-center">
            <Link to="/">
              <img
                src={Logo}
                className="xs:w-[153px] xs:h-[43px]   object-contain"
                onClick={closeMenu}
              />
            </Link>
          </div>
          <div className="sm:hidden xs:hidden xl:block">
            <div className="flex justify-center items-center lg:ml-1 xl:ml-5 2xl:ml-0">
              <div
                className="relative group xl:mr-5 2xl:mr-10 lg:mr-3"
                onMouseLeave={showSectionfalse}
              >
                <Link to="#">
                  <div
                    className="flex justify-center items-center cursor-pointer"
                    onMouseEnter={showSection}
                  >
                    <h3 className="text-[16px] font-roboto-regular">
                      {t("aboutus")}
                    </h3>
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAABHEl
  EQVR4nO3SMSiFYRSA4QcRiZSUUpSyKIvBYDKYLBYWk81iNNnvZLTYTBYWi8lgMhgsyqIUpZSUSES4Ur+SIpf/3vvdnKfOeOq8fR8hhBBCCCGEEH
  JXQDHxKfw0ZimBY4tfzHIpr1KHlQSOLn6aVdSX+sXeFtYSOL6YzQYa/NLb4noCEZto9EdN2KpixDaa5aQFO1WI2EWrnLVjr4IRe2hTJh3Yr0DE
  ATqVWRcOyxhxhG4V0oPjMkScok+F9eIkx4gz9KuSAZznEHGBQVU2hMs/RFxhWCJGcPOLiOtsNymjuC0h4g5jEjWO+x9EPGBC4ibx+E3EE6bViK
  ns4M8Rz5hRY2azw98jXjCnRs1/CFlQ4xazCSGEEEL4b14BnfLMQvEtlakAAAAASUVORK5CYII="
                      className="w-[12px] h-[14px] ml-2"
                    />
                  </div>
                </Link>
                <div className="hidden absolute  left-0  bg-bbf-black text-bbf-white group-hover:block w-44 bg-opacity-70 py-4 leading-[50px]">
                  <ul>
                    <Link to="/history">
                      <li className="my-1 hover:bg-bbf-green px-3">
                        {t("history")}
                      </li>
                    </Link>

                    <Link to="/philosophie">
                      <li className="my-1 hover:bg-bbf-green px-3">
                        {t("philosophy")}
                      </li>
                    </Link>

                    <Link to="/brands">
                      <li className="my-1 hover:bg-bbf-green px-3">
                        {t("brands")}
                      </li>
                    </Link>
                    <Link to="/messe">
                      <li className="my-1 hover:bg-bbf-green px-3">
                        {t("messe")}
                      </li>
                    </Link>
                  </ul>
                </div>
              </div>
              <div
                className="relative group xl:mr-5 2xl:mr-10  lg:mr-3"
                onMouseLeave={showSectionfalse}
              >
                <div
                  className="flex justify-center items-center cursor-pointer "
                  onMouseEnter={showSection}
                >
                  <Link to="/locations">
                    <h3 className="text-[16px] font-roboto-regular">
                      {t("ourlocations")}
                    </h3>
                  </Link>
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAABHEl
  EQVR4nO3SMSiFYRSA4QcRiZSUUpSyKIvBYDKYLBYWk81iNNnvZLTYTBYWi8lgMhgsyqIUpZSUSES4Ur+SIpf/3vvdnKfOeOq8fR8hhBBCCCGEEH
  JXQDHxKfw0ZimBY4tfzHIpr1KHlQSOLn6aVdSX+sXeFtYSOL6YzQYa/NLb4noCEZto9EdN2KpixDaa5aQFO1WI2EWrnLVjr4IRe2hTJh3Yr0DE
  ATqVWRcOyxhxhG4V0oPjMkScok+F9eIkx4gz9KuSAZznEHGBQVU2hMs/RFxhWCJGcPOLiOtsNymjuC0h4g5jEjWO+x9EPGBC4ibx+E3EE6bViK
  ns4M8Rz5hRY2azw98jXjCnRs1/CFlQ4xazCSGEEEL4b14BnfLMQvEtlakAAAAASUVORK5CYII="
                    className="w-[12px] h-[14px] ml-2"
                  />
                </div>
                <div className="hidden absolute left-0  bg-bbf-black text-bbf-white group-hover:block bg-opacity-70 w-60 leading-[50px] py-4">
                  <ul>
                    <Link to="/locations/hoppegarten">
                      <li className="my-1 hover:bg-bbf-green px-3">
                        Stammhaus Hoppegarten
                      </li>
                    </Link>

                    <Link to="/locations/rostock">
                      <li className="my-1 hover:bg-bbf-green px-3">Rostock</li>
                    </Link>

                    <Link to="/locations/luebeck">
                      <li className="my-1 hover:bg-bbf-green px-3">Lübeck</li>
                    </Link>

                    <Link to="/locations/rastede">
                      <li className="my-1 hover:bg-bbf-green px-3">Rastede</li>
                    </Link>

                    <Link to="/locations/muenchen">
                      <li className="my-1 hover:bg-bbf-green px-3">München</li>
                    </Link>

                    <Link to="/locations/recklinghausen">
                      <li className="my-1 hover:bg-bbf-green px-3">
                        Recklinghausen
                      </li>
                    </Link>

                    <Link to="/locations/ronneburg">
                      <li className="my-1 hover:bg-bbf-green px-3">
                        Ronneburg
                      </li>
                    </Link>

                    <Link to="/locations/bielefeld">
                      <li className="my-1 hover:bg-bbf-green px-3">
                        Bielefeld
                      </li>
                    </Link>
                  </ul>
                </div>
              </div>
              <div className="relative group" onMouseLeave={showSectionfalse}>
                <div
                  className="flex justify-center items-center cursor-pointer"
                  onMouseEnter={showSection}
                >
                  <Link to="/services">
                    <h3 className="text-[16px] font-roboto-regular">
                      {t("ourservices")}
                    </h3>
                  </Link>
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAABHEl
  EQVR4nO3SMSiFYRSA4QcRiZSUUpSyKIvBYDKYLBYWk81iNNnvZLTYTBYWi8lgMhgsyqIUpZSUSES4Ur+SIpf/3vvdnKfOeOq8fR8hhBBCCCGEEH
  JXQDHxKfw0ZimBY4tfzHIpr1KHlQSOLn6aVdSX+sXeFtYSOL6YzQYa/NLb4noCEZto9EdN2KpixDaa5aQFO1WI2EWrnLVjr4IRe2hTJh3Yr0DE
  ATqVWRcOyxhxhG4V0oPjMkScok+F9eIkx4gz9KuSAZznEHGBQVU2hMs/RFxhWCJGcPOLiOtsNymjuC0h4g5jEjWO+x9EPGBC4ibx+E3EE6bViK
  ns4M8Rz5hRY2azw98jXjCnRs1/CFlQ4xazCSGEEEL4b14BnfLMQvEtlakAAAAASUVORK5CYII="
                    className="w-[12px] h-[14px] ml-2"
                  />
                </div>
                <div className={`hidden absolute  left-0  bg-bbf-black text-bbf-white group-hover:block bg-opacity-70 leading-[50px] py-4  ${selectedLanguage==="pl"?"w-96":"w-60"}`}>
                  <ul>
                    <Link to="/brands">
                      <li className="my-1 hover:bg-bbf-green px-3">
                        {t("services1")}
                      </li>
                    </Link>
                    <Link to="/fahrradteile">
                    <li className="my-1 hover:bg-bbf-green px-3">
                      {t("services2")}
                    </li></Link>
                    
                   <Link to="/rental">
                    <li className="my-1 hover:bg-bbf-green px-3">
                      {t("services3")}
                    </li>
                    </Link>
                    <Link to="/cycleparts">
                    <li className="my-1 hover:bg-bbf-green px-3">
                      {t("services4")}
                    </li>
                    </Link>
                   
               <Link to="/laufrad">
               <li className="my-1 hover:bg-bbf-green px-3">
                      {t("services5")}
                    </li>
               </Link>
                    
                   <Link to="/support">
                    <li className="my-1 hover:bg-bbf-green px-3">
                      {t("support")}
                    </li>
                    </Link>
                  </ul>
                </div>
              </div>
              <div
                className="relative group xl:ml-5 2xl:ml-10 lg:ml-3"
                onMouseLeave={showSectionfalse}
              >
                <div
                  className="flex justify-center items-center cursor-pointer"
                  onMouseEnter={showSection}
                >
                  <Link to="#">
                    <h3 className="text-[16px] font-roboto-regular">
                      {t("downloads")}
                    </h3>
                  </Link>
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAABHEl
  EQVR4nO3SMSiFYRSA4QcRiZSUUpSyKIvBYDKYLBYWk81iNNnvZLTYTBYWi8lgMhgsyqIUpZSUSES4Ur+SIpf/3vvdnKfOeOq8fR8hhBBCCCGEEH
  JXQDHxKfw0ZimBY4tfzHIpr1KHlQSOLn6aVdSX+sXeFtYSOL6YzQYa/NLb4noCEZto9EdN2KpixDaa5aQFO1WI2EWrnLVjr4IRe2hTJh3Yr0DE
  ATqVWRcOyxhxhG4V0oPjMkScok+F9eIkx4gz9KuSAZznEHGBQVU2hMs/RFxhWCJGcPOLiOtsNymjuC0h4g5jEjWO+x9EPGBC4ibx+E3EE6bViK
  ns4M8Rz5hRY2azw98jXjCnRs1/CFlQ4xazCSGEEEL4b14BnfLMQvEtlakAAAAASUVORK5CYII="
                    className="w-[12px] h-[14px] ml-2"
                  />
                </div>
                <div className={`hidden absolute  left-0  bg-bbf-black text-bbf-white group-hover:block bg-opacity-70 leading-[50px] py-4 ${selectedLanguage==="en"?"w-[270px]":" w-60"}`}>
                  <ul>
                    <Link to="/catalog">
                      <li className="my-1 hover:bg-bbf-green px-3">
                        {t("catalog")}
                      </li>
                    </Link>

                    <li
                      onMouseLeave={showMoreItemfalse}
                      className="cursor-pointer my-1 hover:bg-bbf-green px-3"
                    >
                      <div
                        className="flex items-center group"
                        onMouseEnter={showMoreItem}
                      >
                        <h3 className="text-[16px] font-roboto-regular">
                          {t("opinstruction")}
                        </h3>
                        <img
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAYE
                    lEQVR4nO2QsQqAMAwF+50iiv6EY3/53eamOKhLkaQWBMlBllLuSFIKguAbgF7SCmyWOf5K6jyB0SrnnsG1haTFsUGuOJQtolq5JaK38qdIM3kp0l
                    x+AszAdD0EwT/YAfkuAltNd20zAAAAAElFTkSuQmCC"
                          className={`w-[18px] h-[18px] ${selectedLanguage==="en"?"ml-[70px]":"ml-16"}`}
                        />
                      </div>
                      <div className="">
                        {showSub ? (
                          <div className={`absolute inset-0 ${selectedLanguage==="en"?"left-[270px]":" left-[240px]"}  text-bbf-white w-60 top-[74px] `}>
                            <Link to="/anleitungen-fahrraeder">
                            <div className="bg-bbf-black bg-opacity-70 px-3 hover:bg-bbf-green">
                              
                                {t("fahrrader")}
                          
                            </div>
                            </Link>
                            <Link to="/ebikeantriebe">
                            <div className="bg-bbf-black bg-opacity-70 px-3 hover:bg-bbf-green">
                              {t("ebike antriebe")}
                            </div></Link>
                            <Link to="/components">
                            <div className="bg-bbf-black bg-opacity-70 px-3 hover:bg-bbf-green">
                              {t("komponenten")}
                            </div>
                            </Link>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </li>

                    <Link to="/ceconfirmations">
                      <li className="my-1 hover:bg-bbf-green px-3">
                        {t("ceconfirmation")}
                      </li>
                    </Link>

                    <a
                      href="https://media.bbf-bike.de/shop/documents/bbf-bike/GRS_EfK23_eMobility.pdf"
                      target="_blank"
                    >
                      <li className="my-1 hover:bg-bbf-green px-3">
                        {t("Battery")}
                      </li>
                    </a>
                  </ul>
                </div>
              </div>
              <div
                className="flex justify-center items-center cursor-pointer"
                onMouseEnter={showSection}
              >
                <Link to="/haendlersuche">
                  <h3 className="text-[16px] font-roboto-regular  xl:ml-5 2xl:ml-10 lg:ml-3">
                    {t("dealersearch")}
                  </h3>
                </Link>
              </div>
              <div
                className="flex justify-center items-center cursor-pointer"
                onMouseEnter={showSection}
              >
                <Link to="/contact">
                  <h3 className="text-[16px] font-roboto-regular  xl:ml-5 2xl:ml-10 lg:ml-3">
                    {t("contact")}
                  </h3>
                </Link>
              </div>
              <div
                className="flex justify-center items-center cursor-pointer"
                onMouseEnter={showSection}
              >
                <a href="https://bbf.bike/karriere" target="_blank">
                  <h3 className="text-[16px] font-roboto-regular  xl:ml-5 2xl:ml-10 lg:ml-3">
                    {t("Career")}
                  </h3>
                </a>
              </div>
            </div>
          </div>
          <div className="flex xs:gap-3 md:gap-10 justify-center items-center">
            <div className="sm:block xl:hidden">
              {mobileView  ? (
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYA
    AAB0UlEQVR4nO2aPWrDQBBGP1inFfgKIgRyiKjyTQQpUqR1mZRqA9ki90jS+BwJhhwhkD4/oCCQQYXsXckzo5VnHkxpdlZG71vtLmAYhmEYhhHHPYAnAGd
    IDwfgAUDFOfm6rQ2AJdIhA/Dc6a/inHzd1geAS0zPOYD3nv4qzsnXbX0BWGE6rgB8HuiP5CH4AwM09QfgFvJcA/gJ9PZIJZfXwEC1oBxd+8+G+mk8taCUz
    FvkoEtB2e2rLUcfeeB945bjPtn1eekCTBQAviObWAnKble/ElIuIxqhlGOM7HZ1AyF8ZEPHyDFWdqTGp06GsXKMlR2L8amTYagcY2XHanzqZIiVY6zsRIx
    PnQwhOQ6RnZjxqZOhT45DZSdufI5k6MprM+J3zVjJ4QYmw9iaxPhcyTC0JjU+VzLMyvhcyTAr43Mmw2yMz5kMk67xOZLh5VSNH4P6B+A1vwKlZgkWmmMw1
    7wQyjQvhZ32jyGv+XO41LwhUmjeEss1b4pmmrfFnfaDET9CdidzNFYeKbtZH44Wmo/Hc80XJDLNV2Sc9ktSXkh2yV6TWwvKjlqOd1QDrQVlRyVHssn3PY
    TUL0uTT757b9gnuiGxaHtrejQMwzAMw0CIf46EpZ58ik3jAAAAAElFTkSuQmCC"
                  className="h-8 w-8 cursor-pointer"
                  onClick={closeMenu}
                />
              ) : (
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwY
     AAABUUlEQVR4nO3YIWuCURjF8ZMGi1bjqpiWBKPVuCqmJcFoNVrFtCQYrcZVMS0JRqtxVUxXLtxwuFu678qu/x+cD/A+vBzu80gAAAAAfhEqSbFQSRhAq
     VBJGECpUEkAAAAAlHuW1EppS3pJ6Uh6tfQlDVKGkt5SRpLeLVNJs5S5pEXKUtKHZSNpm7KT9Jmyl/RlOUk6p1wkfadcG3yzwqMvQ6GSMIBSoZIwgCaerP
     1b1v4xXWv/nrX/wNo/ZmztP7H2n1n7x6ys/dfW/ltr/5iDtf/R2v9s7R9za/oHAAAAAA8qWK72xr5k7++Tvc339mbfZe/5jb31l7YDzLP9YGq7w8h2imG
     2b/RtF+nYjtLO9pc/GUD4x2EApUIlYQClQiVpNIBbdmXx9j9a+x+yq423/9raf5Vdgbz9J9b+4+yq5O3fs/bvZlcqb/94zQIAAACgH+74IoEFCMNpAgA
     AAABJRU5ErkJggg=="
                  className="h-8 w-8 cursor-pointer"
                  onClick={showBurgerMenu}
                />
              )}
            </div>
            <div className="flex items-center justify-center">
              <a
                href="https://b2b.bbf-bike.de/PrivateLogin/index/requireReload/"
                target="_blank"
              >
                <Button text="B2B Shop"  color="bbf-black"/>

                {/* <button
                className={`font-roboto-regular md:text-[14px] xs:text-[12px] md:w-[130px] md:h-[43px] 
     xs:h-[40px] py-[12px] md:ml-[25px]  xs:p-2  sm:ml-0 bg-bbf-white text-bbf-green rounded-[9px] font-semibold`}
              >
                B2B Shop
              </button> */}
              </a>
            </div>
          </div>
        </div>
      </div>

      {mobileView && !subMenuClicked  ? (
        <div className="flex flex-col bg-bbf-black justify-start items-start xs:pl-5 md:pl-[70px] min-h-screen h-auto lg:pt-28 xl:hidden">
          <ul className="xs:text-[20px] md:text-[25px] font-roboto font-semibold text-bbf-white my-10 leading-[60px]">
            <Link to="#">
              <li onClick={() => subMenu("aboutus")}>{t("aboutus")}</li>
            </Link>
            <Link to="">
              <li onClick={() => subMenu("locations")}>
                {t("Unsere Standorte")}
              </li>
            </Link>

            <Link to="">
              <li onClick={() => subMenu("services")}>
                {t("Unsere Leistungen")}
              </li>
            </Link>
            <Link to="">
              <li onClick={() => subMenu("downloads")}>{t("downloads")}</li>
            </Link>
            <Link to="/haendlersuche">
              <li onClick={closeMenu}>{t("dealersearch")}</li>
            </Link>
            <Link to="/contact">
              <li onClick={closeMenu}>{t("contact")}</li>
            </Link>
            <a href="https://bbf.bike/karriere" target="_blank">
              {t("Career")}
            </a>
          </ul>
        </div>
      ) : (
        <div></div>
      )}

      {subMenuClicked ? (
        getSubMenu === "aboutus" ? (
          <div className="flex flex-col bg-bbf-black justify-start items-start xs:pl-5 md:pl-[70px] lg:pt-28 min-h-screen h-auto xl:hidden">
            <div
              onClick={showBurgerMenu}
              className="flex justify-center items-end gap-2"
            >
              <img src={goBackIcon} className="h-8 w-8 xs:mt-5 md:mt-10" />
              <h3 className="text-bbf-white xs:text-[18px] md:text-[23px] font-roboto font-semibold">
                {t("aboutus")}
              </h3>
            </div>
            <ul className="xs:text-[20px] md:text-[25px] font-roboto font-semibold text-bbf-white my-5 leading-[60px]">
              <Link to="/history">
                <li onClick={closeMenu}>{t("history")}</li>
              </Link>
              <Link to="/philosophie">
                <li onClick={closeMenu}>{t("philosophy")}</li>
              </Link>

              <Link to="/brands">
                <li onClick={closeMenu}>{t("brands")}</li>
              </Link>
              <Link to="/messe">
              <li onClick={closeMenu}>{t("messe")}</li>
                    </Link>
            </ul>
          </div>
        ) : getSubMenu === "locations" ? (
          <div className="flex flex-col bg-bbf-black justify-start items-start xs:pl-5 lg:pt-28 md:pl-[70px] min-h-screen h-auto xl:hidden">
               <div
              onClick={showBurgerMenu}
              className="flex justify-center items-end gap-2"
            >
              <img src={goBackIcon} className="h-8 w-8 xs:mt-5 md:mt-10" />
              <h3 className="text-bbf-white xs:text-[18px] md:text-[23px] font-roboto font-semibold">
                {t("ourlocations")}
              </h3>
            </div>
            <ul className="xs:text-[20px] md:text-[25px] font-roboto font-semibold text-bbf-white my-5 leading-[60px]">
              <Link to="/locations/hoppegarten">
                <li onClick={closeMenu}>Stammhaus Hoppegarten</li>
              </Link>
              <Link to="/locations/rostock">
                <li onClick={closeMenu}>Rostock</li>
              </Link>

              <Link to="/locations/luebeck">
                <li onClick={closeMenu}>Lübeck</li>
              </Link>
              <Link to="/locations/rastede">
                <li onClick={closeMenu}>Rastede</li>
              </Link>
              <Link to="/locations/muenchen">
                <li onClick={closeMenu}>München</li>
              </Link>
              <Link to="/locations/recklinghausen">
                <li onClick={closeMenu}>Recklinghausen</li>
              </Link>
              <Link to="/locations/ronneburg">
                <li onClick={closeMenu}>Ronneburg</li>
              </Link>
              <Link to="/locations/bielefeld">
                <li onClick={closeMenu}>Bielefeld</li>
              </Link>
            </ul>
          </div>
        ) : getSubMenu === "services" ? (
          <div className="flex flex-col bg-bbf-black justify-start items-start xs:pl-5  lg:pt-28 md:pl-[70px] min-h-screen h-auto xl:hidden">
              <div
              onClick={showBurgerMenu}
              className="flex justify-center items-end gap-2"
            >
              <img src={goBackIcon} className="h-8 w-8 xs:mt-5 md:mt-10" />
              <h3 className="text-bbf-white xs:text-[18px] md:text-[23px] font-roboto font-semibold">
                {t("ourservices")}
              </h3>
            </div>
            <ul className="xs:text-[20px] md:text-[25px] font-roboto font-semibold text-bbf-white my-5 leading-[60px]">
              <Link to="/brands">
                <li onClick={closeMenu}> {t("services1")}</li>
              </Link>
              <Link to="/fahrradteile">
                <li onClick={closeMenu}>{t("services2")}</li>
              </Link>

              <Link to="/rental">
                <li onClick={closeMenu}> {t("services3")}</li>
              </Link>
              <Link to="/cycleparts">
                <li onClick={closeMenu}> {t("services4")}</li>
              </Link>
              <Link to="/laufrad">
                <li onClick={closeMenu}>{t("services5")}</li>
              </Link>
        
              <Link to="/support">
                <li onClick={closeMenu}>{t("support")}</li>
              </Link>
            </ul>
          </div>
        ) : (
          <div className="flex flex-col bg-bbf-black justify-start items-start xs:pl-5 lg:pt-28 md:pl-[70px] min-h-screen h-auto xl:hidden">
            <div
              onClick={showBurgerMenu}
              className="flex justify-center items-end gap-2"
            >
              <img src={goBackIcon} className="h-8 w-8 xs:mt-5 md:mt-10" />
              <h3 className="text-bbf-white xs:text-[18px] md:text-[23px] font-roboto font-semibold">
              {t("downloads")}
              </h3>
            </div>
            <ul className="xs:text-[20px] md:text-[25px] font-roboto font-semibold text-bbf-white my-5 leading-[60px]">
              <Link to="/catalog">
                <li onClick={closeMenu}>{t("catalog")}</li>
              </Link>
              
                <li onClick={()=>subMenuinner("opinstruction")}> {t("opinstruction")}</li>
            

              <Link to="/ceconfirmations">
                <li onClick={closeMenu}>{t("ceconfirmation")}</li>
              </Link>
              <a
                      href="https://media.bbf-bike.de/shop/documents/bbf-bike/GRS_EfK21_eMobility.pdf"
                      target="_blank"
                    >
                      <li onClick={closeMenu}>
                        {t("Battery")}
                      </li>
                    </a>
             
            </ul>
          </div>
        )
      ):""}
      {subMenuInnerClicked && !subMenuClicked?getSubMenuInner==="opinstruction"? (
        <div className="flex flex-col bg-bbf-black justify-start items-start xs:pl-5 lg:pt-28 md:pl-[70px] min-h-screen h-auto xl:hidden">
        <div
          onClick={()=>subMenu("opinstruction")}
          className="flex justify-center items-end gap-2"
        >
          <img src={goBackIcon} className="h-8 w-8 xs:mt-5 md:mt-10" />
          <h3 className="text-bbf-white text-[20px] font-roboto font-semibold">
          {t("opinstruction")}
          </h3>
        </div>
        <ul className="xs:text-[20px] md:text-[25px]  font-semibold font-roboto text-bbf-white my-5 leading-[60px]">
          <Link to="/anleitungen-fahrraeder">
            <li onClick={closeMenu}>{t("fahrrader")}</li>
          </Link>
          
          <Link to="/ebikeantriebe">
            <li onClick={closeMenu}>      {t("ebike antriebe")}</li>
          </Link>
        

          <Link to="/components">
            <li onClick={closeMenu}>{t("komponenten")}</li>
          </Link>
         
        </ul>
      </div>
      ):"":""}
    </div>
  );
};

export default Header;
