import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import Brands from '../Components/Brands'

const BrandsPage = () => {
  return (
    <div>
   
        <Brands/>
     
    </div>
  )
}

export default BrandsPage