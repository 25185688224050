import React from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import rentalBikes from "../Images/rentalBikes.jpg"
import rentalbikesMobile from "../Images/rentalbikesMobile.jpg"
const RentalBike = () => {
    const {t}= useTranslation()
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className='min-h-[calc(100vh-100px)] h-auto md:mb-[10px] xl:mb-[15px]  xs:mb-[15px]'>
    <div className='lg:pt-[160px] xs:pt-12 md:mx-[70px] xs:mx-5  font-roboto'>
    <h2 className="xs:text-[15px] md:text-[20px] xl:text-[25px] font-bold  md:my-5 xs:my-4 underline underline-offset-[12px] decoration-bbf-green ">
    {t("services3")}
          </h2>
          <p className=' xs:text-[13px] md:text-[15px] xl:text-[17px] xs:text-justify xl:text-start'>{t("rentaldes")}</p>
          <img src={rentalBikes} className='xs:mt-8 xl:mt-10 object-contain xs:hidden md:block'/>
          <img src={rentalbikesMobile} className='xs:mt-8 xl:mt-10 object-contain xs:block md:hidden'/>
    </div>

</div>
  )
}

export default RentalBike