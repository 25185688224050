import axios from "axios";
import React, { useEffect, useState, useRef } from "react";


import history from "../data/history.json";
import { useTranslation } from "react-i18next";
const History = () => {
  //   const[history,setHistory]=useState({})

  //  const historyResult= async()=>{
  //   try{
  //       const response= await axios.get("https://api.bbf-bike.de/api/web/data/bbf-bike/history")
  //       setHistory(response.data)
  //        }

  // catch(err){
  //  console.log(err)
  // } }
  //   useEffect(()=>{
  //      historyResult()
  //   },[])
  //   console.log(history)

  const { i18n } = useTranslation();
  const currentlanguage = i18n.language;
  const { t } = useTranslation();
  const sectionRef = useRef();
  const scrollToSection = () => {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="min-h-[calc(100vh-100px)] h-auto overflow-x-hidden">
      <div className="relative lg:pt-[110px] sm:pt-0">
        <img
          src="https://media.bbf-bike.de/shop/documents/websites/bbf-bike/images/history/HistoryBannerImage.jpg"
          className="xs:object-contain md:object-cover
          w-full"
        />
        <h1 className="absolute inset-0 text-bbf-white  flex justify-start lg:pt-[110px] md:mx-16 xs:mx-5 items-center xs:text-[18px] md:text-[35px] xl:text-[70px] font-roboto-regular">
          <div className="underline xs:underline-offset-[6px] md:underline-offset-[12px] decoration-bbf-green font-roboto">
            {t("ourhistory").toUpperCase()}
          </div>
        </h1>
        <h3 className="absolute inset-0 font-roboto md:mx-16 xs:mx-5 xs:text-bbf-black md:text-bbf-white xs:top-[100px] md:top-[150px] lg:top-[300px] xl:top-[350px] 2xl:top-[430px] xl:text-[30px] md:text-[18px] xs:text-[13px] xs:mt-[15px] md:my-0 ">
          {t("historydes")}
        </h3>
      </div>

      <div className="md:mt-[35px] xl:mt-[45px]  xs:mt-[65px] ">
        {history.columns?.map((item, index) => (
          <div
            key={item}
            className={`flex flex-wrap ${
              index % 2 === 0
                ? "lg:flex-row sm:flex-col xs:flex-col"
                : "lg:flex-row-reverse sm:flex-col xs:flex-col"
            }`}
          >
            <div className="flex-1 xl:mx-0 xs:mx-5">
              <div className="relative">
                <img
                  src={`${item.image}`}
                  className="h-full xs:object-contain md:object-cover
          w-full  "
                />
              </div>
            </div>
            <div className="flex-1 items-center flex">
              <div className="flex flex-col justify-center items-center xl:mx-8 xs:mx-5  xs:my-5 lg:my-0">
                <div>
                  {currentlanguage === "en"
                    ? item?.translations.en.descriptions.map((item, index) => (
                        <div key={index}>
                          <div className="font-bold font-roboto text-[20px] ">
                            {item?.subtitle}
                          </div>
                          <div className="mt-[15px] xs:text-[13px] md:text-[17px] font-roboto text-bbf-black  text-justify">
                            {item?.content}
                          </div>
                        </div>
                      ))
                    : currentlanguage === "pl"
                    ? item?.translations.pl.descriptions.map((item, index) => (
                        <div key={index}>
                          <div className="font-bold font-roboto text-[20px]">
                            {item?.subtitle}
                          </div>
                          <div className="mt-[15px]  xs:text-[13px] md:text-[17px] font-roboto text-bbf-black  text-justify">
                            {item?.content}
                          </div>
                        </div>
                      ))
                    : item?.descriptions.map((item, index) => (
                        <div key={index}>
                          <div className="font-bold font-roboto text-[20px] mt-2">
                            {item?.subtitle}
                          </div>
                          <div className="mt-[15px]  xs:text-[13px] md:text-[17px] font-roboto text-bbf-black text-justify">
                            {item?.content}
                          </div>
                        </div>
                      ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default History;
