import React from "react";
import { useParams } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import MarkerPic from "../Images/Marker.png";
import {
  TileLayer,
  MapContainer,
  Marker,
  Popup,
  ZoomControl,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import branches from "../data/branches.json";

import { Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
const SingleLocation = () => {
  const { location } = useParams();
  // const[branches,setBranches]=useState([])
  const [click, setClick] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [show, setShow] = useState(false);
  const [center, setCenter] = useState([51.1657, 10.4515]);
  const handleClick = (item) => {
    setClick(true);
    setSelectedItem(item);
    setShow(true);
  };

  const handleClose = () => {
    setClick(false);
    setShow(false);
  };
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  //     const locations=async()=>{
  //  try{
  //     const response= await axios.get("https://api.bbf-bike.de/api/web/data/bbf-bike/branches")
  //     setBranches(response.data)
  //  }
  //  catch(err){
  //     console.log(err)
  //  }
  //     }

  //     useEffect(()=>{
  //         locations()
  //     },[])
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const filteredLocation = branches?.filter(
    (item) => item.branchId === location
  );
  const { t } = useTranslation();
  const customIcon = L.icon({
    iconUrl: MarkerPic,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });
  useEffect(() => {
    if (filteredLocation.length > 0) {
      const newCenter = [
        filteredLocation[0].coordinates.lat,
        filteredLocation[0].coordinates.lng,
      ];
      setCenter(newCenter);
    }
  }, [location]);

  const sectionRef = useRef();
  const scrollToSection = () => {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="min-h-[calc(100vh-100px)] h-auto md:mb-[20px] xl:mb-[35px]  xs:mb-[15px]">
      {filteredLocation.map((item, index) => (
        <div key={index}>
          <div className="lg:pt-[110px] sm:pt-0 relative">
            <img src={item.image} className="h-full xs:object-contain md:object-cover
          w-full" />
         <div className="absolute inset-0 bg-bbf-black bg-opacity-30"></div>
            
            <h2 className="absolute inset-0 text-bbf-white  flex justify-start xs:pt-8 md:pt-20 xl:pt-[240px] 2xl:pt-[300px] md:mx-16 xs:mx-5 items-start xs:text-[18px] md:text-[35px] xl:text-[70px] font-roboto-regular">
          <div className="underline xs:underline-offset-[6px] md:underline-offset-[12px] decoration-bbf-green font-roboto"> {item?.name.toUpperCase()}</div>
         
        </h2>
         <h3 className="absolute inset-0 font-roboto md:mx-16 xs:mx-5 xs:text-bbf-black md:text-bbf-white xs:top-[100px] md:top-[150px] xl:top-[350px] 2xl:top-[430px] xl:text-[30px] md:text-[18px] xs:text-[13px] xs:my-2 md:my-0 ">
         {currentLanguage === "en"
                    ? item.translations.eng.subtitle
                    : currentLanguage === "pl"
                    ? item.translations.pl.subtitle
                    : item.subtitle}
</h3>

          </div>
          <div className="md:mt-[35px] xl:mt-[45px]  xs:mt-[40px] xs:px-5 md:px-10 xl:px-20" ref={sectionRef}>
            <div className="flex justify-center items-center ">
            <div className="grid md:grid-cols-1 xl:grid-cols-2 xs:gap-3 md:gap-5 xl:gap-10 md:w-[600px] xl:w-[1400px]">
            <div className="flex flex-col justify-start  font-roboto ">
              <div className="flex flex-col items-start justify-center">
                <div className="text-bbf-black lg:text-[20px] xs:text-[17px] xs:mb-2 xl:mb-5 font-roboto font-semibold">   {t("getintouch")}</div>
            
                <div className="flex justify-start items-center gap-3 mb-4 xs:text-[13px] md:text-[15px] xl:text-[17px] font-roboto">  
               
                
                       <img
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAs
            TAAALEwEAmpwYAAAFR0lEQVR4nO1aaWgdVRS+KVHcal2r1ZA5ZxoTLSqu+EdIVcQFpShGSyuIMcy9LxKXH1K1yqtVfyjFfyp1Qf1RlGfn3Gmi4lKISMV
            StYoLRQWpiGBVrFat0TSNnHvvvCx2Zt7Lm8kifjAQ3jvv3HPm7OdGiP/xb/S91nak0v4KSfC01LBNavhBafibH/6bP1Man1Kht7w76pgvZhtUBB1Sw7N
            S416lcbSmh/APRfCM3OifMtPyi6B/0WGSYJ0kGHbC7TeWIFxdIuhkBW9844TD+eG/zWcE9ymC95nWKsXWwkdves47ZEaU4DcpNXzKwkiNI5JgQ0Bti2v
            9fe+mxW1S44uxQlLje0HYukhMJ1TknW39nwWAL4Kw9Zyp8goi/zyl4UtnnW9Lm/BMMY2WMEooDW+WXmk9ulGet1RajpEEb8XKFG4ZExPOnViJ8mBnc26
            81597kCLYHLtZoTHDgR27Ux6WSLDMVy6zPSAKS7EEwxzYjcREFkohnm8SAOHvvRXvxNwP4DphrEGwQRQMRfiSs/wT+VdsU8Bwfy0pVoX+QqnxQUn4Eb9
            ZfiThdkm4tq/SdnwtCUVVrbLkiNwU4bbDvaFtmUJEeJ0i2JNU0SXBrwH512by0fAB0wcabshNEe6drBC4OlsJV+AINVfyuLJL7S1VGqNqAY28a9J4KQ3
            3O9r1+Slim71RFizx4NBfWLUEwV1JdCUNq1z6/iXobz8ukS7yLnJtz9Y8dLBCEvzITHsI25NoOCZiS9TArz8rxfYQtjt33tWI7BMP1vAXM01rvZXGj7O
            sNvltcwJIoumOOua7mBpqRPbJQv7JTNOqrdTwG9PUkmViIdkV07oIZWNkr8gLiuB7K2RygYrjoxZF7ABmM1gSjRxoOdlZ7btGZJ8opMYd5g2G/umJB3P
            NMG/QW5rJL/QvdjHyYRJNr/bOcsp+1ojsE4XUMOCYXp9IQ7jWCRfVzE/jmkSaEFe6rEWNyD5JSHjYpcyHkmi4YrOrmIDXsCqZF97jXGb3rXTSsUl0ivC
            RLGXrBlvCBefmNDqu2FzsHG0/ZyeOGX7YncZZgifKZWm8lIYtzgtS6eqC65348CHOJmm0XLG52CW3KLg7S7jbtXeU6bQJhoOKvyA3RYyAhNvt2/SvzKL
            lis3FjoPZpGWCPdw7sZukuVOMEmGXc+UtIm9UK7fG50XBUBrCrFibMoKN3qnV7jXDvRo6p+IvMAXYrJY8KOQQdhWjTIgrC1NEQ5+z/NtFncHu1RsvB4r
            gXx7sbFaEX9v48FeIomDSqMtIPFvnzV+F3vK4LeGtSt78Jx5G8JirEy8X5bpK472iaPDyLA7GQPtn5MVXhnhpPHBxHRHTAUXweN5W4eBWRe6zDoSesK1
            lbKviX9goP9sNmCTyUxGLv/TDNa6Jp7xyWcybKp+uypKD4yW21Ngrpht3VloOlRp3mgwW+TdPlQ8vKly7/nmeu+Qp7bt4guSpr97fc+s/1mDCZWLGMCq
            aJMG7rnVZV+/PFeELblMyUIyAdV762AtP3BdEcEGtvwtC7/L4LrGem65CMW7M3VHLvYbZohB8Y/cAcJuYLejizEPwSdY4HENpfDKeNxrJeIXAbj3MDe1
            I2ibFuBS36ARDJfJOE7MRyk6FXA92HqjNsCMz7LLW8O4QsxXlwc5mSfBOdY0zKpqq35XFPEn4ukvXr47/blaix7Qvduk9/hoiHpW55hRypVYEAo1X21E
            VRxR5VyjCq9yaaF+g/UvEXIIc68V+5hWQtYZ/t5hzGBVNUmNl3D/RTIiZOYVuU/hwK8/4uV5qiv8w/gF0K4RGYBI0eQAAAABJRU5ErkJggg=="
                            className="md:w-6 md:h-6 xs:w-4 xs:h-4"
                          />
                  <h4 >{item.address}</h4></div>
                  <div className="flex justify-start items-center gap-3 mb-4 xs:text-[13px] md:text-[15px] xl:text-[17px] font-roboto">  
                  <img
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAE
            j0lEQVR4nO1aXWhcVRA+8R/FH/yraZO9MzehghRU+uBPEVHEF1GQEgVriU3TO2eTxj6KT3kofVKpKFUDiqBv696ZTSLRPkiLD/600FoQafWhKiqCNdqqGH8jc/eczdruTXdLs
            jmBfLBPO+funXNmvvlmzhqzghUsHWwFHrIMB4lhhhiOJBzdYZYT+ko3XWQZ9ljB2foPMf5Ek11rzHJAUoqvJIH33Mv/Tozbk4m11xLjeOaM4JQJHUOl6AYr+Inb/W+L0rO+/j
            sSPJE5yDhgQsXIVO8VxHjI7fpnQ2WITrehFDdVTwp+TibiggkNI1O9FxPD+9WTgE+HefU1ebbEKMGGGDHsqoYMfDWY9nY1EX4/Ovt+ExKIcVpfrFiJ7mnG3gpsdnk0naSFThM
            KiGFGX2yHRFc1vUbgbeeMmFBADF9koZLG65pdoyGoSa/rEoFHTQggn8ApbmptHWyrJj4cMyGAGLe7MHmjlXVaKF3h/NvMmo7Fe8MmkXBvj2X81zKcGqjceHmz60iiJxx7HTShg
            JwsSTiyzdj3vx5dQoJfBkfDxPCIj/fRfXdfcHZ7fNqF4+HRUXOeCQV9JXO+ShPnDM1na9P4emI4Wa09eJ8JDVZwo0ve75KJzkvz7EhwzJ3GuAkSs6bDCnzsFO4z+bkBvziHB02
            osJXoVivwJwn+YwXvamiTwpO+0RocL6AJFSS40yd+wxCbNR0kMOlsDswXhkve6hLDEUetexrZqC7z5GAFK0oWJkQMSXSLtrpVZoq35BdS+MH1J2NBVPdGUAd8755U4LZGNjpZ
            8clPjLtNqLCML3hKzpug2DS+15+eFXjOhIhkbP2FlnGfc+aDvMROBB9UtnPt8i4TIkZKvddZxuPOmXeUDBrZFRn75pzB3UHmzCDjWsvwvUvsUh5LVU/GhRnjK0GyGaVwsxZBR8
            uv5u245kxd9a80Ckd1UMlERSdlxRc+V+Fq2oWi9NxpGX89W2LbNLrdU7P2K/VzMhWatTp1xlgWylvHcVV7nGG43wr8UXMm52SK5UJcU9SM09oae0XgQu+49jJJNpKFbV5RVyea
            8WNtcYYq0cPeGRJ4LS8XVAFYhonTdv1kUeApFaD/c1x6upVM6obmonO09pyMDzOGt/LYTE8sERhxefOy9jPzPdcyDtRyMRsGwmaz2KByvMH/KDG+O5+A1NFs08+d7FrjZ2fu1C
            eHK92rzaLrsjlq/nAhp4+Wod9PQnXD8qTSgtaZuWEEfrOQP5ikhc5a28B42LRDAZDgfpfQM3mq+VywtdR1tduk30zbtJnAi3Wx/dJCNF7EsMPrPdNOVKt2dTieVe1yvOFcn2W1
            uDqqL6bxA6bdUBLwFVxlCDE8+/jeVZe18oxiuRB7IrGCz5slbZsFdxLDX7W+RoCaGQKOaM4xHnUbsVfD1iw19GLVMn5UlzvHdDybN2suSk93nbw51MpMuk1zM9zod9kpglMk8G
            Z20ZrG6/R6PFPPDF97um2biGwVGlZ6OZQNzvUWQM5UwS6c9qtjZjkgS+YUhy0jK7tlf1IQPKF51YqkWcEKzMLiPwd3DQyQvIVxAAAAAElFTkSuQmCC"
                            className="md:w-6 md:h-6 xs:w-4 xs:h-4"
                          />
<h4>{currentLanguage==="pl"?"03342.354.325":item.contact.phone}</h4>
                  </div>
                 
                  <div className="flex justify-start items-center gap-3 xs:text-[13px] md:text-[15px] xl:text-[17px] font-roboto">
                  <img
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACN0lEQVR4nO2X
            P4gTURDG36kgKJ6IyKEXk5m9FQULwUKwUFFsFCyvEwuVnVkl2GkZSzuxtBPLkMzsGQ2IiFgIFgcKgiAIggiCiHCCqCcaebrJrZzH5Y+5vMT3g1fssm/3+3bmzZtnjMfj8XgGASs2
            RmGYkTNihhT2RhyDRz8iDTNmXKexoHFJI6QwGyVw0DgKCe4jgUfLGmlek0ItruQD4wixTm0nwZss+ONvehdHRPAqK8yn9z+T4JViPRwflIFiPRy3GqyW35pg3mpc1oiFKsEOUiwv
            RAffk8CF6bJZvVIGSiWzihI4RYpvWzoE7p2bmdrdcdWKk8JhEnySMfScNDjebxNcDY6w4tPsd1kKx3oqv+3+mX8BdZEJHe8jJ+9OrCfFy3/kquL1Yjnc0quB+HZ+k10HJPAlffdX
            UrwWlYONfdsQz1bDXLZ6kOCHWOFSsR6u7dRA6cGhNbFCRArvuq2W3OvO3qznGQEvYsHpdufHCR4lhWeZ+bOseGAwLUrDjFnxLPgqI+g+VWHPUlOiSmEXC9xpteCCb2xU7Fo0g+61
            oltb19n0YoGP6SL9btPvzAxONJ85L9s227wngW+pgU92XZxOdm5wrmmkWm6SFG9YI2l1myOFi7+GwFzLpH2mlpt0vvuNqvm9LPhw0UlO8HEkhf1D18ZHiidY4SUJvLZ7UT86a16p
            84gty92U5nb5Dw5WQwZ7I47BPiKOwT4ijsE+Io7BPiKuR4SHfJiRMeLxeDweMwB+An37+6BMF2JeAAAAAElFTkSuQmCC"
                            className="md:w-6 md:h-6 xs:w-4 xs:h-4"
                          />
                  <h4>{item.contact.email}</h4>
                  </div>
                 </div>
               </div>
              <div className="flex flex-col justify-start">
             
               
                  <div className="flex flex-col items-start justify-start font-roboto xs:text-[13px] md:text-[15px] xl:text-[17px]">
                    {item.content.filter((item)=>item.title.de==="Öffnungszeiten des Abholmarktes").map((item,index)=>(
                      <div key={index}>
                           <h2 className="text-bbf-black lg:text-[20px] sm:text-[17px] mb-5 font-roboto font-semibold">
                          {currentLanguage === "en"
                            ? item.title.en
                            : currentLanguage === "pl"
                            ? item.title.pl
                            : item.title.de}
                        </h2>
                                                  {currentLanguage === "en"
                            ? item.translations.en.map((item,index)=>
                            <div key={index} className="flex flex-col justify-center items-start xs:gap-4 xl:gap-8">
                            <div className="font-semibold">{item.season}</div>
                            <div>{item.timings.split("•")
                                .filter((item) => item.trim() !== "")
                                .map((item, index) => (
                                  <li key={index} className="flex mb-4 justify-start items-start">
                                    
                                    <img
                                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABAklE
                              QVR4nNXUO04DMRSF4a+HggUgIURgEShQkLAJNsEiWAQQKNkGz4geCkj2AEOR0A8aySOhKLEdZoTgSKcZ2ef3XN9r/ogeUc542CZgOAfw4Jd1giusL
                              bvxEBcY4zN4hAH639Y9hT97xXpO8A7u55Rm1nfYxgaew7fjVPg+PjLCaxfYwyqOsJI6+aLwXXQjkE5OaW4iJ60VK1fyQssGgBK9GOCyBcB5DDBuAT
                              CKAaYtAKYxwCSxuRvaMbZm0qREZYarSV6oQQuA0xig3wLgQELXDcJvZWgTbz8IL3KfCqFTiiXC30OHLaVO4l2qXZV0SwP1wvhX7VcNUeUXnOVc6P/X
                              F4+kAKqrnelmAAAAAElFTkSuQmCC"
                                      className="object-contain mr-2"
                                    />
                              
                                   <div> {item.trim()}</div>
                                  </li>))}</div>
                            </div>
                            )
                            : currentLanguage === "pl"
                            ? item.translations.pl.map((item,index)=>
                            <div key={index} className="flex flex-col justify-center items-start xs:gap-4 xl:gap-8">
                           <div className="font-semibold">{item.season}</div>
                            <div>{item.timings.split("•")
                                .filter((item) => item.trim() !== "")
                                .map((item, index) => (
                                  <li key={index} className="flex mb-4 justify-start items-start">
                                    
                                    <img
                                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABAklE
                              QVR4nNXUO04DMRSF4a+HggUgIURgEShQkLAJNsEiWAQQKNkGz4geCkj2AEOR0A8aySOhKLEdZoTgSKcZ2ef3XN9r/ogeUc542CZgOAfw4Jd1giusL
                              bvxEBcY4zN4hAH639Y9hT97xXpO8A7u55Rm1nfYxgaew7fjVPg+PjLCaxfYwyqOsJI6+aLwXXQjkE5OaW4iJ60VK1fyQssGgBK9GOCyBcB5DDBuAT
                              CKAaYtAKYxwCSxuRvaMbZm0qREZYarSV6oQQuA0xig3wLgQELXDcJvZWgTbz8IL3KfCqFTiiXC30OHLaVO4l2qXZV0SwP1wvhX7VcNUeUXnOVc6P/X
                              F4+kAKqrnelmAAAAAElFTkSuQmCC"
                                      className="object-contain mr-2"
                                    />
                              
                                   <div> {item.trim()}</div>
                                  </li>))}</div>
                            </div>
                            )
                            : item.description.map((item,index)=>(
                              <div key={index} className="flex flex-col justify-center items-start xs:gap-4 xl:gap-8">
                                <div className="font-semibold">{item.season}</div>
                                <div>{item.timings.split("•")
                                .filter((item) => item.trim() !== "")
                                .map((item, index) => (
                                  <li key={index} className="flex mb-4 justify-start items-start">
                                    <img
                                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABAklE
                              QVR4nNXUO04DMRSF4a+HggUgIURgEShQkLAJNsEiWAQQKNkGz4geCkj2AEOR0A8aySOhKLEdZoTgSKcZ2ef3XN9r/ogeUc542CZgOAfw4Jd1giusL
                              bvxEBcY4zN4hAH639Y9hT97xXpO8A7u55Rm1nfYxgaew7fjVPg+PjLCaxfYwyqOsJI6+aLwXXQjkE5OaW4iJ60VK1fyQssGgBK9GOCyBcB5DDBuAT
                              CKAaYtAKYxwCSxuRvaMbZm0qREZYarSV6oQQuA0xig3wLgQELXDcJvZWgTbz8IL3KfCqFTiiXC30OHLaVO4l2qXZV0SwP1wvhX7VcNUeUXnOVc6P/X
                              F4+kAKqrnelmAAAAAElFTkSuQmCC"
                                      className="object-contain mr-2"
                                    />
                                   <div> {item.trim()}</div>
                                  </li>
                                ))}</div>
                              </div>
                            ))          
                  }
                      </div>
                    ))}
                  </div>

              </div>
              <div className="flex flex-col items-start justify-start font-roboto xs:text-[13px] md:text-[15px] xl:text-[17px]">
                    {item.content.filter((item)=>item.title.de==="Auftragsannahme").map((item,index)=>(
                      <div key={index}>
                           <h2 className="text-bbf-black lg:text-[20px] sm:text-[17px] mb-5 font-roboto font-semibold">
                          {currentLanguage === "en"
                            ? item.title.en
                            : currentLanguage === "pl"
                            ? item.title.pl
                            : item.title.de}
                        </h2>
                                                  {currentLanguage === "en"
                            ? item.translations.en.map((item,index)=>(
                              <div key={index} className="flex flex-col justify-center items-start xs:gap-4 xl:gap-8">
                                <div className="font-semibold">{item.season}</div>
                                <div>{item.timings.split("•")
                                .filter((item) => item.trim() !== "")
                                .map((item, index) => (
                                  <li key={index} className="flex mb-4 justify-start items-start">
                                    <img
                                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABAklE
                              QVR4nNXUO04DMRSF4a+HggUgIURgEShQkLAJNsEiWAQQKNkGz4geCkj2AEOR0A8aySOhKLEdZoTgSKcZ2ef3XN9r/ogeUc542CZgOAfw4Jd1giusL
                              bvxEBcY4zN4hAH639Y9hT97xXpO8A7u55Rm1nfYxgaew7fjVPg+PjLCaxfYwyqOsJI6+aLwXXQjkE5OaW4iJ60VK1fyQssGgBK9GOCyBcB5DDBuAT
                              CKAaYtAKYxwCSxuRvaMbZm0qREZYarSV6oQQuA0xig3wLgQELXDcJvZWgTbz8IL3KfCqFTiiXC30OHLaVO4l2qXZV0SwP1wvhX7VcNUeUXnOVc6P/X
                              F4+kAKqrnelmAAAAAElFTkSuQmCC"
                                      className="object-contain mr-2"
                                    />
                                   <div> {item.trim()}</div>
                                  </li>
                                ))}</div>
                              </div>
                            ))
                               
                            : currentLanguage === "pl"
                            ? item.translations.pl.map((item,index)=>(
                              <div key={index} className="flex flex-col justify-center items-start xs:gap-4 xl:gap-8">
                                <div className="font-semibold">{item.season}</div>
                                <div>{item.timings.split("•")
                                .filter((item) => item.trim() !== "")
                                .map((item, index) => (
                                  <li key={index} className="flex mb-4 justify-start items-start">
                                    <img
                                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABAklE
                                QVR4nNXUO04DMRSF4a+HggUgIURgEShQkLAJNsEiWAQQKNkGz4geCkj2AEOR0A8aySOhKLEdZoTgSKcZ2ef3XN9r/ogeUc542CZgOAfw4Jd1giusL
                                bvxEBcY4zN4hAH639Y9hT97xXpO8A7u55Rm1nfYxgaew7fjVPg+PjLCaxfYwyqOsJI6+aLwXXQjkE5OaW4iJ60VK1fyQssGgBK9GOCyBcB5DDBuAT
                                CKAaYtAKYxwCSxuRvaMbZm0qREZYarSV6oQQuA0xig3wLgQELXDcJvZWgTbz8IL3KfCqFTiiXC30OHLaVO4l2qXZV0SwP1wvhX7VcNUeUXnOVc6P/X
                                F4+kAKqrnelmAAAAAElFTkSuQmCC"
                                      className="object-contain mr-2"
                                    />
                                   <div> {item.trim()}</div>
                                  </li>
                                ))}</div>

                              </div>
                            ))
                                
                            : item.description.map((item,index)=>(
                              <div key={index} className="flex flex-col justify-center items-start xs:gap-4 xl:gap-8">
                                <div className="font-semibold">{item.season}</div>
                                <div>{item.timings.split("•")
                                .filter((item) => item.trim() !== "")
                                .map((item, index) => (
                                  <li key={index} className="flex mb-4 justify-start items-start">
                                    <img
                                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABAklE
                              QVR4nNXUO04DMRSF4a+HggUgIURgEShQkLAJNsEiWAQQKNkGz4geCkj2AEOR0A8aySOhKLEdZoTgSKcZ2ef3XN9r/ogeUc542CZgOAfw4Jd1giusL
                              bvxEBcY4zN4hAH639Y9hT97xXpO8A7u55Rm1nfYxgaew7fjVPg+PjLCaxfYwyqOsJI6+aLwXXQjkE5OaW4iJ60VK1fyQssGgBK9GOCyBcB5DDBuAT
                              CKAaYtAKYxwCSxuRvaMbZm0qREZYarSV6oQQuA0xig3wLgQELXDcJvZWgTbz8IL3KfCqFTiiXC30OHLaVO4l2qXZV0SwP1wvhX7VcNUeUXnOVc6P/X
                              F4+kAKqrnelmAAAAAElFTkSuQmCC"
                                      className="object-contain mr-2"
                                    />
                                   <div> {item.trim()}</div>
                                  </li>
                                ))}</div>
                              </div>
                            ))
                                }
                      </div>
                    ))}
                  </div>
                  <div className="flex flex-col items-start justify-start font-roboto xs:text-[13px] md:text-[15px] xl:text-[17px]">
                    {item.content.filter((item)=>item.title.de==="Lieferzeiten").map((item,index)=>(
                     <div key={index} className="flex flex-col justify-center items-start ">
                           <h2 className="text-bbf-black lg:text-[20px] sm:text-[17px] mb-5 font-roboto font-semibold">
                          {currentLanguage === "en"
                            ? item.title.en
                            : currentLanguage === "pl"
                            ? item.title.pl
                            : item.title.de}
                        </h2>
                                                  {currentLanguage === "en"
                            ? item.translations.en.map((item,index)=>(
                              <div key={index} className="flex flex-col justify-center items-start xs:gap-4 xl:gap-8">
                                <div className="font-semibold">{item.season}</div>
                                <div>{item.timings.split("•")
                                .filter((item) => item.trim() !== "")
                                .map((item, index) => (
                                  <li key={index} className="flex mb-4 justify-start items-start">
                                    <img
                                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABAklE
                              QVR4nNXUO04DMRSF4a+HggUgIURgEShQkLAJNsEiWAQQKNkGz4geCkj2AEOR0A8aySOhKLEdZoTgSKcZ2ef3XN9r/ogeUc542CZgOAfw4Jd1giusL
                              bvxEBcY4zN4hAH639Y9hT97xXpO8A7u55Rm1nfYxgaew7fjVPg+PjLCaxfYwyqOsJI6+aLwXXQjkE5OaW4iJ60VK1fyQssGgBK9GOCyBcB5DDBuAT
                              CKAaYtAKYxwCSxuRvaMbZm0qREZYarSV6oQQuA0xig3wLgQELXDcJvZWgTbz8IL3KfCqFTiiXC30OHLaVO4l2qXZV0SwP1wvhX7VcNUeUXnOVc6P/X
                              F4+kAKqrnelmAAAAAElFTkSuQmCC"
                                      className="object-contain mr-2"
                                    />
                                   <div> {item.trim()}</div>
                                  </li>
                                ))}</div>
                              </div>
                            ))
                                
                            : currentLanguage === "pl"
                            ? item.translations.pl.map((item,index)=>(
                              <div key={index} className="flex flex-col justify-center items-start xs:gap-4 xl:gap-8">
                                <div className="font-semibold">{item.season}</div>
                                <div>{item.timings.split("•")
                                .filter((item) => item.trim() !== "")
                                .map((item, index) => (
                                  <li key={index} className="flex mb-4 justify-start items-start">
                                    <img
                                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABAklE
                                QVR4nNXUO04DMRSF4a+HggUgIURgEShQkLAJNsEiWAQQKNkGz4geCkj2AEOR0A8aySOhKLEdZoTgSKcZ2ef3XN9r/ogeUc542CZgOAfw4Jd1giusL
                                bvxEBcY4zN4hAH639Y9hT97xXpO8A7u55Rm1nfYxgaew7fjVPg+PjLCaxfYwyqOsJI6+aLwXXQjkE5OaW4iJ60VK1fyQssGgBK9GOCyBcB5DDBuAT
                                CKAaYtAKYxwCSxuRvaMbZm0qREZYarSV6oQQuA0xig3wLgQELXDcJvZWgTbz8IL3KfCqFTiiXC30OHLaVO4l2qXZV0SwP1wvhX7VcNUeUXnOVc6P/X
                                F4+kAKqrnelmAAAAAElFTkSuQmCC"
                                      className="object-contain mr-2"
                                    />
                                   <div> {item.trim()}</div>
                                  </li>
                                ))}</div>
                              </div>
                            ))
                                
                            : item.description.map((item,index)=>(
                              <div key={index} className="flex flex-col justify-center items-start xs:gap-4 xl:gap-8">
                                <div className="font-semibold">
                                  {item.season}
                                </div>
                                <div>{item.timings.split("•")
                                .filter((item) => item.trim() !== "")
                                .map((item, index) => (
                                  <li key={index} className="flex mb-4 justify-start items-start">
                                    <img
                                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABAklE
                              QVR4nNXUO04DMRSF4a+HggUgIURgEShQkLAJNsEiWAQQKNkGz4geCkj2AEOR0A8aySOhKLEdZoTgSKcZ2ef3XN9r/ogeUc542CZgOAfw4Jd1giusL
                              bvxEBcY4zN4hAH639Y9hT97xXpO8A7u55Rm1nfYxgaew7fjVPg+PjLCaxfYwyqOsJI6+aLwXXQjkE5OaW4iJ60VK1fyQssGgBK9GOCyBcB5DDBuAT
                              CKAaYtAKYxwCSxuRvaMbZm0qREZYarSV6oQQuA0xig3wLgQELXDcJvZWgTbz8IL3KfCqFTiiXC30OHLaVO4l2qXZV0SwP1wvhX7VcNUeUXnOVc6P/X
                              F4+kAKqrnelmAAAAAElFTkSuQmCC"
                                      className="object-contain mr-2"
                                    />
                                   <div> {item.trim()}</div>
                                  </li>
                                ))}</div>
                              </div>
                            ))
                                }
                      </div>
                    ))}
                  </div>
                  <div className="xl:h-[350px] xl:w-[560px] lg:w-[900px] lg:h-[350px] md:w-[500px] md:h-[300px] xs:w-[340px] xs:h-[200px] ">
                <MapContainer
                  center={[51.1657, 10.4515]}
                  zoom={4.5}
                  style={{ height: "100%", width: "100%" }}
                  zoomControl={true}
                  className="z-0"
                >
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  />
                  <ZoomControl position="topright" />

                  <Marker
                    icon={customIcon}
                    position={[item?.coordinates.lat, item?.coordinates.lng]}
                  >
                    <Popup>
                      <div className="xs:p-0 md:p-2">
                        <h2 className="font-roboto font-bold xl:text-[17px] xs:text-[14px]">
                          {item?.name.toUpperCase()}
                        </h2>
                        <h2 className="flex items-start xs:mt-2 md:mt-3 gap-3">
                          {" "}
                          <img
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAs
            TAAALEwEAmpwYAAAFR0lEQVR4nO1aaWgdVRS+KVHcal2r1ZA5ZxoTLSqu+EdIVcQFpShGSyuIMcy9LxKXH1K1yqtVfyjFfyp1Qf1RlGfn3Gmi4lKISMV
            StYoLRQWpiGBVrFat0TSNnHvvvCx2Zt7Lm8kifjAQ3jvv3HPm7OdGiP/xb/S91nak0v4KSfC01LBNavhBafibH/6bP1Man1Kht7w76pgvZhtUBB1Sw7N
            S416lcbSmh/APRfCM3OifMtPyi6B/0WGSYJ0kGHbC7TeWIFxdIuhkBW9844TD+eG/zWcE9ymC95nWKsXWwkdves47ZEaU4DcpNXzKwkiNI5JgQ0Bti2v
            9fe+mxW1S44uxQlLje0HYukhMJ1TknW39nwWAL4Kw9Zyp8goi/zyl4UtnnW9Lm/BMMY2WMEooDW+WXmk9ulGet1RajpEEb8XKFG4ZExPOnViJ8mBnc26
            81597kCLYHLtZoTHDgR27Ux6WSLDMVy6zPSAKS7EEwxzYjcREFkohnm8SAOHvvRXvxNwP4DphrEGwQRQMRfiSs/wT+VdsU8Bwfy0pVoX+QqnxQUn4Eb9
            ZfiThdkm4tq/SdnwtCUVVrbLkiNwU4bbDvaFtmUJEeJ0i2JNU0SXBrwH512by0fAB0wcabshNEe6drBC4OlsJV+AINVfyuLJL7S1VGqNqAY28a9J4KQ3
            3O9r1+Slim71RFizx4NBfWLUEwV1JdCUNq1z6/iXobz8ukS7yLnJtz9Y8dLBCEvzITHsI25NoOCZiS9TArz8rxfYQtjt33tWI7BMP1vAXM01rvZXGj7O
            sNvltcwJIoumOOua7mBpqRPbJQv7JTNOqrdTwG9PUkmViIdkV07oIZWNkr8gLiuB7K2RygYrjoxZF7ABmM1gSjRxoOdlZ7btGZJ8opMYd5g2G/umJB3P
            NMG/QW5rJL/QvdjHyYRJNr/bOcsp+1ojsE4XUMOCYXp9IQ7jWCRfVzE/jmkSaEFe6rEWNyD5JSHjYpcyHkmi4YrOrmIDXsCqZF97jXGb3rXTSsUl0ivC
            RLGXrBlvCBefmNDqu2FzsHG0/ZyeOGX7YncZZgifKZWm8lIYtzgtS6eqC65348CHOJmm0XLG52CW3KLg7S7jbtXeU6bQJhoOKvyA3RYyAhNvt2/SvzKL
            lis3FjoPZpGWCPdw7sZukuVOMEmGXc+UtIm9UK7fG50XBUBrCrFibMoKN3qnV7jXDvRo6p+IvMAXYrJY8KOQQdhWjTIgrC1NEQ5+z/NtFncHu1RsvB4r
            gXx7sbFaEX9v48FeIomDSqMtIPFvnzV+F3vK4LeGtSt78Jx5G8JirEy8X5bpK472iaPDyLA7GQPtn5MVXhnhpPHBxHRHTAUXweN5W4eBWRe6zDoSesK1
            lbKviX9goP9sNmCTyUxGLv/TDNa6Jp7xyWcybKp+uypKD4yW21Ngrpht3VloOlRp3mgwW+TdPlQ8vKly7/nmeu+Qp7bt4guSpr97fc+s/1mDCZWLGMCq
            aJMG7rnVZV+/PFeELblMyUIyAdV762AtP3BdEcEGtvwtC7/L4LrGem65CMW7M3VHLvYbZohB8Y/cAcJuYLejizEPwSdY4HENpfDKeNxrJeIXAbj3MDe1
            I2ibFuBS36ARDJfJOE7MRyk6FXA92HqjNsCMz7LLW8O4QsxXlwc5mSfBOdY0zKpqq35XFPEn4ukvXr47/blaix7Qvduk9/hoiHpW55hRypVYEAo1X21E
            VRxR5VyjCq9yaaF+g/UvEXIIc68V+5hWQtYZ/t5hzGBVNUmNl3D/RTIiZOYVuU/hwK8/4uV5qiv8w/gF0K4RGYBI0eQAAAABJRU5ErkJggg=="
                            className="md:w-6 md:h-6 xs:w-4 xs:h-4"
                          />
                          <div className="font-roboto text-[14px]">{item?.address}</div>
                        </h2>
                        <h2 className="flex items-start xs:mt-2 md:mt-3 gap-3">
                          {" "}
                          <img
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAE
            j0lEQVR4nO1aXWhcVRA+8R/FH/yraZO9MzehghRU+uBPEVHEF1GQEgVriU3TO2eTxj6KT3kofVKpKFUDiqBv696ZTSLRPkiLD/600FoQafWhKiqCNdqqGH8jc/eczdruTXdLs
            jmBfLBPO+funXNmvvlmzhqzghUsHWwFHrIMB4lhhhiOJBzdYZYT+ko3XWQZ9ljB2foPMf5Ek11rzHJAUoqvJIH33Mv/Tozbk4m11xLjeOaM4JQJHUOl6AYr+Inb/W+L0rO+/j
            sSPJE5yDhgQsXIVO8VxHjI7fpnQ2WITrehFDdVTwp+TibiggkNI1O9FxPD+9WTgE+HefU1ebbEKMGGGDHsqoYMfDWY9nY1EX4/Ovt+ExKIcVpfrFiJ7mnG3gpsdnk0naSFThM
            KiGFGX2yHRFc1vUbgbeeMmFBADF9koZLG65pdoyGoSa/rEoFHTQggn8ApbmptHWyrJj4cMyGAGLe7MHmjlXVaKF3h/NvMmo7Fe8MmkXBvj2X81zKcGqjceHmz60iiJxx7HTShg
            JwsSTiyzdj3vx5dQoJfBkfDxPCIj/fRfXdfcHZ7fNqF4+HRUXOeCQV9JXO+ShPnDM1na9P4emI4Wa09eJ8JDVZwo0ve75KJzkvz7EhwzJ3GuAkSs6bDCnzsFO4z+bkBvziHB02
            osJXoVivwJwn+YwXvamiTwpO+0RocL6AJFSS40yd+wxCbNR0kMOlsDswXhkve6hLDEUetexrZqC7z5GAFK0oWJkQMSXSLtrpVZoq35BdS+MH1J2NBVPdGUAd8755U4LZGNjpZ
            8clPjLtNqLCML3hKzpug2DS+15+eFXjOhIhkbP2FlnGfc+aDvMROBB9UtnPt8i4TIkZKvddZxuPOmXeUDBrZFRn75pzB3UHmzCDjWsvwvUvsUh5LVU/GhRnjK0GyGaVwsxZBR8
            uv5u245kxd9a80Ckd1UMlERSdlxRc+V+Fq2oWi9NxpGX89W2LbNLrdU7P2K/VzMhWatTp1xlgWylvHcVV7nGG43wr8UXMm52SK5UJcU9SM09oae0XgQu+49jJJNpKFbV5RVyea
            8WNtcYYq0cPeGRJ4LS8XVAFYhonTdv1kUeApFaD/c1x6upVM6obmonO09pyMDzOGt/LYTE8sERhxefOy9jPzPdcyDtRyMRsGwmaz2KByvMH/KDG+O5+A1NFs08+d7FrjZ2fu1C
            eHK92rzaLrsjlq/nAhp4+Wod9PQnXD8qTSgtaZuWEEfrOQP5ikhc5a28B42LRDAZDgfpfQM3mq+VywtdR1tduk30zbtJnAi3Wx/dJCNF7EsMPrPdNOVKt2dTieVe1yvOFcn2W1
            uDqqL6bxA6bdUBLwFVxlCDE8+/jeVZe18oxiuRB7IrGCz5slbZsFdxLDX7W+RoCaGQKOaM4xHnUbsVfD1iw19GLVMn5UlzvHdDybN2suSk93nbw51MpMuk1zM9zod9kpglMk8G
            Z20ZrG6/R6PFPPDF97um2biGwVGlZ6OZQNzvUWQM5UwS6c9qtjZjkgS+YUhy0jK7tlf1IQPKF51YqkWcEKzMLiPwd3DQyQvIVxAAAAAElFTkSuQmCC"
                            className="md:w-6 md:h-6 xs:w-4 xs:h-4"
                          />
                          <div className="font-roboto text-[14px]">{item?.contact.phone}</div>
                        </h2>
                        <h2 className="flex items-start xs:mt-2 md:mt-3 gap-3">
                          <img
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACN0lEQVR4nO2X
            P4gTURDG36kgKJ6IyKEXk5m9FQULwUKwUFFsFCyvEwuVnVkl2GkZSzuxtBPLkMzsGQ2IiFgIFgcKgiAIggiCiHCCqCcaebrJrZzH5Y+5vMT3g1fssm/3+3bmzZtnjMfj8XgGASs2
            RmGYkTNihhT2RhyDRz8iDTNmXKexoHFJI6QwGyVw0DgKCe4jgUfLGmlek0ItruQD4wixTm0nwZss+ONvehdHRPAqK8yn9z+T4JViPRwflIFiPRy3GqyW35pg3mpc1oiFKsEOUiwv
            RAffk8CF6bJZvVIGSiWzihI4RYpvWzoE7p2bmdrdcdWKk8JhEnySMfScNDjebxNcDY6w4tPsd1kKx3oqv+3+mX8BdZEJHe8jJ+9OrCfFy3/kquL1Yjnc0quB+HZ+k10HJPAlffdX
            UrwWlYONfdsQz1bDXLZ6kOCHWOFSsR6u7dRA6cGhNbFCRArvuq2W3OvO3qznGQEvYsHpdufHCR4lhWeZ+bOseGAwLUrDjFnxLPgqI+g+VWHPUlOiSmEXC9xpteCCb2xU7Fo0g+61
            oltb19n0YoGP6SL9btPvzAxONJ85L9s227wngW+pgU92XZxOdm5wrmmkWm6SFG9YI2l1myOFi7+GwFzLpH2mlpt0vvuNqvm9LPhw0UlO8HEkhf1D18ZHiidY4SUJvLZ7UT86a16p
            84gty92U5nb5Dw5WQwZ7I47BPiKOwT4ijsE+Io7BPiKuR4SHfJiRMeLxeDweMwB+An37+6BMF2JeAAAAAElFTkSuQmCC"
                            className="md:w-6 md:h-6 xs:w-4 xs:h-4"
                          />
                         
                          <div className="font-roboto text-[14px]"> {item?.contact.email}</div>
                        </h2>
                      </div>
                    </Popup>
                  </Marker>
                </MapContainer>
              </div>
                            
              <div className="flex my-4 items-center">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAERElEQVR4nO2Za2g
        cVRTHr1at1mcVH9G4c85ktRgVUVEUlaII+sHHBw1UKxbSOvfO1qD94hdbtiiCilIfH1RQBAXFIXPOJMaqiJRaFFqKohiKIGLV+qD4tj6rkTtzNmnjJpnZnX2M5A
        cXlrnn3D3/ue8zSs0zTzEx7N5sQmeZKjJeWDpPM/4dF8ILVSGZUAcYhi2GcULKO/aZKho6xOWxAIKv48I4YZ+pIuGN9iwyBDsTITioGVfK7y9uef3Ew1VR0Iz3
        xj1A+G61qg60RTNsk2f3qCLgc9+phnCPzIvLas89ci42hP9oxl81O6C6Hc0YJCLghTp1L4rAl1Q3o4fdS2pvvTIMzvT6VWG51xD+YsX4BEtVN1JN5sF2WamqM
        9lpxvUyV94bCNQC1W34DJ4Mqc9nW5nWBL2HacZPEzFwm+omhjaWj9KMX8VCUhxHNLk3xUIYvrmDnWNUt2AYHs66e2uCt6RXHlLdQGWkr6wJfrfnKT/EC9L6mcg
        51/oYhj9NBEtaG2UKDMErMsGfVhnRDM/IEBtTncQLnatlePxYCZyTptcPBP2H+GOlxbbY39PrrY/1tW3YtlQn8J46/2DDuEPe6F31bAzDuqnTL6yrZ2N9xWaHb
        bPlgf8ngAjXSIAfD20sL5xtz0jE4vp6NranNMNHiZ1zp2onK4PeYw3jt/GfR3DdTHZphMR2BNfLJvn9UFA+XrULQ/ikDKk3Z7NLKyS2JXxNbJ9Q7aAy0nemJvj
        LMO712D07NyFRqT9eihn36hDOUa3GEG6SS9Jjc9lmERK3zfC4zLstLb0W6whvlLH83Wo6+bi8hfhjpcWGYLf43KBawYpnnUMN4SfJeQpXp/HJKsSiCW+XDXanv
        TKrvDEEa2VIjadd7xsRMhCoBYbgA+n5u5sOfL+AXu49RTP8LOP3qtR+DQixmNC9Ql7aHm/ULam80AzPS0BRNr/GhFg04Yj0ynMqD0zoXGSvr4bhj1WEp7dLiEf
        lPnuqtv/tkXupyiFbuFXezP1Z3ZsRYjGED8jGu91epVWjGIIVtWyhF7hHt1vIYLTkSMP4Zewfwa2qESpB/xGacFctW9jUSpe8jLWNtYGDMiJ22ZgyN6AJ7mu2W
        +0yXbuPNHpEr+6TnbExZXL2h0uuYfwtmeRT2cJO4UmW0i44etg9LbWjIaSZsoWdQk9mKSFM5eBHzuV5bUa1VUcm+4PNtOWNuqVaTtnGOOfxQBO8P1e2MC2acMO
        kEMINTbfHtSwlfFjdtPSgGQ0Nub4YfpbHgS1vId5ozyIbW/KiXX/uI3ROHy/t5wM/wittqZfUbgQTOstkxOy2Mdf5U3xUeuPtbv/WZwg3S08/sl+FT84Z9pqZNV
        vYKYxkKeMrd+ieNVXB+Gqj2cJOoWtZSoI34gce47Ui4icvLPWogmBC9wTD8INsFdfY8Ta+z7fwYhbC8cn0fqEL4eZOj5B5/rf8CwwhpwlOO3NDAAAAAElFTkSuQ
        mCC"
                    className="md:w-8 xs:w-6 md:h-8 xs:h-6 mr-5"
                  />
                  <h2 className="font-roboto font-semibold xs:text-[13px] md:text-[15px] xl:text-[17px]">
                    {" "}
                    {t("Only for specialist bicycle retailers")}
                  </h2>
                </div>
            </div>
            </div>
          </div>
        
        </div>
       
      ))}
    </div>
  );
};

export default SingleLocation;
