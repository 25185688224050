import React from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import cpcycleparts from "../data/cpcycleparts.json"
import arrow from "../Images/arrow.png"
import { Button } from './Button'
const CpcycleParts = () => {
  const {i18n}= useTranslation()
  const currentLanguage =i18n.language
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='min-h-[calc(100vh-100px)] h-auto md:mb-[10px] xl:mb-[15px]  xs:mb-[10px]'>
        <div className='xl:pt-[150px] xs:pt-12 md:mx-[70px] xs:mx-5 xs:text-[13px] md:text-[15px] xl:text-[17px] font-roboto'>
        <div className='flex flex-col xl:pt-10'>
          {cpcycleparts.cp.map((item,index)=>(
            <div key={index}>
               <h3 className='xs:text-[15px] md:text-[20px] xl:text-[25px] font-bold font-roboto underline underline-offset-[12px] decoration-bbf-green'>
          {currentLanguage==="de"?(<div>{item.headline.de}</div>):currentLanguage==="en"?(<div>{item.headline.en}</div>):(<div>{item.headline.pl}</div>)}
          </h3> 
        <div className='mt-8 text-justify'>{currentLanguage==="de"?(<div>{item.description1.de}</div>):currentLanguage==="en"?(<div>{item.description1.en}</div>):(<div>{item.description1.pl}</div>)}</div>
        <div className='mt-8'>{currentLanguage==="de"? <div>{item.bulletpoints.header.de}</div>:""}</div>
        <div className='mt-5'>{currentLanguage==="de"?(<div>{item.bulletpoints.points.de.map((item,i)=>(
          <div key={i} className='flex justify-start items-center gap-3 flex-row mb-5'>
            <img
                              src={arrow}
                              className="xs:h-4 xs:w-4 xl:h-6 xl:w-6 xs:mt-1 object-contain"
                            />
            
            <p>{item}</p>
            </div>
        ))}</div>):currentLanguage==="en"?(<div>{item.bulletpoints.points.en.map((item,i)=>(
          <div key={i} className='flex justify-start items-start gap-3 flex-row mb-5'>
            <img
                              src={arrow}
                              className="xs:h-4 xs:w-4 xl:h-6 xl:w-6 xs:mt-1 object-contain"
                            />
            
            <p>{item}</p>
            </div>
        ))}</div>):(<div>{item.bulletpoints.points.pl.map((item,i)=>(
          <div key={i} className='flex justify-start items-start gap-3 flex-row mb-5'>
          <img
                            src={arrow}
                            className="xs:h-4 xs:w-4 xl:h-6 xl:w-6 xs:mt-1 object-contain"
                          />
          
          <p>{item}</p>
          </div>
        ))}</div>)}</div>
        <div className='mb-8'>{currentLanguage==="de"?item.bulletpoints.footer.de:currentLanguage==="en"?item.bulletpoints.footer.en:item.bulletpoints.footer.pl}</div>
         <a href='http://cp-cycleparts.de/' target='_blank'>
         <Button text="CP CycleParts"  color="bbf-black"/>
         </a>
        
        <img src={item.images.desktop} className='mt-8 md:block xs:hidden object-contain'/>
        <img src={item.images.mobile} className='mt-8 md:hidden xs:block object-contain'/>
            </div>
          ))}

            </div>
            </div>
            </div>
  )
}

export default CpcycleParts